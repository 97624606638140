<template>
	<v-container style="fontFamily: 'Poppins'" fluid>
		
		<v-toolbar class="font-weight-light body-1 elevation-0">
			<v-tooltip bottom>
				<template #activator="{ onInner, attrs }">
						<v-btn small dark color="primary" v-bind="attrs" 
						href="https://youtu.be/_W7AIL_i-S0" fab v-on="onInner" > <v-icon fab> mdi-youtube </v-icon> </v-btn> 
				</template>
			<span>Da click para ver un video tutorial de ayuda en YouTube</span> 
			</v-tooltip>
			<v-toolbar-title>Compras</v-toolbar-title> 
			<!-- <vue-excel-xlsx
				:data="articulos"
				:columns="headers2"
				:filename="'Reporte de Compras '+$store.state.usuario.localidad"
				:sheetname="'Compras'"
				>
				<v-btn dark color="boton">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
			</vue-excel-xlsx>  -->
			<v-divider
				class="mx-4"
				inset
				vertical
			></v-divider>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Buscar"
				filled
				hide-details
			></v-text-field>
			<v-select v-model="localidad" filled class="mx-2" :items="Localidades" label="Localidad"
				hide-details="" @change="initialize()"
			/>
			<v-spacer></v-spacer>
			<div class="text-center">
				<v-menu offset-y>
				<template #activator="{ on, attrs }">
					<v-btn
					color="primary"
					dark
					large
					v-bind="attrs"
					class="rounded-0"
					v-on="on"
					>
					Opciones
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="Aprobaciones()">
				<v-list-item-title><v-icon >mdi-beaker-check</v-icon> Aprobaciones</v-list-item-title>
				</v-list-item>
				<v-list-item @click="AbrirCopmraSimple()">
				<v-list-item-title > <v-icon >mdi-shopping</v-icon> Nueva Compra Simple</v-list-item-title>
				</v-list-item>
				<v-list-item @click="modelinventario= true">
				<v-list-item-title > <v-icon >mdi-tag-plus</v-icon> Nueva Compra con Inventario</v-list-item-title>
				</v-list-item>
				<v-list-item @click="modelxml= true">
				<v-list-item-title > <v-icon >mdi-xml</v-icon> Carga Masiva XML</v-list-item-title>
				</v-list-item>
				<v-list-item to='/comprasproveedor'>
				<v-list-item-title >
				<v-icon >mdi-timeline-plus</v-icon> Reporte de proveedores</v-list-item-title>
				</v-list-item>
				</v-list>
				</v-menu>
			</div>
		</v-toolbar>
	<v-data-table
		:headers="headers"
		:items="articulos"
		:search="search"
		sort-by="nombre"
		class="elevation-1"
		:loading="loading"
		>
		<template #top>
			<v-dialog
				v-model="modelCarga"
				width="300"
				dark
				>
				<v-card color="white">
					<v-card-text class="black--text">
					Por Favor, Espere..
					<v-progress-linear
						indeterminate
						color="black"
						class="mb-0"
					></v-progress-linear>
					</v-card-text>
					<div>
					<!-- Barra de progreso con animación -->
					<v-progress-linear
						v-if="cargando"
						color="primary"
						:value="progreso"
						:buffer-value="100"
						stream
						background-color="grey darken-1"
						indeterminate 
						height="30"
						class="fixed-progress-bar primary lighten-2"
					>
						<template #default>
						{{ progreso }}%
						</template>
					</v-progress-linear>
					</div>
					<v-img
					src="https://i.pinimg.com/originals/e2/6d/87/e26d873ee68fbcd9dc1c40d8b0c6b3b4.gif"
					class="my-3"
					contain
					height="200"
					></v-img>
				</v-card>
			</v-dialog>

			<v-dialog v-model="modelcompra" max-width="1000" class="boton">
				<v-toolbar  dark color="primary" class="font-weight-light body-1">
					<h4>Nueva Compra sin inventario</h4>
					
				</v-toolbar>
				<v-card>
					<v-container fluid>
							<v-form ref="form" v-model="validFactura2">
								<v-row>
									<v-col cols="12" sm="6">
										<v-select
											v-model="proveedor"
											hide-details=""
											filled
											:rules="[v => !!v || 'El Proveedor es requerido']"
											:items="Proveedores"
											label="Proveedor"
											append-outer-icon="mdi-account-multiple-plus"
											@click:append-outer="GestionProveedores"
										/>   
									</v-col>
									<v-col cols="12" sm="6">
										<v-text-field v-model="NoFactura" hint="Procura que el numero de factura no se repita" onfocus="this.select();" persistent-hint filled :rules="[v => !!v || 'El Número de Factura es requerido']" label="Número de Factura"></v-text-field>
										
									</v-col>
									<v-col cols="12" sm="3">
										<v-text-field v-model="FechaCompra" clearable filled :rules="[v => !!v || 'Debe seleccionar alguna fecha']" type="date" label="Fecha Compra"></v-text-field>
									</v-col>
									<v-col cols="12" sm="3" >
										<v-text-field id="Vencimiento" v-model="FechaVencimiento" clearable filled :rules="[v => !!v || 'Debe seleccionar alguna Vencimiento']" type="date" label="Fecha Vencimiento" @keydown="CalcularDias()" @change="CalcularDias()"></v-text-field>
									</v-col>
									<v-col cols="12" sm="3">
										<v-select v-model="localidad" filled :items="Localidades" label="Localidad"></v-select>
										<!-- <v-text-field filled onfocus="this.select();" v-model="OtrosImpuestos" label="Otros Impuestos"></v-text-field> -->
									</v-col>
									<v-col cols="12" sm="3">
										<v-text-field v-model="SubTotal" filled onfocus="this.select();" label="SubTotal"></v-text-field>
									</v-col>
									<v-col cols="12" sm="3">
										<v-text-field v-model="Impuestos" filled onfocus="this.select();" label="IVA"></v-text-field>
									</v-col>
									<v-col cols="12" sm="3">
										<v-select v-model="TipoPago" filled hint="Caja chica: Tomado del cajon de dinero, Crédito: Crea una CXP, Contado: Almacena la compra" persistent-hint
										:items="TiposPago" label="Tipo de Pago"/>
									</v-col>
									<v-col v-if="TipoPago !== 'Caja Chica'" cols="12" sm="6">
										<v-select  v-model="cuenta" :items="Cuentas" persistent-hint hint="La cuenta que estas usando para tomar el dinero, sino tienes cuentas cargadas agregalas desde 'Contabilidad' 'Estados Bancarios'" filled  label="Cuenta Bancaria"></v-select>
										
									</v-col>
									<v-col cols="12" sm="3">
										<v-select v-model="MonedaPago" persistent-hint hint="Si la moneda de la cuenta es diferente a la de esta compra el sistema realizará la conversión" filled :items="['CRC', 'USD', 'EUR', 'GTQ']" label="Moneda"></v-select>
									</v-col>
									<v-col cols="12" sm="3">
										<v-text-field v-model="OrdenCompra" filled  label="Orden De compra"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6">
										<v-textarea v-model="Detalle" filled auto-grow
												rows="1"
												dense
												row-height="30"
												shaped label="Detalle o comentario"></v-textarea>
									</v-col>
									<v-col cols="12" sm="6" flat color="white"  class="rounded-xl light-blue lighten-5 mx-5">
										<v-card-text>
											<b> SubTotal:  </b>{{SubTotal}} <br>
											<b> Iva:  </b> {{Impuestos}} <br>
											<strong class="h2--text">Total Compra: </strong> {{MontoTotal=(parseFloat(SubTotal) + parseFloat(Impuestos))}} <br>
											<p> Vence en {{dias}} días </p>
										
										</v-card-text>
									</v-col>
								</v-row>
							</v-form>
							<v-card-actions>
								<v-btn id="espacioBoton" color="error" @click="modelcompra = false">
								Cancelar
								</v-btn> 
								<v-spacer/>
								<v-btn :loading="loading" :disabled="!validFactura2" color="primary"  class="mr-4" style="top: 10px;" @click="generarInventarioSimple()">
									Guardar Compra
								</v-btn>
							</v-card-actions>
					</v-container>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="menu20"
				width="500"
				>

				<v-card>
					<v-card-title class="headline grey lighten-2">
					Ligar linea a producto existente
					</v-card-title>
					<div class="pa-3">
					<v-combobox v-model="codigo" clearable :items="productos" label="Busqueda de producto por nombre" @change="LigarProducto()" @keyup.enter="LigarProducto()"></v-combobox>
					</div>     

					<v-divider></v-divider>

					<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="menu20 = false"
					>
						Salir
					</v-btn>
					</v-card-actions>
				</v-card>
				</v-dialog>
			<v-dialog v-model="modelSeriales" persistent max-width="800" class="primary">
				<v-toolbar  dark color="primary" class="font-weight-light body-1">
					<h4>Agregar códigos Seriales</h4>
					<v-card-subtitle>Producto: {{nombre}} </v-card-subtitle>
				</v-toolbar>
				<v-card>
				<v-container fluid>
					<v-col cols="12" sm="12">
					<v-row>
					<v-col class="mt-2" cols="12" sm="10">
						<v-text-field v-model="serial" clearable label="Escanear o digitar Código" @keyup.enter="agregarSerial()"></v-text-field>
						<v-menu
								v-model="menu21"
								:close-on-content-click="false"
								:nudge-right="40" 
								transition="scale-transition"
								offset-y
								min-width="290px"
							>
								<template #activator="{ on: open, attrs }">
									<v-text-field
										v-model="start"
										label="Fecha Vencimiento"
										prepend-icon="mdi-calendar"
										readonly
										persistent-hint
										type="date"
										hint="DD/MM/YYYY formato"
										v-bind="attrs"
										:rules="fechaInicialR"
										v-on="open"
									></v-text-field>
								</template>

								<v-date-picker v-model="start" @input="menu21 = false"></v-date-picker>
							</v-menu>
							<v-edit-dialog
									:return-value.sync="nombreproveedor"
									large
									persistent
									@save="updateCliente()"
									@close="updateCliente()"
									>
									Proveedor: {{nombreproveedor }}
									<template #input>
										<v-select
										v-model="nombreproveedor"
										:items="Proveedores"
										label="Nombre del proveedor"
										counter
										></v-select>
										<v-btn color="primary" icon="" @click="GestionProveedores()"> <v-icon> mdi-account-multiple-plus </v-icon>  </v-btn>
									</template>
									</v-edit-dialog> 
					</v-col>
					<v-col class="mt-2" cols="12" sm="2">
						<v-btn  @click="agregarSerial()"> Agregar</v-btn>
					</v-col>
						<v-col cols="12" sm="12">
							<v-data-table
								:headers="cabeceraSeriales"
								item-key="name"
								single-select
								:items="seriales"
								class="elevation-1"
								hide-default-footer=""
							>
								<template #v-slot>
									<h3>No hay artículos agregados al detalle.</h3>
								</template> 
								
								<template #[`item.actionmovimiento`]="{ item }">
									<v-icon
										large
										color="error"
										@click="eliminarProducto(item.id)"
									>
										mdi-delete
									</v-icon>
								</template>

								<template #[`item.actioncantidad`]="{ item }">
									<v-text-field v-model="item.cantidadIngreso"> </v-text-field>
								</template>
								
							</v-data-table>

						<v-btn  color="primary"  class="mr-4" style="top: 10px;" @click="guardarSeriales()">
						Continuar Cargando
						</v-btn>
						<v-btn id="espacioBoton" color="error" @click="modelSeriales = false">
						Salir
						</v-btn> 
						</v-col>
					</v-row>
					</v-col>
				</v-container>
				</v-card>
			</v-dialog>
			<v-dialog v-model="modelinventario" fullscreen persistent max-width="1900" class="primary">
			<v-toolbar  dark color="boton" class="font-weight-light body-1">
				<v-toolbar-title style="font-family: 'Poppins', sans-serif;">Nueva Compra con inventario</v-toolbar-title>
				<v-spacer/>
				<div class="text-center">
					
				<v-menu
				v-model="menu"	
				:close-on-content-click="false"
				:nudge-width="200"
				offset-x
				>
				<template #activator="{ on: menuActivator, attrs }">
					<v-btn color="green" v-bind="attrs" small v-on="menuActivator">
						Carga mediante Excel
					<v-icon>mdi-file-export</v-icon>
					</v-btn>
				</template>

				<v-tooltip bottom>
					<template #activator="{ on: tooltipActivator, attrs: tooltipAttrs }">
					<v-btn small dark color="red" v-bind="tooltipAttrs" fab href="https://youtu.be/cSkcshysR90" v-on="tooltipActivator" 
						>
						<v-icon fab>mdi-youtube</v-icon>
					</v-btn>
					</template>
					<span>Da click para ver un video tutorial de ayuda en YouTube</span>
				</v-tooltip>

				<v-card>
					<v-list>
					<v-list-item>
						<v-list-item-avatar>
						<img src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light">
						</v-list-item-avatar>
						<v-list-item-content>
						<v-list-item-title>Carga de inventario mediante excel</v-list-item-title>
						<v-list-item-subtitle>Oprime el botón verde para descargar la plantilla</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
						<vue-excel-xlsx
                          :data="machote"
                          :columns="columns"
                          :filename="'Reporte de Inventario en '+$store.state.usuario.localidad"
                          :sheetname="'Inventario Actual'"
                          >
                          <v-btn small dark color="black">  <v-icon small>mdi-file-excel </v-icon> Descargar Excel </v-btn>
                      </vue-excel-xlsx> 
						<v-btn small color="primary" @click="listarInventarioLocalidad">Descarga productos</v-btn>
						</v-list-item-action>
					</v-list-item>
					</v-list>

					<v-divider></v-divider>

					<v-list>
					<v-list-item-content>
						<v-list-item-title>Seleccione el archivo y presione el botón cargar.</v-list-item-title>
					</v-list-item-content>
					<div class="pa-4 primary text-no-wrap ">
						<input id="input" type="file" />
					</div>
					</v-list>

					<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="CargarExcel()">Cargar</v-btn>
					<v-btn color="error" outlined @click="menu = false">Salir</v-btn>
					</v-card-actions>
				</v-card>
				</v-menu>

				</div>
			</v-toolbar>
			<v-card>
				<v-container fluid>
					<v-col cols="12" sm="12">
						
					<v-row>
						<v-col cols="12" sm="3">
								<v-select
									v-model="proveedor"
									hide-details=""
									filled
									:rules="[v => !!v || 'El Proveedor es requerido']"
									:items="Proveedores"
									label="Proveedor"
									append-outer-icon="mdi-account-multiple-plus"
									@click:append-outer="GestionProveedores"
								></v-select>    
								<v-text-field v-model="OrdenCompra" hide-details="" filled label="Orden compra"></v-text-field>    
							
						</v-col>
						<v-col cols="12" sm="2"> 
							<v-text-field v-model="NoFactura" hide-details="" filled :rules="[v => !!v || 'El Número de Factura es requerido']" label="No Factura"/>
							
							<v-textarea v-model="Detalle" filled auto-grow
									rows="1"
									dense
									row-height="10"
									label="Detalle de compra">
							</v-textarea>  
						</v-col>
						<v-col cols="12" sm="3">
							<v-text-field v-model="FechaCompra" hide-details="" filled clearable type="date" label="Fecha Compra"></v-text-field>
							<v-select v-model="cuenta" :items="Cuentas" persistent-hint hint="La cuenta que estas usando para tomar el dinero, sino tienes cuentas cargadas agregalas desde 'Contabilidad' 'Estados Bancarios'" filled  label="Cuenta Bancaria"></v-select>
						</v-col>
						<v-col cols="12" sm="3">
							<v-text-field v-model="FechaVencimiento" hide-details="" filled clearable :rules="[v => !!v || 'Debe seleccionar alguna Vencimiento']" type="date" label="Fecha Vencimiento" @change="CalcularDias()"></v-text-field>
							<v-select v-model="TipoPago" filled hint="Caja chica: Tomado del cajon de dinero, Crédito: Crea una CXP, Contado: Almacena la compra" persistent-hint
								:items="TiposPago" label="Tipo de Pago"/>
						</v-col>
						<v-col cols="12" sm="1">
							<v-text-field v-model="OtrosImpuestos" hide-details="" filled label="Otros"/>
							<v-select v-model="MonedaPago" hint="Si la moneda de la cuenta es diferente a la de esta compra el sistema realizará la conversión" filled :items="['CRC', 'USD', 'EUR', 'GTQ']" label="Moneda"/>
						</v-col>
						<v-col cols="12" sm="12">
							<v-row>
								<v-combobox v-model="codigo" filled clearable :items="productos" label="Busqueda de producto por nombre" @change="buscarProducto()" @keyup.enter="buscarCodigo()"></v-combobox>
								
								<input id="xmlfile" type="file" @change="ExportToTable" />  
								<vue-excel-xlsx
									:data="detalles"
									:columns="columns2"
									:filename="'Lista de Productos '+$store.state.usuario.localidad"
									:sheetname="'Ventas'"
									>
									<v-btn dark color="green">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
								</vue-excel-xlsx>  
							</v-row>
						</v-col>
						<v-col cols="12" sm="12">
							<v-data-table :headers="cabeceraDetalles" item-key="name" single-select :items="detalles" dense class="elevation-1">
								<template #no-data>
									<h3>No hay artículos agregados al detalle.</h3>
								</template>
								<template #[`item.actionivi`]="{ item }"> 
                                    <v-checkbox
                                      v-model="item.Ivi"
                                      readonly
                                      color="primary"
                                    ></v-checkbox>
                              </template> 
							  <template #[`item.actionexiste`]="{ item }"> 
                                    <v-checkbox
                                      v-if="item.existe == false"
                                      v-model="checkno"
									  readonly
                                      color="error"
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-if="item.existe == true"
                                      v-model="checksi"
                                      readonly
                                      color="green"
                                    ></v-checkbox>
								</template> 
								<template #[`item.actionmovimiento`]="{ item }">
									<v-icon color="grey" @click="eliminarProducto2(item.id)">
										mdi-delete
									</v-icon>
								</template>
								<template #[`item.actionopciones`]="{ item }" >
									<v-menu :offset-y="offset">
										<template #activator="{ onInner, attrs }">
											<v-icon color="primary" v-bind="attrs" v-on="onInner" >mdi-dots-horizontal</v-icon>
										</template>
										<v-list>
											<v-list-item @click="AñadirCodigo(item)">
												<v-list-item-title>
													<v-icon>mdi-barcode-scan</v-icon> Añadir seriales y vencimiento
												</v-list-item-title>
											</v-list-item>
											<v-list-item>
												<v-list-item-title>Añadir Códigos QR</v-list-item-title>
											</v-list-item>
											<v-list-item @click="AgregarProducto(item)">
												<v-list-item-title>
													<v-icon>mdi-basket-plus</v-icon> Nuevo Producto
												</v-list-item-title>
											</v-list-item>
											<v-list-item @click="AbrirLigue(item)">
												<v-list-item-title>Ligar a producto de inventario actual</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</template>
								<!-- Otros templates en el v-data-table se mantienen igual -->
							</v-data-table>
						</v-col>
						<v-col cols="12" sm="6" flat class="var">
							<v-card-text>
								<b> Gravado:  </b>{{totalGravado=(calcularGravado)}} \\ <b> Vence en {{dias}} días </b>  <br>
								<b> Iva:  </b> {{totalImpuesto=(calcularImpuesto)}} <br>
								<b> Total Exento: </b> {{totalExento=(calcularExento)}} <br>
								<b> Total Descuento: </b>{{totalDescuento=(calcularDescuento)}} <br>
								<strong class="h2--text">Total Compra: </strong> {{total=(calcularTotal)}}
							</v-card-text>
							<v-btn class="mt-4" small color="accent" @click="generarInventario2()">
								Cargar Stock
							</v-btn> 
							<v-btn class="mt-4" small color="accent" @click="ActualizarDatos()">
								Actualizar Datos
							</v-btn> 
						</v-col>
						<v-col cols="12" sm="6" class="var">  
							<v-btn small color="primary" @click="generarInventario()">
								Generar Proceso
							</v-btn>
							<v-btn class="mx-1" style="width:120px" color="accent" small  @click="modelinventario = false">
								Cancelar
							</v-btn> 
							<v-btn class="mx-1" style="width:120px" small color="accent" @click="Limpiarxml()">
								Limpiar
							</v-btn> 
							<v-btn small style="width:150px" color="accent" @click="ValidarExistencia()">
								Validar Existencia
							</v-btn> 
							<v-btn class="mt-2" small color="accent" @click="AgregarInventario()">
								Agregar todos los productos que no existen
							</v-btn> 
							<v-switch v-model="confirma" label="Actualizar precio de costo, venta, bar code, comision" /> 
							
							<v-select v-model="localidad" style="width:50%"  filled :items="Localidades" label="Localidad"
								hide-details="" @change="initialize()"
							/>
							<v-text-field v-model="ganancia" hide-details="" style="width:50%" filled onfocus="this.select();" label="Digite el % de ganancia que desea obtener al precio de venta con impuesto" @keyup.enter="ObtenerGanancia()" />

					</v-col>
					
				</v-row>	
				</v-col>
			</v-container>
			</v-card>
			</v-dialog>
			<v-dialog v-model="modelxml" persistent max-width="1900" class="primary">
				<v-toolbar dark class="font-weight-light body-1">
					<h4>Carga Masiva de XMLs</h4>
				</v-toolbar>
				<v-card>
				<v-container fluid>
					
					<v-col cols="12" sm="12">
						<input id="xmlfile" type="file" multiple @change="ExportToTable2" />  
						<v-btn @click="ExportToTable2"> Cargar </v-btn>
							<!-- <vue-excel-xlsx
								:data="detalles"
								:columns="columns2"
								:filename="'Plantilla de Productos '+$store.state.usuario.localidad"
								:sheetname="'Ventas'"
								>
								<v-btn fab="" color="success">  <v-icon large="">mdi-file-excel </v-icon> </v-btn>
							</vue-excel-xlsx> -->
					<v-row>
						<v-col cols="12" sm="12">
						<v-simple-table dense>
							<template  #default>
							<thead>
								<tr >
									<th class="text-left">Eliminar</th>
									<th class="text-left">Emisor</th>
									<th class="text-left">Receptor</th>
									<th class="text-left">Cedula</th>
									<th class="text-left">Correo receptor</th>
									<th class="text-left">No Factura</th>
									<th class="text-left">Total Gravado</th>
									<th class="text-left">Total Exento</th>
									<th class="text-left">Total Descuento</th>
									<th class="text-left">Iva 1%</th>
									<th class="text-left">Iva 2%</th>
									<th class="text-left">Iva 4%</th>
									<th class="text-left">Iva 13%</th>
									<th class="text-left">Total Impuestos</th>
									<th class="text-left">Total Impuestos</th>
									<th class="text-left">Total</th>
									<th class="text-left">Fecha</th>
									<th class="text-left">Moneda</th>
									<th class="text-left">Clave</th>
									<th class="text-left">Tipo Cambio</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item , index) in Comprobantes" :key="index"
									:class="ColorTabla(item.existe,item.ConsecutivoDocumento)">
									<td><v-btn fab dark="" small color="error" @click="eliminarProductoLinea(item.id)"><v-icon dark>mdi-delete</v-icon> </v-btn></td>
									<td> {{item.Proovedor}} </td>
									<td> {{item.Razon_Social}} </td>
									<td> {{item.Cedula}} </td>
									<td> {{item.CorreoProv}} </td>
									<td> {{item.ConsecutivoDocumento}} </td>
									<td> {{item.TotalGravado}} </td>
									<td> {{item.TotalExento}} </td>
									<td> {{item.TotalDescuento}} </td>
									<td> {{item.Imp1}} </td>
									<td> {{item.Imp2}} </td>
									<td> {{item.Imp4}} </td>
									<td> {{item.Imp13}} </td>
									<td> {{item.MontoImpuesto}} </td>
									<td> {{item.Monto_Factura}} </td>
									<td> {{item.Fecha}} </td>
									<td> {{item.Moneda}}</td>
									<td> {{item.Clave}}</td>
									<td> {{item.TipoCambio}}</td>
								</tr>
							</tbody>
							</template>
						</v-simple-table>
					</v-col>
						<v-col cols="12" sm="3" flat color="white"  class="light-blue lighten-5 mx-5">
							<v-card-text>
								<v-sub-title>Totales de ingresos </v-sub-title> <br>
								<b> IVA 1%: </b>{{MonedaPago}} {{Imp1=(calcularImpuesto1)}} <br>
								<b> IVA 2%: </b>{{MonedaPago}} {{Imp2=(calcularImpuesto2)}} <br>
								<b> IVA 4%: </b>{{MonedaPago}} {{Imp4=(calcularImpuesto4)}} <br>
								<b> IVA 13%: </b>{{MonedaPago}} {{Imp13=(calcularImpuesto13)}} <br>
								<v-card-subtitle> {{FechaCompra}} </v-card-subtitle> 
							
							</v-card-text>
						</v-col>
						<v-col class="mt-5" cols="12" sm="3">
						<v-textarea v-model="Detalle" auto-grow
							rows="1"
							dense
							row-height="30"
							shaped label="Detalle del compra">
						</v-textarea>
						<v-select v-model="TipoPago" 
						:items="TiposPago" label="Tipo de Pago">
						</v-select> 
						<v-select v-model="TipoCompra" 
						:items="TipoCompras" label="Tipo de Compra">
						</v-select> 
						</v-col>
						<v-col cols="12" sm="4">  
							<v-btn :disabled="cargoxml = false" color="primary" @click="ComprasMasivas()">
								Almacenar Compras
							</v-btn>
							<v-btn id="espacioBoton" color="error" @click="modelxml = false">
							Cancelar
							</v-btn> 
							<v-btn id="espacioBoton" dark @click="Limpiarxml()">
							Limpiar
							</v-btn> 
						</v-col>
						
					</v-row>
					</v-col>
				</v-container>
				</v-card>
			</v-dialog>
			<v-dialog v-model="modelAprobaciones" persistent max-width="1000" class="primary">
				<v-toolbar  dark color="primary" class="font-weight-light body-1">
					<h4>Aprobaciones de Inventario</h4>
					
				</v-toolbar>
				<v-card>
					<v-container fluid>
						<v-col cols="12" sm="12">
								<v-row>
									<v-col cols="12" sm="12">
										<v-flex xs12 sm12 md12 lg12 xl12>
										
										<v-data-table
											:headers="cabeceraAprobaciones"
											item-key="name" 
											single-select
											:items="listaAprobaciones"
											class="elevation-1">
											<template slot="items" slot-scope="props">
													<v-autocomplete
													:items="saleables"
													:item-text="i => i.name"
													:item-value="i => i.id"
													hide-details
													></v-autocomplete>
											<tr>
												<td>{{props.item.idarticulo}}</td>
												<td>{{props.item.descripcion}}</td>
												<v-text-field v-model="props.item.cantidad" type="number"></v-text-field>
												<v-text-field v-model="props.item.precio" type="number"></v-text-field>
												<td> Eliminar </td>
											</tr>
											</template>
											<template slot="no-data">
												<h3>No hay artículos agregados al detalle.</h3>
											</template> 
											<template #[`item.actionmovimiento`]="{ item }">
											<v-icon
												large
												color="error"
												@click="DenegarProducto(item)"
											>
												mdi-delete-circle
											</v-icon>
											</template>
											<template #[`item.actioncantidad`]="{ item }"> 
												<v-icon large color="success" @click="AprobarProducto(item)">mdi-check-circle</v-icon>  
											</template> 

										</v-data-table>
										</v-flex>
										<v-btn disabled color="var"  class="mr-4" style="top: 10px;" @click="generarInventario()">
										Aprobar Todo
										</v-btn>
										<v-btn id="espacioBoton" color="error" @click="modelAprobaciones = false">
										Salir
										</v-btn> 
									</v-col>
								</v-row>
						</v-col>
					</v-container>
			</v-card>
			</v-dialog>
			<v-dialog v-model="modelproveedor" persistent max-width="1400" class="primary">
				<v-toolbar  dark color="primary" class="font-weight-light body-1">
					<h4>Gestion de Proveedores</h4>
					
				</v-toolbar>
				<v-card>
				<v-container fluid>
					<v-row>
					<v-col cols="12" sm="5">
					<v-col cols="12" sm="12">
					<v-row>
						<v-col class="mt-2" cols="12" sm="6">
						<v-select v-model="tpcedulaproveedor" outlined="" dense="" :rules="[v => !!v || 'El tipo de cédula es requerido']" :items="tipoDocumentos" label="Tipo Cédula"></v-select>
						</v-col>
						<v-col class="mt-2" cols="12" sm="6">
						<v-text-field v-model="nombreproveedor" outlined="" dense="" label="Nombre"></v-text-field>
						</v-col>
						<v-col class="mt-2" cols="12" sm="6">
						<v-text-field v-model="cedulaproveedor" outlined="" dense="" label="Cedula"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6">
							<v-text-field v-model="telefonoproveedor" outlined="" dense="" clearable label="Teléfono"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6">
							<v-text-field v-model="correoproveedor" outlined="" dense="" clearable label="Correo"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12">
							<v-text-field v-model="direccionproveedor" outlined="" dense="" clearable label="Dirección"></v-text-field>
						</v-col>
						<v-col cols="12" sm="12">
								
						<v-btn  color="primary"  class="mr-4" style="top: 10px;" @click="IngresarProveedor()">
							<v-icon> mdi-content-save </v-icon>  Guardar
						</v-btn>
						<v-btn id="espacioBoton" color="error" @click="modelproveedor = false">
							<v-icon> mdi-cancel </v-icon> Cancelar
						</v-btn> 
						</v-col>
						</v-row>
						</v-col>
					</v-col>
					<v-col cols="12" sm="7">
						<v-data-table
							:headers="ProveedoresDetalles"
							single-select
							:items="gestionProveedor"
							class="elevation-1">
							
							<template slot="no-data">
								<h3>No hay artículos agregados al detalle.</h3>
							</template> 
							<template #[`item.actionmovimiento`]="{ item }">
							<v-icon
								large
								color="error"
								@click="eliminarProducto3(item.id)"
							>
								mdi-delete
							</v-icon>
							</template>
							<template #[`item.actioncantidad`]="{ item }"> 
							<v-text-field v-model="item.cantidadIngreso">  </v-text-field>
							</template> 

						</v-data-table>
					</v-col>
					</v-row>
				</v-container>
				</v-card>
			</v-dialog>
			<v-snackbar
				v-model="snackbar" top vertical="" :timeout="6000"
				>
				{{ Mensaje }}
				<v-btn
				color="pink"
				text
				@click="snackbar = false"
				>
				Close
				</v-btn>
			</v-snackbar>
			
			
		</template>
		<template #[`item.action`]="{ item }">
		<v-icon
			small
			class="mr-2"
			disabled=""
			color="primary" 
			@click="editItem(item)"
		>
			mdi-pencil
		</v-icon>
		<v-icon
			small
			color="error"
			@click="EliminarCompra(item)"
		>
			mdi-delete
		</v-icon>
		</template>
    
		<template #no-data>
		<p> No se encontró información </p>
		</template>
  	</v-data-table>
	</v-container>
</template>

<script>
import axios from 'axios'

  	export default {
    data: () => ({
		dialog: false,
		search: '',
		articulos: [],
		modelCarga: true,
		datamachote: [
			{
				idarticulo: "123Ab022",
				descripcion: "Item Ejemplo",
				precio: "300",
				precioCompra: "40",
				Cabys: "1235460000000",
				valorImpuesto: "13",
				Ivi: true,
				cantidadIngreso: "50",
				impuesto: "S",
				familia: "Pantalones",
				proveedor: "Nautica",
				innerPack:"Hombre",
				maximo: "20",
				minimo:"5",
				precioMayor: "100",
				precioDetalle:"120",
				precio3:"60",
				comision:20,
			},
			// ...otros objetos/filas
		],
		machote: [{ label: 'Código Interno o SKU', field: 'idarticulo' },
			{ label: 'descripcion', field: 'descripcion' },
			{ label: 'Precio Venta', field: 'precio' },
			{ label: 'Precio Compra', field: 'precioCompra'},
			{ label: 'Cabys', field: 'Cabys'},
			{ label: 'Valor Impuesto', field: 'valorImpuesto'},
			{ label: 'Ivi', field: 'Ivi' },
			{ label: 'Cantidad Ingresar', field: 'cantidadIngreso' },
			{ label: 'Grabado (S o N)', field: 'impuesto'},
			{ label: "Categoria 1",field: "innerPack", },
			{ label: "Familia",field: "familia", },
			{ label: "Codigo Barras",field: "barcode" },
			{ label: "Proveedor",field: "proveedor", },
			{ label: "Maximo",field: "maximo", },
			{ label: "Minimo",field: "minimo", },
			{ label: "Precio Mayor",field: "precioMayor", },
			{ label: "Precio Detalle",field: "precioDetalle", },
			{ label: "Precio 3",field: "precio3", },
			{ label: "Comision",field: "comision", },
			{ label: "Max %Descuento",field: "maxDescuento", }
		],

		columns : [
			{ label: 'Código Interno o SKU', field: 'idarticulo' },
			{ label: 'descripcion', field: 'descripcion' },
			{ label: 'Precio Venta', field: 'precio' },
			{ label: 'Precio Compra', field: 'precioCompra'},
			{ label: 'Cabys', field: 'Cabys'},
			{ label: 'Valor Impuesto', field: 'valorImpuesto'},
			{ label: 'Ivi', field: 'Ivi' },
			{ label: 'Cantidad Ingresar', field: 'cantidadIngreso' },
			{ label: 'Grabado (S o N)', field: 'impuesto'},
			{ label: "Categoria 1",field: "innerPack", },
			{ label: "Familia",field: "familia", },
			{ label: "Codigo Barras",field: "barcode" },
			{ label: "Proveedor",field: "proveedor", },
			{ label: "Maximo",field: "maximo", },
			{ label: "Minimo",field: "minimo", },
			{ label: "Precio Mayor",field: "precioMayor", },
			{ label: "Precio Detalle",field: "precioDetalle", },
			{ label: "Precio 3",field: "precio3", },
			{ label: "Comision",field: "comision", },
			{ label: "Max %Descuento",field: "maxDescuento", }
			],
			columns2: [
			{ label: 'Código', field: 'idarticulo'},  
			{ label: 'Descripción', field: 'descripcion'},
			{ label: 'Precio Venta', field: 'precio' },
			{ label: 'Costo Sin IVA', field: 'precioCompra',  },
			{ label: 'Cabys ', field: 'cabys'  },
			{ label: '% Imp ', field: 'valorImpuesto'  },
			{ label: 'IVI', field: 'Ivi' },
			{ label: 'Cantidad Ingresar', field: 'cantidadIngreso' },
			{ label: 'Impuesto', field: 'impuesto'},
			{ label: "Familia",field: "familia", },
			{ label: "Codigo Barras",field: "idarticulo", },
			{ label: "Proveedor",field: "proveedor", },
		],
		headers: [
			{ text: 'Opciones', value: 'action', sortable: false },
			{ text: 'Factura', value: 'noFactura' },
			{ text: 'Proveedor', value: 'proveedor' },
			{ text: 'Usuario', value: 'cajero' },
			{ text: 'Total', value: 'total'},
			{ text: 'IVA', value: 'iva'},
			{ text: 'Pago', value: 'pago'},
			{ text: 'Estado', value: 'estado'},       
			{ text: 'Fecha Compra', value: 'fechaCompra' },
			{ text: 'Fecha Vencimiento', value: 'fechaVencimiento'} ,
			{ text: 'Fecha Registro', value: 'fechaRegistro'},
			{ text: 'Detalle', value: 'detalle'},             
		],
		headers2: [
			{ label: 'Factura', field: 'noFactura' },
			{ label: 'Proveedor', field: 'proveedor' },
			{ label: 'Usuario', field: 'cajero' },
			{ label: 'Total', field: 'total'},
			{ label: 'IVA', field: 'iva'},
			{ label: 'Pago', field: 'pago'},
			{ label: 'Estado', field: 'estado'},       
			{ label: 'Fecha Compra', field: 'fechaCompra' },
			{ label: 'Fecha Vencimiento', field: 'fechaVencimiento'} ,
			{ label: 'Fecha Registro', field: 'fechaRegistro'},
			{ label: 'Detalle', field: 'detalle'},             
		],
		Impuesto:[    
			{ text: '0% Excento', value: 0  },
			{ text: '1% Canasta Bàsica e Insumos', value: 1  }, 
			{ text: '2% Medicamentos, Primas, ect.' , value: 2  },
			{ text: '4% Boletos y Salud', value: 4  }, 
			{ text: '13% Tarifa General', value: 13  },                      
			],
			Comprobantes: [],
		codigo: '',
		modelAprobaciones: false,
		tipoDocumentos: [
			'FISICA',
			'JURIDICA',
			'EXTRANJERO',
			],
		offset: true,
		FechaCompra: null,
		TipoCambio: 0,
		cargando: false,
      	progreso: 0,
		FechaVencimiento: '',
		checkno: true,
		OtrosImpuestos: '',
		NoFactura: "01",
		fileName: '', // Para almacenar el nombre del archivo seleccionado
		cargoxml: false,
		MonedaPago: "CRC",
		nombre: '',
		
		SubFamilia: '',
		TipoPago: 'Contado',
		stock: 0,
		modelproveedor: false,
		dias:0,
		detalles:[],
		descuento: 0,
		MontoTotal: 0,
		SubTotal: 0,
		Impuestos: 0,
		confirma:false,
		Receptor: '',
		seriales: [],
		serial:'',
		time:"",
		TiposPago: ["Caja Chica","Contado","Crédito"],
		cabeceraSeriales: [
		{ text: 'Código Producto', value: 'codigoproducto', sortable: false  },
		{ text: 'Nombre Producto', value: 'descricion', sortable: false  },
		{ text: 'Código Serial', value: 'codigoSerial', sortable: false  },
		{ text: 'Estado ', value: 'estado', sortable: false  },
		{ text: 'Vencimiento ', value: 'vencimiento', sortable: false  },
		{ text: 'Proveedor ', value: 'proveedor', sortable: false  },
		{ text: 'Eliminar ', value: 'actionmovimiento', sortable: false  },
		],
		cabeceraDetalles: [
		{ text: 'Código', value: 'idarticulo'},  
		{ text: 'Descripción', value: 'descripcion'},
		{ text: 'Precio Venta', value: 'precio'  },
		{ text: 'Costo Sin IVA', value: 'precioCompra' },
		{ text: 'Cabys ', value: 'cabys' },
		{ text: '% Imp ', value: 'valorImpuesto'},
		{ text: 'Cantidad Ingresar', value: 'cantidadIngreso'},
		{ text: 'Opciones', value: 'actionopciones',},
		{ text: 'Eliminar', value: 'actionmovimiento'},
		{ text: 'IVI', value: 'actionivi'},
		{ text: 'Existe', value: 'actionexiste' },
		],
		ProveedoresDetalles: [
		{ text: 'Nombre', value: 'proveedor'},
		{ text: 'Tipo Ced', value: 'tipoCedula'},
		{ text: 'Cédula', value: 'cedula', sortable: false  },
		{ text: 'Correo', value: 'correo', sortable: false  },
		{ text: 'Dirección', value: 'direccion', sortable: false  },
		{ text: 'Eliminar', value: 'actionmovimiento', sortable: false },
		],
		precio_venta: 0,
		descripcion: '',
		Imp1: 0,
		Imp2: 0,
		Imp4: 0,
		Imp13: 0,
		menu: false,
		ganancia: '30',
		valida: 0,
		modelcompra : false,
		checksi: true,
		CantPersonas: 2,
		TipoMovimiento: [
			{text:'Entrada', value: 'INGRESO'},
			{text:'Salida', value:'SALIDA'}],
		validaMensaje:[],

		Categoria: 'PRODUCTO',
		esProducto: true,
		Ivi: true,
		adModal: 0,
		Localdiad: '',
		Localidades:[],
		TipoCompra: "Compra",
		TipoCompras: ['Compra','Gasto'],
		Mensaje: '',
		snackbar: false,
		modelxml: false,
		menu2: false,
		menu3: false,
		menu4: false,
		menu5: false,
		menu6: false,
		menu7: false,
		menu8: false,
		menu9: false,
		hora: "00:10",
		hora2: "24:00",
		hora4: 1,
		adAccion: 0,
		items: [
			{ title: 'Aprobaciones' },
			{ title: 'Nueva Compra Simple' },
			{ title: 'Nueva Compra con Inventario' },
			{ title: 'Crear Producto' },
		],
		adNombre: '',
		adId: '',
		barcode: '',
		cantidadIngreso: 0,
		listaAprobaciones: [],
		selectedFile: null,
		Costo:'',
		loading: false,
		menu20: false,
		validFactura:false,
		validFactura2:false,
		menu21: false,
		cedulaproveedor: '',
		OrdenCompra: '',
		on:null,
		tpcedulaproveedor: '',
		cabeceraAprobaciones:[],
		nombreproveedor: '',
		telefonoproveedor: '',
		correoproveedor: '',
		direccionproveedor: '',
		items2: [...Array(4)].forEach((_, i) => `Item ${i}`),
		codigoProducto: '',
		Proveedores:[],
		proveedor:null,
		categoria:13,
		modelSeriales: false,
		familia:'NA',
		precio: 0,
		modelinventario: false,
		familias:["Mi Producto","Na"],
		precioCompra:10,
		start: new Date().toISOString().substr(0, 10),
		precioVenta: 0,
		precioMall: 0,
		totalExento:null,   
		totalParcial:0,
		totalImpuesto:0,
		totalGravado: 0,
		totalDescuento:0,
		Clave: '',
		total:0,
		precioCentral: 0,
		precioDetalle: 0,
		precioMayor: 0,
		maximo:5,
		minimo:1,
		tipoProducto:'',
		maxDescuento: 90,
		comision:1,
		IdProductKey:0,
		localidades:[],
		Detalle: "",
		idProductKey: 0,
		facturaxml: null,
		localizacion:'',
		compuesto: false,
		uploadValue: 0,
		switch1: true,
		cocina: 0,
		estado: 0,
		columnas: [],
		DatoMovimiento: '',
		Cuentas: [],
		cuenta: null,
		SoN: 'S',
		localidad: "",
		columna1: null,
		productos: [],
        gestionProveedor: [],
		desserts: [],
		itemactual: null,
		editedIndex: -1,
    }),

    computed: {
		formTitle () {
			return this.editedIndex === -1 ? 'Agregar' : 'Actualizar'
		},
		calcularImpuesto1(){
		let resultado=0.0;
			for(let i=0;i<this.Comprobantes.length;i++){
				resultado=resultado+(this.Comprobantes[i].Imp1);
			}
			
			return resultado;
		},
		calcularImpuesto2(){
		let resultado=0.0;
			for(let i=0;i<this.Comprobantes.length;i++){
				resultado=resultado+(this.Comprobantes[i].Imp2);
			}
			
			return resultado;
		},
		calcularImpuesto4(){
		let resultado=0.0;
			for(let i=0;i<this.Comprobantes.length;i++){
				resultado=resultado+(this.Comprobantes[i].Imp4);
			}
			
			return resultado;
		},
		calcularImpuesto13(){
		let resultado=0.0;
			for(let i=0;i<this.Comprobantes.length;i++){
				resultado=resultado+(this.Comprobantes[i].Imp13);
			}
			
			return resultado;
		},
		calcularDescuento(){
		let resultado=0.0;
			for(let i=0;i<this.detalles.length;i++){
				resultado=resultado+(this.detalles[i].descuento * 1);
			}
			
			return resultado;
		},
		calcularExento(){
			let resultado=0.0;
			for(let i=0;i<this.detalles.length;i++){
				if(this.detalles[i].gravado === 'N')
				{
				resultado=resultado+(this.detalles[i].precio * this.detalles[i].cantidadIngreso);
				}
			}
			return resultado;
		},
		calcularIva13(){
			let resultado=0.0;
			for(let i=0;i<this.detalles.length;i++){
				if(this.detalles[i].valorImpuesto === 13 ) {
						resultado=resultado+(this.detalles[i].impuesto * 1);
					}
			}
			
			return resultado.toFixed(2);
		
		},
		calcularMasivoIva13(){
			let resultado=0.0;
			for(let i=0;i<this.Comprobantes.length;i++){
				resultado=resultado+(this.Comprobantes[i].MontoImpuesto * 1);
			}
			
			return resultado.toFixed(2);
		
		},
		calcularIva1(){
			let resultado=0.0;
			for(let i=0;i<this.detalles.length;i++){
				if(this.detalles[i].valorImpuesto === 13 ) {
						resultado=resultado+(this.detalles[i].impuesto * 1);
					}
			}
			
			return resultado.toFixed(2);
		
		},
		calcularIva2(){
			let resultado=0.0;
			for(let i=0;i<this.detalles.length;i++){
				if(this.detalles[i].valorImpuesto === 2 ) {
						resultado=resultado+(this.detalles[i].impuesto * 1);
					}
			}
			
			return resultado.toFixed(2);
		
		},
		calcularIva4(){
			let resultado=0.0;
			for(let i=0;i<this.detalles.length;i++){
				if(this.detalles[i].valorImpuesto === 4) {
						resultado=resultado+(this.detalles[i].impuesto * 1);
					}
			}
			
			return resultado.toFixed(2);
		},
		calcularImpuesto(){
		let resultado=0.0;
			for(let i=0;i<this.detalles.length;i++){
				if(this.detalles[i].gravado === 'S')
				{
				if(this.detalles[i].valorImpuesto === 13)
				{
				resultado+=((this.detalles[i].precioCompra * 0.13) * this.detalles[i].cantidadIngreso);
				} else
				if(this.detalles[i].valorImpuesto === 1)
				{
					resultado+=((this.detalles[i].precioCompra * 0.01) * this.detalles[i].cantidadIngreso);
				} else
				if(this.detalles[i].valorImpuesto === 2)
				{
					resultado+=((this.detalles[i].precioCompra * 0.02) * this.detalles[i].cantidadIngreso);
				} else
				if(this.detalles[i].valorImpuesto === 4)
				{
					resultado+=((this.detalles[i].precioCompra * 0.04) * this.detalles[i].cantidadIngreso);
				}
				}
			}
		return resultado.toFixed(2);
		},
		calcularTotal(){
			let resultado=0.0;
			resultado = parseFloat(this.totalGravado) + parseFloat(this.totalImpuesto);
		return resultado;
		},
		calcularGravado(){
		let resultado=0.0;
			for(let i=0;i<this.detalles.length;i++){
					resultado=resultado+((this.detalles[i].precioCompra * this.detalles[i].cantidadIngreso)) ;
				
			}
			return resultado.toFixed(2);
		},
		calcularSubTotal(){
		let resultado=0.0;
			resultado = parseFloat(this.totalExento) + parseFloat(this.totalGravado);
		return resultado;
		},
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
	  this.listarAsociadas();
      this.initialize()
      this.ObtenerDia();
      this.select();
      this.selectProveedores();
      this.selectProductos(this.$store.state.usuario.localidad);
	  this.listarCuentas();
    },

    methods: {
	listarCuentas(){
		const barcodepreview = Date.now();
		const idsa = barcodepreview.toString();
		this.NoFactura = idsa.substring(5, 12);
		const me =this;
		const header={"Authorization" : "Bearer " + this.$store.state.token};
		const configuracion= {headers : header};
		axios.get('api/EstadoCuentas/ResumenCuentas/'+this.$store.state.usuario.localidad,configuracion).then(function(response){
			
			me.data=response.data;
			me.loading = false;
			me.data.forEach(function(x){
				me.Cuentas.push({text: x.alias+ '-'+ x.etiqueta +' (' + x.valor + x.moneda + ')',value:x.etiqueta});
			});
		}).catch(function(error){
			alert(error);
		});
	},
	async listarAsociadas(){
		let items = [];
		const me=this;
		me.Localidades.push(me.$store.state.usuario.localidad);
		this.localidad = this.$store.state.usuario.localidad;
		const header={"Authorization" : "Bearer "};
		const configuracion= {headers : header};
		await axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
			items=response.data;
			items.forEach(function(x){
				me.Localidades.push(x.localdiadAsociada);
			});
		}).catch(function(error){
			alert("Producto no encontrado "+error);
			me.modelCarga=false;
		});
	},
	ExportToTable() {  
		const me = this;

		// Verificar soporte para FileReader
		if (typeof(FileReader) === "undefined") {
			alert("Sorry! Your browser does not support HTML5!");
			return;
		}

		const reader = new FileReader();
		reader.onload = function(e) {
			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(e.target.result, "application/xml");

			// Obtener datos de factura y comprobante
			me.NoFactura = xmlDoc.querySelector("NumeroConsecutivo")?.textContent || "";
			me.Clave = xmlDoc.querySelector("Clave")?.textContent || "";
			me.TipoCambio = xmlDoc.querySelector("TipoCambio")?.textContent || "0";
			me.totalGravado = xmlDoc.querySelector("TotalGravado")?.textContent || "0";
			me.SubTotal = xmlDoc.querySelector("TotalVentaNeta")?.textContent || "0";
			me.Impuestos = xmlDoc.querySelector("TotalImpuesto")?.textContent || "0";
			me.FechaCompra = xmlDoc.querySelector("FechaEmision")?.textContent?.substring(0, 10) || "";
			me.MontoTotal = xmlDoc.querySelector("TotalComprobante")?.textContent || "0";
			me.MonedaPago = xmlDoc.querySelector("CodigoMoneda")?.textContent || "CRC";

			// Información del proveedor
			const emisor = xmlDoc.querySelector("Emisor");
			me.cedulaproveedor = emisor?.querySelector("Numero")?.textContent || "";
			me.correoproveedor = emisor?.querySelector("CorreoElectronico")?.textContent || "";
			me.proveedor = emisor?.textContent || "";

			// Información del receptor
			me.Receptor = xmlDoc.querySelector("Receptor")?.textContent || "";
			me.totalExento = xmlDoc.querySelector("TotalExento")?.textContent || "0";
			me.descuento = xmlDoc.querySelector("TotalDescuentos")?.textContent || "0";
			me.nombreproveedor = me.proveedor;

			// Agregar proveedor y comprobante
			me.Proveedores.push(me.nombreproveedor);
			me.Comprobantes.push({
				id: me.Comprobantes.length + 2,
				proveedor: me.proveedor,
				total: me.MontoTotal,
				noFactura: me.NoFactura,
				gravado: me.totalGravado,
				exento: me.totalExento,
				iva: me.Impuestos,
				receptor: me.Receptor,
				moneda: me.MonedaPago,
				cedula: me.cedulaproveedor,
				correo: me.correoproveedor,
				descuento: me.descuento,
				clave: me.Clave,
				existe: false,
				cantidad: 0,
				tipocambio: me.TipoCambio,
			});

			// Procesar detalles de líneas
			const lineas = xmlDoc.querySelectorAll("LineaDetalle");
			lineas.forEach((linea) => {
				const codigoCabys = linea.querySelector("Detalle")?.textContent || "";
				const cantidad = linea.querySelector("Cantidad")?.textContent || "";
				const codigoInterno = linea.querySelector("CodigoComercial")?.textContent.trim() || "01";
				const precioCompra = linea.querySelector("PrecioUnitario")?.textContent || "";
				const valorImpuesto = parseInt(linea.querySelector("Tarifa")?.textContent || "0");
				const impuesto = parseFloat(linea.querySelector("Monto")?.textContent || "0");

				me.detalles.push({
					id: me.detalles.length + 2,
					idarticulo: codigoInterno,
					descripcion: codigoCabys,
					cabys: codigoCabys,
					proveedor: me.proveedor,
					precioCompra,
					cantidadIngreso: cantidad,
					precio: 0,
					descuento: 0,
					EsIvi: "IVI",
					valorImpuesto,
					impuesto,
					Ivi: true,
					familia: "",
					existe: false,
					cantidad: 0,
				});
			});

			// Mostrar la tabla
			document.getElementById('xmltable').style.display = 'block';
		};

		reader.readAsText(document.getElementById("xmlfile").files[0]);
	},
	CargarExcel() {
		this.modelCarga = true;
		let cols = [];
		const me = this;
		let cod = [];
		let Descripcion = "";
		let Venta = [];
		let costo = [];
		let cabys = [];
		const desc = [];
		let familia = [];
		let codBarras = [];
		let tarifaimp = [];
		let proveedor = [];
		let ivi = [];
		let cantingreso = [];
		let grabado = []; // Cambiado a let y revisa si realmente lo necesitas

		const input = document.getElementById('input');
		const XLSX = require('xlsx');

		const reader = new FileReader();
		reader.onload = (event) => {
			const data = new Uint8Array(event.target.result);
			const workbook = XLSX.read(data, { type: "array" });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

			me.columnas = rows;
			for (let j = 1; j < me.columnas.length; j++) {
				cols = me.columnas[j];
				cod = cols[0];
				Descripcion = cols[1];
				Venta = cols[2];
				costo = cols[3];
				cabys = cols[4];
				tarifaimp = cols[5];
				ivi = cols[6];
				cantingreso = cols[7];
				grabado = cols[8];
				familia = cols[9];
				codBarras = cols[10];
				proveedor = cols[11];
				me.detalles.push({
					id: Date.now(),
					idarticulo: cod,
					descripcion: Descripcion,
					precioCompra: costo,
					cantidadIngreso: cantingreso,
					precio: Venta,
					descuento: desc,
					EsIvi: "IVI",
					grabado,
					valorImpuesto: tarifaimp,
					Ivi: ivi,
					existe: false,
					familia, // Uso de shorthand
					codigoBarras: codBarras,
					cabys, // Uso de shorthand
					proveedor, // Uso de shorthand
				});
			}
			me.menu = false;
			me.ValidarExistencia();
		};

		reader.readAsArrayBuffer(input.files[0]);
	},


	ColorTabla(id,NoFactura){
		if(NoFactura === ""){
			return "red"
		}
		if(id === false){
			return "white"
		}  if(id === true) {
			return "red"
		}
	},
	onFileClick() {
      this.$refs.fileInput.click(); // Abre el diálogo de selección de archivos
    },
	async listarInventarioLocalidad() {
		this.modelCarga = true;
		const header = { Authorization: "Bearer " };
		const configuracion = { headers: header };

		try {
			const response = await axios.get(
			'api/ProductosWebs/ListarMachote/' + this.$store.state.usuario.localidad,
			configuracion
			);
			this.datamachote = response.data.forEach(item => ({
			idarticulo: item.codigoProducto,
			descripcion: item.descripcion,
			precio: item.precioVenta,
			precioCompra: item.precioCompra,
			Cabys: item.codigoRaiz,
			valorImpuesto: item.valorImpuesto,
			Ivi: item.estadoInventario,
			cantidadIngreso: item.cantidadInvActual,
			impuesto: item.valorImpuesto,
			familia: item.familia,
			proveedor: item.proveedor,
			innerPack: item.innerPack,
			barcode:item.codigoBarras,
			maximo: item.maximo,
			minimo: item.minimo,
			precioMayor: item.precioMayor,
			precioDetalle: item.precioDetalle,
			precio3: item.precioCentral,
			comision: item.comision,
			maxDescuento: item.maxDescuento
			}));
		} catch (error) {
			alert('Productos no encontrados');
		} finally {
			this.modelCarga = false;
		}
		},

    handleDownload() {
      // await this.listarInventarioLocalidad();
      this.$refs.excelDownloader.downloadExcel();
    },
	ExportToTable2(event) {
		const me = this;
		let Id = 1;
		const promises = [];

		for (let j = 0; j < event.target.files.length; j++) {
			const file = event.target.files[j];
			
			// Verificar si el archivo es un XML
			if (!file.name.toLowerCase().endsWith('.xml')) {
				alert('Archivo no válido: ' + file.name);
				continue;
			}

			const promise = new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = function(e) {
					const parser = new DOMParser();
					const xmlDoc = parser.parseFromString(e.target.result, "text/xml");

					// Obtener los valores del XML
					const NoFactura = xmlDoc.querySelector("NumeroConsecutivo")?.textContent || "";
					me.Clave = xmlDoc.querySelector("Clave")?.textContent || "";
					me.TipoCambio = xmlDoc.querySelector("TipoCambio")?.textContent || "1";
					me.totalGravado = xmlDoc.querySelector("TotalGravado")?.textContent || "0";
					me.SubTotal = xmlDoc.querySelector("TotalVentaNeta")?.textContent || "0";
					const ivatotal = xmlDoc.querySelector("TotalImpuesto")?.textContent || "0";
					me.Impuestos = ivatotal || "0";
					me.FechaCompra = xmlDoc.querySelector("FechaEmision")?.textContent || "";
					me.MontoTotal = xmlDoc.querySelector("TotalComprobante")?.textContent || "0";
					me.MonedaPago = xmlDoc.querySelector("CodigoMoneda")?.textContent || "CRC";
					
					const emisor = xmlDoc.querySelector("Emisor");
					me.cedulaproveedor = emisor.querySelector("Numero")?.textContent || "";
					me.correoproveedor = emisor.querySelector("CorreoElectronico")?.textContent || "";
					
					me.proveedor = emisor?.textContent || "";
					me.Receptor = xmlDoc.querySelector("Receptor")?.textContent || "";
					me.totalExento = xmlDoc.querySelector("TotalExento")?.textContent || "0";
					me.descuento = xmlDoc.querySelector("TotalDescuentos")?.textContent || "0";

					// Verificar si la factura ya existe
					for (let l = 0; l < me.Comprobantes.length; l++) { 
						if (me.Comprobantes[l].ConsecutivoDocumento === NoFactura) {
							me.Mensaje = `La Factura ${NoFactura} de ${me.Comprobantes[l].Proovedor} ya fue registrada. No se pueden cargar repetidas.`;
							me.snackbar = true;
							return;
						}
					}

					// Procesar lineas de detalle
					const lineasDetalle = xmlDoc.querySelectorAll("LineaDetalle");
					let impuesto1 = 0; let impuesto2 = 0; let impuesto4 = 0; let impuesto13 = 0;

					lineasDetalle.forEach(linea => {
						// const codigo = linea.querySelector("CodigoComercial")?.textContent.trim() || "01";
						const iva = parseFloat(linea.querySelector("Monto")?.textContent || "0");
						const tarifa = parseInt(linea.querySelector("Tarifa")?.textContent || "0");

						switch (tarifa) {
							case 1: impuesto1 += iva; break;
							case 2: impuesto2 += iva; break;
							case 4: impuesto4 += iva; break;
							case 13: impuesto13 += iva; break;
						}
					});

					// Crear el comprobante
					const comprobante = {
						// idarticulo: codigo,
						Proovedor: me.proveedor,
						Monto_Factura: me.MontoTotal,
						ConsecutivoDocumento: NoFactura,
						TotalGravado: me.totalGravado,
						TotalExento: me.totalExento,
						MontoImpuesto: ivatotal,
						id: Id,
						Razon_Social: me.Receptor,
						Moneda: me.MonedaPago,
						Cedula: me.cedulaproveedor,
						TipoDocumento: '01',
						CorreoProv: me.correoproveedor,
						TotalDescuento: me.descuento,
						existe: false,
						Fecha: me.FechaCompra,
						Clave: me.Clave,
						TipoCambio: me.TipoCambio,
						Imp1: impuesto1,
						Imp2: impuesto2,
						Imp4: impuesto4,
						Imp13: impuesto13,
						Estado: "Aceptado",
					};

					Id++;
					resolve(comprobante);
				};

				reader.onerror = function() {
					reject(new Error("Error al leer el archivo XML"));
				};
				
				reader.readAsText(file);
			});

			promises.push(promise);
		}

		Promise.all(promises).then(comprobantes => {
			comprobantes.forEach(comprobante => {
				me.Comprobantes.push(comprobante);
			});
			me.ValidarExistenciaXML();
		}).catch(error => {
			alert("error al procesar los archivos xml "+error);
		});
	},

	ExportToTable3(event) {
		const me = this;
		const files = event.target.files;

		for (let j = 0; j < files.length; j++) {
			const file = files[j];

			if (typeof(FileReader) !== "undefined") {
				const reader = new FileReader();

				reader.onload = function(e) {
					const parser = new DOMParser();
					const xmlDoc = parser.parseFromString(e.target.result, "text/xml");

					me.NoFactura = xmlDoc.querySelector("NumeroConsecutivo")?.textContent || "";
					me.Clave = xmlDoc.querySelector("Clave")?.textContent || "";
					me.TipoCambio = xmlDoc.querySelector("TipoCambio")?.textContent || "1";
					me.totalGravado = xmlDoc.querySelector("TotalGravado")?.textContent || "0";
					me.SubTotal = xmlDoc.querySelector("TotalVentaNeta")?.textContent || "0";
					me.Impuestos = xmlDoc.querySelector("TotalImpuesto")?.textContent || "0";
					me.FechaCompra = xmlDoc.querySelector("FechaEmision")?.textContent || "";
					me.MontoTotal = xmlDoc.querySelector("TotalComprobante")?.textContent || "0";
					me.MonedaPago = xmlDoc.querySelector("CodigoMoneda")?.textContent || "CRC";

					// Cedula y correo del proveedor
					const emisor = xmlDoc.querySelector("Emisor");
					me.cedulaproveedor = emisor.querySelector("Numero")?.textContent || "";
					me.correoproveedor = emisor.querySelector("CorreoElectronico")?.textContent || "";

					// Nombre del proveedor y receptor
					me.proveedor = emisor?.textContent || "";
					me.Receptor = xmlDoc.querySelector("Receptor")?.textContent || "";

					// Total Exento y Descuento
					me.totalExento = xmlDoc.querySelector("TotalExento")?.textContent || "0";
					me.descuento = xmlDoc.querySelector("TotalDescuentos")?.textContent || "0";

					let impuesto1 = 0; let impuesto2 = 0; let impuesto4 = 0; let impuesto13 = 0;

					// Procesar Lineas de Detalle
					const lineasDetalle = xmlDoc.querySelectorAll("LineaDetalle");

					lineasDetalle.forEach(linea => {
						// const codigo = linea.querySelector("CodigoComercial")?.textContent.trim() || "01";
						const iva = parseFloat(linea.querySelector("Monto")?.textContent || "0");
						const tarifa = parseInt(linea.querySelector("Tarifa")?.textContent || "0");

						switch (tarifa) {
							case 1: impuesto1 += iva; break;
							case 2: impuesto2 += iva; break;
							case 4: impuesto4 += iva; break;
							case 13: impuesto13 += iva; break;
						}
					});

					me.Comprobantes.push({
						Proovedor: me.proveedor,
						Monto_Factura: me.MontoTotal,
						ConsecutivoDocumento: me.NoFactura,
						TotalGravado: me.totalGravado,
						TotalExento: me.totalExento,
						MontoImpuesto: me.Impuestos,
						Razon_Social: me.Receptor,
						Moneda: me.MonedaPago,
						Cedula: me.cedulaproveedor,
						TipoDocumento: '01',
						CorreoProv: me.correoproveedor,
						TotalDescuento: me.descuento,
						Fecha: me.FechaCompra,
						Clave: me.Clave,
						TipoCambio: me.TipoCambio,
						Imp1: impuesto1,
						Imp2: impuesto2,
						Imp4: impuesto4,
						Imp13: impuesto13,
						Estado: "Aceptado",
					});
				};

					reader.readAsText(file);
				} else {
					alert("¡Lo siento! Tu navegador no soporta HTML5.");
				}
			}
		},

	async AgregarInventario() {
		this.modelCarga = true;
		this.cargando = true;
		this.progreso = 0; // Reinicia el progreso al inicio
		const me = this;

		// Filtrar los productos que no existen y preparar los datos
		const productosDatos = this.detalles.filter(det => !det.existe).map(det => {
			const compraCalculada = det.precioCompra * (1 + (det.valorImpuesto / 100));
			return {
			CodigoProducto: det.idarticulo,
			Cantidad: parseFloat(det.cantidadIngreso),
			UnidadMedida: "UND",
			Descripcion: det.descripcion,
			PrecioVenta: det.precio,
			PrecioCompra: compraCalculada,
			ValorImpuesto: det.valorImpuesto,
			Gravado: det.gravado,
			CodigoRaiz: det.cabys,
			TipoProducto: det.familia,
			BarCode: det.codigoBarras,
			EstadoInventario: det.Ivi,
			Exe_Grad: det.valorImpuesto === 0 ? 'N' : 'S',
			proveedor: det.proveedor,
			localizacion: "https://noah.cr/ImagenesGenerales/usuario.png",
			InnerPack: det.innerPack,
			Maximo: det.maximo,
			Minimo: det.minimo,
			PrecioMayor: det.precioMayor,
			PrecioDetalle: det.precioDetalle,
			PrecioMall: det.precio3,
			Compuesto: 0,
			MaxDescuento: det.MaxDescuento
			};
		});

		const configuracion = { headers: { "Authorization": "Bearer " + this.$store.state.token } };
		const totalProductos = productosDatos.length;
		const batchSize = 300; // Define un tamaño de lote adecuado

		try {
			for (let i = 0; i < totalProductos; i += batchSize) {
			const batch = productosDatos.slice(i, i + batchSize);

			// Enviar el lote
			await axios.post('api/Productos/CrearMasivo', {
				Localidad: me.localidad,
				detalles: batch,
			}, configuracion);

			// Actualizar el progreso
			this.progreso = Math.round(((i + batch.length) / totalProductos) * 100);
			}

			// Si todo sale bien
			me.modelCarga = false;
			me.progreso = 100; // Asegúrate de que sea 100 al finalizar
			me.cargamdo = false;
			me.Mensaje = 'Productos cargados de forma exitosa en ' + me.localidad + '. Validando existencias actuales.';
			me.snackbar = true;
			me.ValidarExistencia();
		} catch (err) {
			me.modelCarga = false;
			if (err.response) {
			const errorResponse = err.response.data;
			let errorMessage = "Ocurrió un error: ";
			if (errorResponse && errorResponse.detalles) {
				errorMessage += errorResponse.detalles.join(', ');
			} else {
				errorMessage += errorResponse.message || "Error desconocido";
			}
			alert(errorMessage);
			} else {
			alert("Error de conexión o error desconocido");
			}
		}
	},	

	initialize(){
		const me=this;
		const barcodepreview = Date.now();
		const idsa = barcodepreview.toString();
		this.codigoProducto = idsa.substring(5, 12);
		this.barcode = idsa.substring(5, 12);
		this.modelCarga = true;
		// let header={"Authorization" : "Bearer " + this.$store.state.token};
		const header={"Authorization" : "Bearer "};
		const configuracion= {headers : header};
		axios.get('api/FacturasCompras/Listar/'+this.localidad,configuracion).then(function(response){
		me.articulos=response.data;
		me.modelCarga = false;
		}).catch(function(error){
			me.snackbar = true;
			me.modelCarga = false;
			me.Mensaje = 'No contiene compras almacenadas '+error;
		});
	},
		ObtenerGanancia(){
			// Convertimos el porcentaje de ganancia a decimal
			const porcentajeGanancia = parseFloat(this.ganancia) / 100;
			// alert(porcentajeGanancia);
			for(let i = 0; i < this.detalles.length; i++){
				// Calculamos el impuesto para el precio de compra
			const impuesto = parseFloat(this.detalles[i].precioCompra) * (parseFloat(this.detalles[i].valorImpuesto) / 100);
			// alert("Impuesto: "+impuesto);
			// Calculamos el precio con el impuesto incluido
				const Ganancia = this.detalles[i].precioCompra * porcentajeGanancia
				// alert("Ganancia: "+Ganancia);
				const PrecioFinal = parseFloat(this.detalles[i].precioCompra) + impuesto +Ganancia;
				// alert("Precio Con impuesto: "+gananciaDecimal);
				// alert("Precio Final: "+ "impuesto"+impuesto +PrecioFinal);
				const PrecioVenta =  parseInt(PrecioFinal);

				
				// Calculamos el precio final considerando la ganancia
				this.detalles[i].precio = PrecioVenta;
			}
		},

		close () {
			this.dialog = false;
			this.limpiar();
		},
      	limpiar(){
			this.precioVenta= 0;
			this.precioCompra=0;
			this.descripcion="";
			this.barcode="";
			this.SubTotal = 0;
			this.NoFactura = '0';
			this.OrdenCompra = '';
			this.Detalle = '';
			this.Impuestos = 0;
			this.proveedor = "";
			this.familia = "";
			this.maximo=5;
			this.minimo=0;
			this.comision = 0;
			this.categoria = 0;
			this.subFamilia="";
			this.codigoProducto=0;
			this.descripcion="";
			this.editedIndex=-1;
			this.maxDescuento = 0;
      	},
		EsGrabado(item){
		if(item.valorImpuesto === 0){
			item.gravado = 'N'
			return;
		}
		item.gravado = 'S'
		},
		buscarCodigo(){
               const me=this;
                me.errorArticulo=null;
                if (this.codigo !== "")
                {
                    const header={"Authorization" : "Bearer "};
                    const configuracion= {headers : header};
                    axios.get('api/ProductosWebs/BuscarCodigoVenta/'+this.codigo,configuracion)
                    .then(function(response){
                        me.agregarDetalle(response.data);
                    }).catch(function(error){
                        alert("No existe el artículo "+error);
                    });
                }
                this.codigo = "";
    },
	buscarProducto(){
		// this.modelCarga = true;
		const me=this;
		me.errorArticulo=null;
		if (this.codigo !== "")
		{
			const header={"Authorization" : "Bearer "};
			const configuracion= {headers : header};
			axios.get('api/ProductosWebs/BuscarCodigoVenta/'+this.codigo.value+'/'+this.$store.state.usuario.localidad,configuracion)
			.then(function(response){
				me.agregarDetalle(response.data);
			}).catch(function(error){
				alert("No existe el artículo "+error);
			});
		}
    },
	LigarProducto(){
		for(let i=0;i<this.detalles.length;i++){
            if(this.detalles[i].idarticulo === this.itemactual){
				this.detalles[i].idarticulo = this.codigo.value;
			}
          }
		
		this.menu20 = false;
		this.ValidarExistencia();
	},
	AbrirLigue(item){
		this.itemactual = item.idarticulo;
		this.menu20 = true;
	},
    selectLocalidad(){
                const me=this;
                let localidadesArray=[];
                const header={"Authorization" : "Bearer "};
                const configuracion= {headers : header};
                axios.get('api/Localidades/SelectLocalidades',configuracion).then(function(response){
                    localidadesArray=response.data;
                    localidadesArray.forEach(function(x){
                        me.localidades.push({text: x.nombre,value:x.nombre});
                    });
                })
    },
    onChangeFileUpload(event){
        this.facturaxml = event.target.files[0];
      },
    AprobarProducto(item){
      const header={"Authorization" : "Bearer " + this.$store.state.token};
      const configuracion= {headers : header};
          const me=this;
          axios.put('api/SalidaProductos/Aprobar',{
              'IdSalida':item.idsalida,
              'Descripcion':item.descripcion,
              'Codigo': item.codigo,
              'Localidad': item.localidad,
              'Costo': item.costo,
              'Cajero': item.cajero,
              'Cantidad': item.cantidad,
              'Estado': 0,
              'Tipo': 'ENTRADA'
          },configuracion).then(function(response){
              me.snackbar = true;
              me.Mensaje = 'Registro Aprobado correctamente';
              me.Aprobaciones();                     
          }).catch(function(error){
              me.snackbar = true;
              me.Mensaje = 'Error al aprobar, reporte a su proveedor el problema '+error;   
          });
    },
    DenegarProducto(item){
       
      const header={"Authorization" : "Bearer " + this.$store.state.token};
      const configuracion= {headers : header};
          const me=this;
          axios.put('api/SalidaProductos/Aprobar',{
              'IdSalida':item.idsalida,
              'Estado': -1,
          },configuracion).then(function(response){
              me.snackbar = true;
              me.Mensaje = 'Registro Denegado correctamente';
              me.Aprobaciones();                     
          }).catch(function(error){
              me.snackbar = true;
              me.Mensaje = 'Error al aprobar, reporte a su proveedor el problema '+error;   
          });
    },
     eliminarProducto(id){
        this.modelCarga = true;
        const me = this;
          if(confirm("Seguro que quiere eliminar el Serial ?")){
              me.seriales = me.seriales.filter(e => e.id !== id)
              const header={"Authorization" : "Bearer " + this.$store.state.token};
              const configuracion= {headers : header};
               axios.post('api/CodigosSeriales/Delete',{
                  'Id': id,
                      
                },configuracion).then(function (response) {
                    me.snackbar = true;
                    me.Mensaje = 'Serial Eliminado Con éxito.';  
                    me.modelCarga = false;
                    
                  })
                  .catch(function (error) {
                      me.snackbar = true;
                      me.Mensaje = 'Error eliminando el serial.' +error;    
                      me.modelCarga = false;
                    })
          }


      },
      eliminarProducto2(id){
              this.detalles = this.detalles.filter(e => e.id !== id)

      },
      editarProductoSuma(index){
        this.cantidades = this.detalles[index].cantidad
        this.indexProducto = index 
        this.detalles[this.indexProducto].cantidad = this.cantidades + 1
       // this.sumarCantidadProducto();
      },
      editarProductoResta(index){
          if(this.detalles[index].cantidad !== 1){
            this.cantidades = this.detalles[index].cantidad
            this.indexProducto = index 
            this.detalles[this.indexProducto].cantidad = this.cantidades - 1
          } 
        
       // this.sumarCantidadProducto();
      },
	eliminarProductoLinea(id){
		// console.log(id)
		this.Comprobantes = this.Comprobantes.filter(e => e.id !== id)
		// console.log(this.cards)
	},
    agregarDetalle(data = []) {
		this.errorArticulo = null;
		const EsIvi = data.estadoInventario ? 'IVI' : 'IVA No incluido';
		let precioComprasinImp;

		if (data.codigoProducto) {
			this.codigo = data.codigoProducto;
		} else {
			this.codigo = ''; // Asegurarse de que se establezca en vacío si no hay código
		}

		// Calcular el precio sin impuesto basado en valorImpuesto
		switch (data.valorImpuesto) {
			case 13:
				precioComprasinImp = data.precioCompra / 1.13;
				break;
			case 1:
				precioComprasinImp = data.precioCompra / 1.01;
				break;
			case 2:
				precioComprasinImp = data.precioCompra / 1.02;
				break;
			case 4:
				precioComprasinImp = data.precioCompra / 1.04;
				break;
			default:
				precioComprasinImp = data.precioCompra;
		}

		if (this.codigo !== '') {
			this.detalles.push({
				id: Date.now(),
				idarticulo: data.codigoProducto,
				descripcion: data.descripcion,
				cantidadActual: data.cantidadInvActual,
				cantidadIngreso: 1,
				cantidad: 1,
				descuento: 0,
				precio: data.precioVenta,
				precioCompra: precioComprasinImp.toFixed(2),
				valorImpuesto: data.valorImpuesto,
				gravado: data.exe_Grad,
				Ivi: data.estadoInventario,
				cabys: data.codigoRaiz,
				EsIvi,
				existe: true,
			});

			this.codigo = '';
			this.modelCarga = false;
		} else {
			alert("Debe digitar un código de producto o servicio");
			this.modelCarga = false;
		}
	},

    agregarSerial(){
      this.modelCarga = true;
            this.seriales.push(
              {
              id: Date.now(),
              codigoSerial:this.serial,
              estado:"En Stock",
              codigoproducto: this.codigoProducto,
              descricion: this.nombre,
			  vencimiento: this.start,
			  proveedor: this.nombreproveedor,
              }
              );
          const me = this;
          const header={"Authorization" : "Bearer " + this.$store.state.token};
          const configuracion= {headers : header};
            axios.post('api/CodigosSeriales/Crear',{
                  'Estado': "En Stock",
                  'Localidad': this.$store.state.usuario.localidad,
                  'Codigoproducto': me.codigoProducto,
                  'CodigoSerial': me.serial,
                  'Usuario':this.$store.state.usuario.localidad,
                  'Descricion': me.nombre,
				  'Vencimiento': me.start,
				  'Proveedor':me.nombreproveedor,
                      
                },configuracion).then(function (response) {
                      me.snackbar = true;
                      me.Mensaje = 'Serial registrado de forma exitosa';  
                      me.serial = '';
                      me.modelCarga = false
                  })
                  .catch(function (error) {
                      me.snackbar = true;
                      me.Mensaje = 'El Codigo serial ya fue registrado.'+error;  
                      me.modelcompra = false;
                })
    },
    encuentra(id){
                let sw=0;
                for(let i=0;i<this.detalles.length;i++){
                    if (this.detalles[i].idarticulo===id){
                        sw=1;
                    }
                }
                return sw;
    },
    Limpiarxml(){
      this.Comprobantes = [];
      this.detalles = [];
    },
    	generarInventarioSimple(){
			if (this.NoFactura === ''){
				this.Mensaje = 'Seleccione el tipo de movimiento';
				this.snackbar = true;
				return
			
			}  {
			this.loading = true;
			const me = this;
			const header={"Authorization" : "Bearer " + this.$store.state.token};
			const configuracion= {headers : header};
			axios.post('api/FacturasCompras/Crear',{
				'Cajero': this.$store.state.usuario.nombre,
				'Localidad': this.localidad,
				'Total': me.MontoTotal,
				'Proveedor': me.proveedor,
				'NoFactura': me.NoFactura,
				'FechaCompra': me.FechaCompra,
				'FechaVencimiento': me.FechaVencimiento,
				'Pago': me.TipoPago,
				'Grabado':me.SubTotal,
				'iva': me.Impuestos,
				'Excento': 0,
				'Detalle': me.Detalle,
				'Moneda':me.MonedaPago,
				'SubTotal': me.SubTotal,
				'Descuento': 0,
				'OrdenCompra': me.OrdenCompra,
				'CuentaContable':me.cuenta,
				'Estado': "Pagado",
				'TipoCompra': me.TipoPago,
				'Dias':me.dias,
			},configuracion).then(function (response) {
					me.snackbar = true;
					me.Mensaje = 'Factura registrada de forma exitosa';  
					me.initialize();
					me.modelcompra = false;
					me.limpiar();
					me.loading =false;
				})
				.catch(function (error) {
					me.snackbar = true;
					me.Mensaje = 'La factura digitada ya ha sido ingresada. '+error;  
					me.modelcompra = false;
					me.limpiar();
					me.loading = false;
			})
			} 
    	},
		RegistrarCXP(){
			
		},	
		generarInventarioSimpleXML(){
			if (this.Comprobantes === ""){
					this.Mensaje = 'Cargue un xml para almacenar la compra';
					this.snackbar = true;
					return
			
				}  {
			this.loading = true;
			const me = this;
			const header={"Authorization" : "Bearer " + this.$store.state.token};
			const configuracion= {headers : header};
				axios.post('api/FacturasCompras/Crear',{
                  'Cajero': this.$store.state.usuario.nombre,
                  'Localidad': this.$store.state.usuario.localidad,
                  'detalles': me.detalles,
                  'Total': me.MontoTotal,
                  'Proveedor': me.proveedor,
                  'NoFactura': me.NoFactura,
                  'FechaCompra': me.FechaCompra,
                  'FechaVencimiento': me.FechaCompra,
                  'Pago': me.TipoPago,
                  'Grabado':me.SubTotal,
                  'iva': me.Impuestos,
                  'Excento': me.totalExento,
                  'Detalle': me.Detalle,
                  'SubTotal': me.SubTotal,
                  'Descuento': 0,
                  'OrdenCompra': me.OrdenCompra,
                  'Estado': "Pagado",
                  'TipoCompra': me.TipoCompra,
                      
                },configuracion).then(function (response) {
                      me.CompraHacienda();
                  })
                  .catch(function (error) {
                      me.snackbar = true;
                      me.Mensaje = 'La factura digitada ya ha sido ingresada. '+error;  
                      me.modelcompra = false;
                      me.limpiar();
                })
			} 
    },
    async CompraHacienda(){
          if(this.TipoCambio === ""){
            this.TipoCambio = 0;
		  }
		  if(this.descuento === ""){
			  this.descuento = 0;
		  }
          this.loading = true;
          const me = this;
          const header={"Authorization" : "Bearer " + this.$store.state.token};
          const configuracion= {headers : header};
            await axios.post('api/ComprasHaciendas/Crear',{
                  'ConsecutivoDocumento': me.NoFactura,
                  'Fecha': me.FechaCompra,
                  'Estado': 'Aceptado',
                  'Proovedor': me.proveedor,
                  'Cedula': me.cedulaproveedor,
                  'CorreoProv': me.correoproveedor,
                  'Razon_Social': me.Receptor,
                  'TipoDocumento': 'Factura Electronica',
                  'Localidad': me.$store.state.usuario.localidad,
                  'Clave':me.Clave,
                  'Justificacion': 'Aceptado',
                  'Moneda': me.MonedaPago,
                  'TipoCambio': me.TipoCambio,
                  'TotalDescuento': me.descuento,
                  'TotalGravado': me.SubTotal,
                  'Imp1': me.Imp1,
                  'Imp2': me.Imp2,
                  'Imp4': me.Imp4,
                  'Imp13': me.Imp13,
                  'MontoImpuesto': me.Impuestos,
                  'Monto_Factura': me.MontoTotal,
                      
                },configuracion).then(function (response) {
                      me.snackbar = true;
                      me.Mensaje = 'Factura registrada de forma exitosa';  
                      me.initialize();
                      me.Limpiarxml();
                  })
                  .catch(function (error) {
					  alert('La factura digitada ya ha sido ingresada o '+error );
                      me.Limpiarxml();
                      
                })
    },
	ComprasMasivas(){
		for (let j = 0; j < this.Comprobantes.length; j++) { 
			if(this.Comprobantes[j].existe === true ){
			this.Mensaje = 'La Factura '+this.Comprobantes[j].ConsecutivoDocumento+" de " +this.Comprobantes[j].Proovedor+ " ya fue registrada, revise que no tenga lineas en rojo";
			this.snackbar = true;
			return;
			
			}
		}
          if(this.TipoCambio === ""){
            this.TipoCambio = 0;
		  }
		  if(this.descuento === ""){
			  this.descuento = 0;
		  }
          this.loading = true;
          const me = this;
		  let link = "";
          const header={"Authorization" : "Bearer " + this.$store.state.token};
          const configuracion= {headers : header};
            axios.post('api/ComprasHaciendas/CrearMasivo',{
                  'Producto': me.Comprobantes,
                  'Localidad': me.$store.state.usuario.localidad,
				  'Justificacion': me.Detalle,
                      
                },configuracion).then(function (response) {
                      me.snackbar = true;
                      me.Mensaje = 'Factura registrada de forma exitosa';
                      link = location.origin+'/'+'repcompras';
					  location.assign(link); 
                  })
                  .catch(function (error) {
					  alert('Ocurrió un error almacenando una factura: '+error );
                      
                      
                })
		
    },
	ValidarXMLS(){
		for (let j = 0; j < this.Comprobantes.length; j++) { 
			if(this.Comprobantes[j].existe === true ){
			this.Mensaje = 'La Factura '+this.Comprobantes[j].consecutivoDocumento+" ya fue registrada, revise que no tenga lineas en rojo";
			this.snackbar = true;
			return false;
			
			}
		}
		return true;
	},
    async EliminarCompra(item) {
		if (confirm("¿Seguro que desea eliminar la Compra " + item.noFactura + "?")) {
			try {
				const header = { "Authorization": "Bearer " + this.$store.state.token };
				const configuracion = { headers: header };

				await axios.post('api/FacturasCompras/EliminarCompra', {
					IdFacturasKey: item.idFacturasKey
				}, configuracion);

				this.snackbar = true;
				this.Mensaje = 'Factura Eliminada con éxito.';
				this.initialize();
			} catch (error) {
				this.snackbar = true;
				this.Mensaje = 'Error eliminando la factura.';
			}
		}
	},

	async generarInventario(){
          if (this.NoFactura === ''){
                this.Mensaje = 'Seleccione el tipo de movimiento';
                this.snackbar = true;
                return
          
            }
			if(this.proveedor === null){
				this.Mensaje = 'Seleccione el proveedor';
                this.snackbar = true;
                return
			}
			for(let i=0;i<this.detalles.length;i++){
                if(this.detalles[i].existe === false){
                  this.Mensaje = 'Hay Productos que aún no existen, asegurese de que todos tengan el check verde para continuar';
                  this.snackbar = true;
				  if(confirm("Hay Productos que aún no estan registrados en sistema, desea detener el proceso para revisar?")){
					  	return;
				  }
                }

              this.detalles[i].precioCompra = parseFloat((parseFloat(this.detalles[i].precioCompra)* parseFloat((this.detalles[i].valorImpuesto / 100))) + parseFloat(this.detalles[i].precioCompra))
              
              }
          {
          this.modelCarga  = true;
          const me = this;
          const header={"Authorization" : "Bearer " + this.$store.state.token};
          const configuracion= {headers : header};
            await axios.post('api/FacturasCompras/Crear',{
				'Cajero': this.$store.state.usuario.nombre,
				'Localidad': this.localidad,
				'detalles': me.detalles,
				'Total': me.total,
				'Proveedor': me.proveedor,
				'NoFactura': me.NoFactura,
				'FechaCompra': me.FechaCompra,
				'FechaVencimiento': me.FechaVencimiento,
				'Pago': me.TipoPago,
				'Grabado':me.SubTotal,
				'iva': me.Impuestos,
				'Excento': 0,
				'Detalle': me.Detalle,
				'Moneda':me.MonedaPago,
				'SubTotal': me.SubTotal,
				'Descuento': 0,
				'OrdenCompra': me.OrdenCompra,
				'CuentaContable':me.cuenta,
				'Estado': "Pagado",
				'TipoCompra': me.TipoPago,
				'Dias':me.dias,
                      
                },configuracion).then(function (response) {
					// me.CompraHacienda()
                    me.generarInventario2();
                    
                  })
                  .catch(function (error) {
						alert("Error ingresando factura valide si el numero de factura ya fue ingresado, envie esté mensaje a su equipo de soporte para solucionar el error "+error)
						me.modelCarga = false;
                })
                } 
    },
    async ValidarExistencia() {
		const header = { "Authorization": "Bearer " + this.$store.state.token };
		const configuracion = { headers: header };

		// Preparar la lista de productos para enviar a la API
		const productosParaValidar = this.detalles.map(detalle => {
			return {
			CodigoProducto: detalle.idarticulo,
			Localidad: this.localidad// Asumiendo que todos los productos pertenecen a la misma localidad
			};
		});
		this.modelCarga = true;
		try {
			// Hacer la llamada POST con la lista de productos
			const response = await axios.post('api/Productos/VerificarExistencia', productosParaValidar, configuracion);
			const existencias = response.data; // Esto será un arreglo de booleanos

			// Actualizar la propiedad 'existe' para cada detalle
			this.detalles.forEach((detalle, index) => {
			detalle.existe = existencias[index];
			});

		} catch (error) {
			alert(error);
		}

		this.modelCarga = false;
	},


	ValidarExistenciaXML(){
      	const me = this;
		const header = { "Authorization": "Bearer " };
		const configuracion = { headers: header };
		const clavesParaVerificar = me.Comprobantes.forEach(c => c.Clave);

		axios.post('api/ComprasHaciendas/VerificarExistenciaPorClave', clavesParaVerificar, configuracion)
			.then(function(response) {
				const comprobantesExistentes = response.data;
				me.Comprobantes.forEach(comprobante => {
					comprobante.existe = comprobantesExistentes[comprobante.Clave] || false;
				});
			})
			.catch(function(error) {
				alert(error);
			});
		me.modelCarga = false;
	},
    async generarInventario2() {
		if (this.NoFactura === '') {
			this.Mensaje = 'Seleccione el No de factura';
			this.snackbar = true;
			return;
		}

		this.modelCarga = true;
		this.loading = true;

		try {
			const header = { "Authorization": "Bearer " + this.$store.state.token };
			const configuracion = { headers: header };

			// Enviar productos en lotes para evitar sobrecarga
			const batchSize = 400; // Puedes ajustar el tamaño del lote según sea necesario
			const productosParaValidar = this.detalles; // Lista de detalles a enviar
			const confirma = this.confirma; // Obtener confirmación

			for (let i = 0; i < productosParaValidar.length; i += batchSize) {
				const batch = productosParaValidar.slice(i, i + batchSize);
				
				const response = await axios.post('api/ProductosWebs/MovimientoInventario2', {
					'Usuario': this.$store.state.usuario.nombre,
					'localidad': this.localidad,
					'detalles': batch,
					'Aprobado': true,
					'TipoIngreso': "INGRESO",
					'Confirma': confirma
				}, configuracion);

				if (response.status === 200) {
					this.Mensaje = 'Factura ingresada correctamente';
				} else {
					throw new Error('Error al ingresar la factura');
				}
			}
			this.snackbar = true;
			this.initialize();
			this.limpiar();
		} catch (error) {
			this.Mensaje = 'El No de factura ya se encuentra registrado o hubo un error '+error;
			this.snackbar = true;
		} finally {
			this.loading = false;
			this.modelCarga = false;
			this.modelinventario = false;
		}
	},
	async ActualizarDatos() {
		this.modelCarga = true;
		await this.enviarDatosEnLotes(this.detalles);
		this.modelCarga = false;
		this.modelinventario = false;
		this.limpiar();
		this.initialize();
		this.snackbar = true;
	},

	async enviarDatosEnLotes(detalles, batchSize = 100) {
      this.cargando = true;
      this.progreso = 0;
      const header = { "Authorization": "Bearer " + this.$store.state.token };
      const configuracion = { headers: header };
      const totalBatches = Math.ceil(detalles.length / batchSize);
      const errores = [];
      let totalEnviados = 0;

      for (let i = 0; i < totalBatches; i++) {
        const batch = detalles.slice(i * batchSize, (i + 1) * batchSize);
        try {
          await axios.post('api/ProductosWebs/ActualizarDatos', {
            'Usuario': this.$store.state.usuario.nombre,
            'localidad': this.localidad,
            'detalles': batch,
            'detalle': this.Detalle,
            'Aprobado': true,
            'TipoIngreso': "INGRESO",
            'Confirma': this.confirma,
          }, configuracion);

          totalEnviados += batch.length;

          // Actualizar el progreso
          this.progreso = Math.round((totalEnviados / detalles.length) * 100);
        } catch (error) {
          if (error.response && error.response.data) {
            errores.push(error.response.data.message);
          } else {
            errores.push('Error de comunicación con el servidor');
          }
        }
      }

      this.cargando = false; // Ocultar el loader al final del proceso

      if (errores.length > 0) {
        this.Mensaje = "Algunos lotes no se enviaron correctamente: " + errores.join(", ");
      } else {
        this.Mensaje = "Todos los datos se enviaron correctamente.";
      }
    },

    IngresarProveedor(){
          if (this.nombreproveedor === ''){
              this.Mensaje = 'Seleccione el nombre del proveedor';
              this.snackbar = true;
              
                }  {
          this.loading = true;
          const me = this;
          const header={"Authorization" : "Bearer " + this.$store.state.token};
          const configuracion= {headers : header};
            axios.post('api/proveedores/Crear',{
                      'Usuario': this.$store.state.usuario.nombre,
                      'Localidad': this.$store.state.usuario.localidad,
                      'Proveedor': me.nombreproveedor,
                      'Cedula': me.cedulaproveedor,
                      'Correo': me.correoproveedor,
                      'Telefono': me.telefonoproveedor,
                      'TipoCedula': me.tpcedulaproveedor,
                      'Dirreccion': me.direccionproveedor
                      
                },configuracion).then(function (response) {
                     alert(`Proveedor agregado con éxito`);  
                    me.loading = false;
                    me.modelproveedor = false;
                    me.selectProveedores();
                    me.nombreproveedor = '';
                    me.cedulaproveedor = '';
                    me.telefonoproveedor = '';
                    me.direccionproveedor = '';
                    me.correoproveedor = '';
                  })
                  .catch(function (error) {
                      alert(`Lo sentimos ha ocurrido un error, notifiuelo al 4070-1889 `+error);  
                })
                } 
    },
    AbrirCopmraSimple(){
      this.modelcompra = true;
      const barcodepreview = Date.now();
      const idsa = barcodepreview.toString();
      this.NoFactura = idsa.substring(5, 12);
    },

    Aprobaciones(){
        this.modelAprobaciones = true;
        const me=this;
        const header={"Authorization" : "Bearer " };
        const configuracion= {headers : header};
        let familiasArray=[];
        axios.get('api/SalidaProductos/Listar/'+me.$store.state.usuario.localidad+'/1',configuracion).then(function(response){
            familiasArray=response.data;
            me.listaAprobaciones = familiasArray;
        }).catch(function(error){
			alert(error);
        });
    },
    AgregarProducto(item){
      this.descripcion = item.descripcion;
      this.precioVenta = item.precio;
      this.precioMayor = item.precio;
      this.barcode = item.codigoBarras;
      this.dialog = true;
      this.precioDetalle = item.precio;
      this.precioCentral = item.precio;
      this.precioMall = 0;
      this.precioCompra = item.precioCompra;
      this.familia = item.familia;
      this.minimo= 1;
      this.maximo= 5;
      this.maxDescuento = 20;
      this.comision = 10;
      this.codigoProducto = item.idarticulo;
      
    },
    IngresarFamilia(){
		if (this.familia === ''){
			this.Mensaje = 'Digite el nombre de la Familia';
			this.snackbar = true;
		}  
		{
		this.loading = true;
		const me = this;
		const header={"Authorization" : "Bearer " + this.$store.state.token};
		const configuracion= {headers : header};
		axios.post('api/Familias/Crear',{
				'Nombre': this.familia,
				'Localidad': this.$store.state.usuario.localidad,
				
		},configuracion).then(function (response) {
			me.Mensaje = 'Familia Ingresada con èxito';
			me.snackbar = true;
			me.loading = false;
			me.select();
			})
			.catch(function (error) {
				alert(`La familia ya existe, no es necesario agregarla `+error);  
		})
		} 
    },
    ObtenerDia(){
		const hoy = new Date();
        const anio = hoy.getFullYear();
        const mes = ('0' + (hoy.getMonth() + 1)).slice(-2); // Los meses comienzan en 0
        const dia = ('0' + hoy.getDate()).slice(-2);
        this.FechaCompra = `${anio}-${mes}-${dia}`;
		this.FechaVencimiento = `${anio}-${mes}-${dia}`;
    },
	CalcularDias() {
		const date1 = new Date(this.FechaCompra);
		const date2 = new Date(this.FechaVencimiento);
		const differenceInTime = date2.getTime() - date1.getTime();
		const differenceInDays = differenceInTime / (1000 * 3600 * 24);
		this.dias = differenceInDays;
	},
	select(){
		const me =this;
		const header={"Authorization" : "Bearer " };
		const configuracion= {headers : header};
		let familiasArray=[];
		axios.get('api/Familias/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
			familiasArray=response.data;
			familiasArray.forEach(function(x){
				me.familias.push(x.nombre);
			});
		}).catch(function(error){
			alert(error);
		});
	},
      GestionProveedores(){
                this.modelproveedor = true;
                const me=this;
                const header={"Authorization" : "Bearer " };
                const configuracion= {headers : header};
                axios.get('api/Proveedores/Listar/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
                    me.gestionProveedor=response.data;
                    me.selectProveedores();
                    
                }).catch(function(error){
					alert(error);
                });
            },
      
      selectProveedores(){
                const header={"Authorization" : "Bearer " };
                const configuracion= {headers : header};
                axios.get('api/proveedores/Select/' + this.$store.state.usuario.localidad, configuracion)
				.then((response) => {
					response.data.forEach(x => {
						this.Proveedores.push(x.proveedor);
					});
				})
				.catch((error) => {
					alert(error);
				});

            },
            selectProductos(localidad){
                const me =this;
                const header={"Authorization" : "Bearer " };
                const configuracion= {headers : header};
                let productosArray=[];
                axios.get('api/Productos/SelectProductos2/'+localidad,configuracion).then(function(response){
                    productosArray=response.data;
                    productosArray.forEach(function(x){
                        me.productos.push({text:x.descripcion, value:x.codigoProducto});
                    });
                }).catch(function(error){
					alert(error);
                });
            },
      guardar () {
        this.modelCarga = true;
            if(this.Categoria === 'PRODUCTO'){ 

            
             if (this.validar()){
                    return;
                }
            } else  
              if(this.Categoria === 'SERVICIO' )
                if (this.validarServicio()){
                      return;
                } 
            if(this.categoria === 0){
              this.SoN = 'N';
            }
            const header={"Authorization" : "Bearer "};
                const configuracion= {headers : header};
                if (this.editedIndex > -1) {
                    // Código para editar
                    const me=this;
                    axios.put('api/Productos/Actualizar',{
                        'codigoProducto':me.codigoProducto,
                        'descripcion':me.descripcion,
                        'barcode':me.barcode,
                        'IdProductKey': me.idProductKey,
                        'precioVenta':me.precioVenta,
                        'precioDetalle':me.precioDetalle,
                        'precioMall':me.PrecioMall,
                        'precioMayor':me.precioMayor,
                        'precioCentral':me.precioCentral,
                        'precioCompra': me.precioCompra,
                        'compuesto': me.compuesto,
                        'Categoria':me.Categoria, // Define si es un servicio o un Producto
                        'Origen':me.hora, // Este campo es la hora inicial del servicio
                        'InnerPack': me.hora2, // Este campo es la Hora final del servicio
                        'CantHoras': me.hora4, // Este campo define la cantidad de horas disponibles del servicio
                        'comision': me.comision,
                        'maxDescuento': me.maxDescuento,
                        'proveedor': me.proveedor,
                        'localizacion': me.localizacion,
                        'minimo':me.minimo,
                        'maximo':me.maximo,
                        'EstadoInventario': me.Ivi,
                        'valorImpuesto':me.categoria,
                        'tipoProducto': me.familia,
                        'SubFamilia': me.SubFamilia,
                        'Localidad' : me.$store.state.usuario.localidad,
                        'CantPersonas': me.CantPersonas
                    },configuracion).then(function(response){
                        me.close();
                        me.initialize();
                        me.limpiar();     
                        me.modelCarga = false;                   
                    }).catch(function(error){
						alert(error);
                    });
                } else {

            const header={"Authorization" : "Bearer "};
            const configuracion= {headers : header};
                this.loading  = true;
                const me=this;
                axios.post('api/Productos/Crear',{
                    'codigoProducto':me.codigoProducto,
                    'descripcion':me.descripcion,
                    'barcode':me.barcode,
                    'precioVenta':me.precioVenta,
                    'precioDetalle':me.precioDetalle,
                    'precioMall':me.PrecioMall,
                    'precioMayor':me.precioMayor,
                    'precioCentral':me.precioCentral,
                    'precioCompra': me.precioCompra,
                    'compuesto': me.compuesto,
                    'comision': me.comision,
                    'maxDescuento': me.maxDescuento,
                    'proveedor': me.proveedor,
                    'localizacion': me.localizacion,
                    'minimo':me.minimo,
                    'maximo':me.maximo,
                    'EstadoInventario': me.Ivi,
                    'Categoria':me.Categoria, // Define si es un servicio o un Producto
                    'Origen':me.hora, // Este campo es la hora inicial del servicio
                    'InnerPack': me.hora2, // Este campo es la Hora final del servicio
                    'CantHoras': me.hora4, // Este campo define la cantidad de horas disponibles del servicio
                    'valorImpuesto':me.categoria,
                    'tipoProducto': me.familia.value,
                    'SubFamilia' :me.SubFamilia,
                    'Exe_Grad':me.SoN,
                    'Localidad' : me.$store.state.usuario.localidad,
                    'CantPersonas': me.CantPersonas // Este campo guarda la cantidad de personas soportadas por un servicio
                },configuracion).then(function(response){
                    me.close();
                    me.initialize();
                    me.limpiar();
                    me.loading = false; 
                    me.Mensaje = "Producto Agregado";
                    me.snackbar = true;
                    me.modelCarga= false;                      
                }).catch(err => {
                    this.loading =false;
                    if (err.response.status===400){
                        alert("Código ocupado por otro producto");
                        this.validaMensaje.push("Código ocupado por otro producto.");
                    } else if (err.response.status===404){
                        alert("Código ocupado por otro producto")
                    }else{
                        alert("Validar Campos Vacios")
                    }
                });
              }
            },
      AñadirCodigo(item){
        this.modelCarga = true;
        this.modelSeriales = true;
        this.codigoProducto = item.idarticulo;
        this.nombre = item.descripcion;
        const me=this;
                const header={"Authorization" : "Bearer " };
                const configuracion= {headers : header};
                axios.get('api/CodigosSeriales/ObtenerSerial/'+me.$store.state.usuario.localidad+'/'+me.codigoProducto,configuracion).then(function(response){
                    me.seriales=response.data;
                    me.modelCarga = false;
                }).catch(function(error){
                    me.modelCarga = false;
                    me.Mensaje = error;
                    me.snackbar = true;
					alert(error);
                });

      },

      editItem (item) {
        this.codigoProducto = item.codigoProducto
        this.descripcion = item.descripcion
        this.idProductKey = item.idProductKey
        this.SubFamilia = item.subFamilia
        this.barcode = item.barcode
        this.precioCompra = item.precioCompra
        this.comision = item.comision
        this.precioVenta = item.precioVenta
        this.hora = item.origen 
        this.hora2 = item.innerPack
        this.hora4 = item.cantHoras
        this.familia = item.tipoProducto
        this.proveedor = item.proveedor
        this.maximo = item.maximo
        this.Ivi = item.estadoInventario
        this.minimo = item.minimo
        this.maxDescuento = item.maxDescuento
        this.localizacion = item.localizacion
        this.precioMall = item.precioMall
        this.precioCentral = item.precioCentral
        this.precioDetalle = item.precioDetalle
        this.precioMayor = item.precioMayor
        this.editedIndex=1;
        this.CantPersonas = item.cantPersonas
        this.dialog = true
      },
      validar(){
          this.valida=0;
          this.validaMensaje=[];

          if (this.descripcion.length<3 || this.descripcion.length>50){
              this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 50 caracteres.");
          }
          if (!this.familia){
              this.validaMensaje.push("Seleccione una familia.");
          }
          
          if (!this.proveedor){
              this.validaMensaje.push("Seleccione un proveedor");
          }
          if (!this.maximo){
              this.validaMensaje.push("Seleccione un Máximo");
          }
          if (!this.minimo){
              this.validaMensaje.push("Seleccione un Mínimo");
          }
          if (!this.precioCompra){
              this.validaMensaje.push("Seleccione un precio de Compra");
          }
          if (!this.proveedor){
              this.validaMensaje.push("Seleccione un proveedor");
          }
          if (!this.comision){
              this.validaMensaje.push("Seleccione una comision");
          }
          if (!this.codigoProducto){
              this.validaMensaje.push("Seleccione un código de Producto");
          }
          if (!this.barcode){
              this.validaMensaje.push("Seleccione un código de barras");
          }
          if (this.validaMensaje.length){
              this.valida=1;
          }
          return this.valida;
      },
      validarServicio(){
          this.valida=0;
          this.validaMensaje=[];

          if (this.descripcion.length<3 || this.descripcion.length>50){
              this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 50 caracteres.");
          }
          if (!this.hora){
              this.validaMensaje.push("Seleccione una hora de inicio");
          }
          if (!this.hora2){
              this.validaMensaje.push("Seleccione una Hora Final");
          }
          if (!this.hora4){
              this.validaMensaje.push("Seleccione la cantidad de Horas");
          }
          if (this.validaMensaje.length){
              this.valida=1;
          }
          return this.valida;
      },
      EsProducto(){
        this.esProducto = true;
        this.Categoria = "PRODUCTO";
        const barcodepreview = Date.now();
        const idsa = barcodepreview.toString();
        this.codigoProducto = idsa.substring(5, 12);
        this.barcode = idsa.substring(5, 12);
      },
      guardarSeriales(){
        this.Mensaje = "Seriales Agregados con éxito";
        this.snackbar = true;
        this.modelSeriales = false;
      },
      EsServicio(){
        this.esProducto = false;
        this.Categoria = "SERVICIO";
        const barcodepreview = Date.now();
        const idsa = barcodepreview.toString();
        this.codigoProducto = idsa.substring(0, 8);
        this.barcode = idsa.substring(0, 8);
      },

      deleteProveedor (item) {
          this.loading = true;
          const me = this;
          if(confirm("Seguro que quiere eliminar el proveedor "+item.proveedor+"?")){
              const header={"Authorization" : "Bearer " + this.$store.state.token};
              const configuracion= {headers : header};
               axios.post('api/Proveedores/DeleteProveedores',{
                  'IdProveedorKey': item.idProveedorKey,
                      
                },configuracion).then(function (response) {
                    me.snackbar = true;
                    me.Mensaje = 'Proveedor Eliminado Con éxito.'+response;  
                    me.GestionProveedores();
                    me.selectProveedores();
                    
                  })
                  .catch(function (error) {
                      me.snackbar = true;
                      me.Mensaje = 'Error eliminando el proveedor.'+error;    
                    })
          }
      },
    },
  }
</script>


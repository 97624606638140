<template>

  <v-data-table
    :headers="headers"
    :items="articulos"
    sort-by="nombre"
	:search="search"
    class="elevation-1"
    >
    <template v-slot:item.categoria="props">
		<v-edit-dialog
		:return-value.sync="props.item.categoria"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.categoria }} <br>
		<template v-slot:input>
			<v-select v-model="props.item.categoria" :items="Categorias" label="Categoría"/>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.proveedor="props">
		<v-edit-dialog
		:return-value.sync="props.item.proveedor"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.proveedor }} <br>
		<template v-slot:input>
			<v-select :disabled="esVendedor" v-model="props.item.proveedor" :items="Proveedores" label="Proveedor"/>
			<v-btn color="primary" icon="" @click="GestionProveedores()"> <v-icon> mdi-account-multiple-plus </v-icon>  </v-btn>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.pkg="props">
		<v-edit-dialog
		:return-value.sync="props.item.pkg"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.pkg }} <br>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.pkg"
			type="number"
			:rules="[max25chars]"
			:label="'Nivel de nicotina '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.innerPack="props">
		<v-edit-dialog
		:return-value.sync="props.item.innerPack"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.innerPack }} <br>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.innerPack"
			
			:rules="[max25chars]"
			:label="'Ubicación del item: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.codigoRaiz="props">
		<v-edit-dialog
		:return-value.sync="props.item.codigoRaiz"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.codigoRaiz }} <br>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.codigoRaiz"
			
			:rules="[max25chars]"
			:label="'Cabys: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.precioCompra="props">
		<v-edit-dialog
		:return-value.sync="props.item.precioCompra"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		<div v-if="!esMarketing"> {{ props.item.precioCompra }} <br> </div>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.precioCompra"
			:disabled="esVendedor"
			:rules="[max25chars]"
			:label="'Precio Compra: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.precioVenta="props">
		<v-edit-dialog
		:return-value.sync="props.item.precioVenta"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.precioVenta }} <br>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.precioVenta"
			:disabled="esVendedor"
			:rules="[max25chars]"
			:label="'Precio Venta: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.barcode="props">
		<v-edit-dialog
		:return-value.sync="props.item.barcode"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.barcode }} <br>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.barcode"
			:disabled="esVendedor"
			:rules="[max25chars]"
			:label="'Código Barras: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.subFamilia="{ item }">
		<div @click="toggleRow(item)">
			<!-- Muestra el texto truncado o completo basado en el estado de 'expandedRows' -->
			{{ expandedRows.includes(item.subFamilia) ? item.subFamilia : truncate(item.subFamilia, 100) }}
		</div>
	</template>
	<template v-slot:item.descripcion="props">
		<v-edit-dialog
		:return-value.sync="props.item.descripcion"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.descripcion }} <br>
		<p style="white-space: pre-line; font-size: 12px;">{{props.item.detalle}} </p>
		<template v-slot:input>
			<v-text-field
			onfocus="this.select();"
			v-model="props.item.descripcion"
			
			:rules="[max25chars]"
			:label="'Código: '"
			></v-text-field>
		</template>
		</v-edit-dialog>
	</template>
	<template v-slot:item.tipoProducto="props">
		<v-edit-dialog
		:return-value.sync="props.item.tipoProducto"
		@save="Actualizar(props.item)"
		@cancel="cancel"
		@close="close"
		large
		>
		{{ props.item.tipoProducto }} <br>
		<p style="white-space: pre-line; font-size: 12px;">{{props.item.detalle}} </p>
		<template v-slot:input>
			<v-row>
				<v-select class="mx-3" dense v-model="props.item.tipoProducto"
				:items="familias" label="Familia"/>
				<v-icon @click="EliminarFamilia()" v-if="props.item.tipoProducto != ''" color="red">mdi-minus  </v-icon>
				<v-edit-dialog
					:return-value.sync="props.item.tipoProducto"
					@save="IngresarFamilia()"
					>
					<v-icon color="green">mdi-plus</v-icon> 
					<template v-slot:input>
						<v-text-field
						onfocus="this.select();"
						v-model="familia"
						label="Digite el nombre de la familia"
						counter
						></v-text-field>
					</template>
				</v-edit-dialog> 
			</v-row>
		</template>
		</v-edit-dialog>
	</template>
    <template v-slot:top>
		<!-- FACTURA -->
      <div id="bill" style="display: none;">
        
        <div style="margin: 0 auto; text-align: center;">
          <br>
          <label style="font-size: medium; font-weight: bold;">Movimiento Inventario</label>
          <br>
          <label style="font-size: medium;">{{localidad}}</label>
          <br>
          <label style="font-size: medium;">Tipo Movimiento {{DatoMovimiento}}</label>
          <br>
          <label style="font-size: medium;">Usuario: {{$store.state.usuario.nombre}}</label>
          <br><br>
          <v-divider color="black"></v-divider>
          <br>
        </div>
        <div style="margin: 0 auto;">
          <label style="font-size: medium;">Fecha: {{Fecha}}</label> <br>
		  <label style="font-size: medium;">Id Movimiento: {{idMovimiento}}</label>
          <br><br>
          <v-divider color="black"></v-divider>
          <br>
          <div style="display: grid; grid-template-columns: auto auto auto;">
            <table>
				<tr>
					<th>Descripcion</th>
					<th>Precio</th>
					<th>Cant</th>
				</tr>
				<tr v-for="item in detalles"
					:key="item">
					<td>{{item.descripcion}}</td>
					<td>{{item.precio}}</td>
					<td>{{item.cantidadIngreso}}</td>
				</tr>
				</table>
          </div>
          <br><br>
          <v-divider color="black"></v-divider>
          <br>
        </div>
      </div>
      <!-- FACTURA -->
	  <div class="text-center">
			<v-overlay :value="modelCarga">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
			</v-overlay>
		</div>
      <v-dialog v-model="modelinventario" persistent max-width="1000" class="primary">
        <v-toolbar  dark color="primary" class="font-weight-light body-1">
              <h4>Movimientos de Inventario</h4>
              
        </v-toolbar>
        <v-card>
          <v-container fluid>
            <v-col cols="12" sm="12">
            <v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
            <v-row>
				
				<v-col cols="12" sm="4">
					<v-select filled v-model="localidad" label="Localidad" :items="Localidades"> </v-select>
              	</v-col>
				<v-col cols="12" sm="4">
					<v-text-field filled v-model="codigo" @keyup.enter="buscarCodigoBarras()"  label="Escanear Código"></v-text-field>
				</v-col>
              	<v-col cols="12" sm="4">
                 <v-select filled v-model="DatoMovimiento" :rules="[v => !!v || 'El tipo de movimiento es requerido']" :items="TipoMovimiento" label="Tipo de Movimiento"></v-select>
				</v-col>
                <v-col cols="12" sm="12">
                  	<v-combobox filled clearable @change="buscarProducto()" @keyup.enter="buscarProducto()" :items="productos" v-model="codigo" label="Busqueda de producto por nombre"></v-combobox>
                </v-col>
                <v-col cols="12" sm="12">
                      <v-flex xs12 sm12 md12 lg12 xl12>
                        
                          <v-data-table
                              :headers="cabeceraDetalles"
                              single-select
                              :items="detalles"
                              class="elevation-1"
							  sort-desc=""
							  dense
							  sort-by="id"
                              v-model="selected"
                              @click:row="rowClick">
                              <template slot="no-data">
                                  <h3>No hay artículos agregados al detalle.</h3>
                              </template> 
                              <template v-slot:item.actionmovimiento="{ item }">
                                <v-icon
                                  large
                                  color="error"
                                  @click="eliminarProducto(item.id)"
                                >
                                  mdi-delete
                                </v-icon>
								</template>
                              	<template v-slot:item.actioncantidad="{ item }">
									<v-text-field
										onfocus="this.select();"
										ref="inputCantidadIngreso"
										v-model="item.cantidadIngreso"
										@focus="handleFocus($event, item.id)"
										@keydown="preventNonNumericInput"
									></v-text-field>
								</template>

                          </v-data-table>
                      </v-flex>
				</v-col>
				<v-col class="mt-2" cols="12" sm="12">
                 <v-text-field v-model="detalle"  label="Detalle, comentario o justificación"></v-text-field>
              	</v-col>
                <v-btn :loading="loading" color="primary" @click="generarInventario()">
                Generar Proceso
                </v-btn>
                <v-btn outlined color="error" class="mx-2" @click="modelinventario = false">
                Cancelar
                </v-btn> 
              </v-row>
              </v-form>
              </v-col>
          </v-container>
		</v-card>
      	</v-dialog>
		<v-dialog fullscreen v-model="modeltrazlado" persistent max-width="900" class="primary">
			<v-toolbar  dark color="primary" class="font-weight-light body-1">
				<h4>Traslado de mercadería</h4>
				
			</v-toolbar>
			<v-card>
			<v-container fluid>
				<v-col cols="12" sm="12">
				<v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
				<v-row>
				<v-col class="mt-2" cols="12" sm="4">
					<v-select filled v-model="localidadorigen" :rules="[v => !!v || 'El origen es requerido']" :items="Localidades" label="Localidad Origen"></v-select>
				</v-col>
				<v-col class="mt-2" cols="12" sm="4">
					<v-select filled v-model="localidaddestino" :rules="[v => !!v || 'El destino es requerido']" :items="Localidades" label="Localidad Destino"></v-select>
				</v-col>
				<v-col class="mt-2" cols="12" sm="4">
					<v-text-field filled v-model="codigo"  @keyup.enter="buscarCodigoBarras()"  label="Escanear Código"></v-text-field>
				</v-col>
				<v-col cols="12" sm="12">
					<v-combobox :disabled="!localidaddestino" filled clearable @change="buscarProducto()" :items="productos" v-model="codigo" label="Busqueda de producto por nombre"></v-combobox>
				</v-col>
					<v-col cols="12" sm="12">
						<v-flex xs12 sm12 md12 lg12 xl12>
							<v-data-table
									:headers="cabeceraDetalles"
									single-select
									:items="detalles"
									class="elevation-1"
									sort-by="id"
									sort-desc=""
									v-model="selected">
								<template slot="no-data">
									<h3>No hay artículos agregados al detalle.</h3>
								</template> 
								<template v-slot:item.actionmovimiento="{ item }">
									<v-icon
									large
									color="error"
									@click="eliminarProducto(item.id)"
									>
									mdi-delete
									</v-icon>
								</template>
								<template v-slot:item.actioncantidad="{ item }">
									<v-text-field
										ref="inputCantidadIngreso"
										@change="validarCantidad(item)"
										v-model="item.cantidadIngreso"
										onfocus="this.select();"
										@focus="handleFocus($event, item.id)"
										@keypress="preventNonNumericInput"
									></v-text-field>
								</template>
							</v-data-table>
						</v-flex>
					<v-col class="mt-2" cols="12" sm="12">
					<v-text-field v-model="detalle"  label="Detalle, comentario o justificación"></v-text-field>
					</v-col>
					<v-btn  color="primary" :loading="modelCarga" class="mr-4" style="top: 10px;" @click="generarTrazlado()">
					Generar Proceso
					</v-btn>
					<v-btn color="error" id="espacioBoton" @click="modeltrazlado = false">
					Cancelar
					</v-btn> 
					<v-spacer/>
					<v-btn  color="primary" :loading="modelCarga" class="mt-4" @click="PrintDetails()">
					<v-icon>mdi-printer </v-icon>
					</v-btn>
					</v-col>
				</v-row>
				</v-form>
				</v-col>
			</v-container>
			</v-card>
		</v-dialog>
	
        <v-dialog v-model="modelSeriales" persistent max-width="800" class="primary">
        <v-toolbar  dark color="primary" class="font-weight-light body-1">
              <h4>Agregar códigos Seriales</h4>
              <v-card-subtitle>Producto: {{nombre}} </v-card-subtitle>
        </v-toolbar>
        <v-card>
          <v-container fluid>
            <v-col cols="12" sm="12">
            <v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
            <v-row>
			  <v-col class="mt-2" cols="12" sm="10">
                 <v-text-field clearable v-model="serial" @keyup.enter="agregarSerial()" label="Escanear o digitar Código"></v-text-field>
				 <v-menu
						v-model="menu21"
						:close-on-content-click="false"
						:nudge-right="40" 
						transition="scale-transition"
						offset-y
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="start"
							label="Fecha Vencimiento"
							prepend-icon="mdi-calendar"
							readonly
							persistent-hint
							type="date"
							hint="DD/MM/YYYY formato"
							v-bind="attrs"
							:rules="fechaInicialR"
							v-on="on"
						></v-text-field>
						</template>
						<v-date-picker v-model="start" @input="menu21 = false"></v-date-picker>
					</v-menu>
					<v-edit-dialog
							:return-value.sync="nombreproveedor"
							large
 							persistent
							@save="updateCliente()"
							@cancel="cancel"
							@open="open"
							@close="updateCliente()"
							>
							Proveedor: {{nombreproveedor }}
							<template v-slot:input>
								<v-select
								v-model="nombreproveedor"
								:items="Proveedores"
								label="Nombre del proveedor"
								:disabled="esVendedor"
								counter
								></v-select>
								<v-btn color="primary" icon="" @click="GestionProveedores()"> <v-icon> mdi-account-multiple-plus </v-icon>  </v-btn>
							</template>
							</v-edit-dialog> 
              </v-col>
              <v-col class="mt-2" cols="12" sm="2">
                 <v-btn  @click="agregarSerial()"> Agregar</v-btn>
              </v-col>
                <v-col cols="12" sm="12">
                      <v-flex xs12 sm12 md12 lg12 xl12>
                        
                          <v-data-table
                              :headers="cabeceraSeriales"
                              item-key="name" 
                              single-select
                              :items="seriales"
                              class="elevation-1"
                              hide-default-footer=""
                              v-model="selected">
                              <template slot="no-data">
                                  <h3>No hay artículos agregados al detalle.</h3>
                              </template> 
                              <template v-slot:item.actionmovimiento="{ item }">
                                <v-icon
                                  large
                                  color="error"
                                  @click="eliminarProducto(item.id)"
                                >
                                  mdi-delete
                                </v-icon>
                              </template>
                              <template v-slot:item.actioncantidad="{ item }"> 
                                <v-text-field v-model="item.cantidadIngreso">  </v-text-field>
                              </template> 

                          </v-data-table>
                      </v-flex>
                <v-btn  color="primary"  class="mr-4" style="top: 10px;" @click="guardarSeriales()">
                Continuar Cargando
                </v-btn>
                <v-btn color="error" id="espacioBoton" @click="modelSeriales = false">
                Salir
                </v-btn> 
                </v-col>
              </v-row>
              </v-form>
              </v-col>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modelAprobaciones" persistent max-width="1000" class="primary">
        <v-toolbar  dark color="primary" class="font-weight-light body-1">
              <h4>Aprobaciones de Inventario</h4>
              
        </v-toolbar>
        <v-card>
          <v-container fluid>
            <v-col cols="12" sm="12">
            <v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
            <v-row>
                <v-col cols="12" sm="12">
                      <v-flex xs12 sm12 md12 lg12 xl12>
                        
                          <v-data-table
                              :headers="cabeceraAprobaciones"
                              item-key="name" 
                              single-select
                              :items="listaAprobaciones"
                              class="elevation-1"
                              v-model="selected"
                              @click:row="rowClick">
                              <template slot="items" slot-scope="props">
                                      <v-autocomplete
                                        :items="saleables"
                                        :item-text="i => i.name"
                                        :item-value="i => i.id"
                                        hide-details
                                      ></v-autocomplete>
                                <tr>
                                  <td>{{props.item.idarticulo}}</td>
                                  <td>{{props.item.descripcion}}</td>
                                  <v-text-field type="number" v-model="props.item.cantidad"></v-text-field>
                                  <v-text-field type="number" v-model="props.item.precio"></v-text-field>
                                  <td> Eliminar </td>
                                </tr>
                              </template>
                              <template slot="no-data">
                                  <h3>No hay artículos agregados al detalle.</h3>
                              </template> 
                              <template v-slot:item.actionmovimiento="{ item }">
                                <v-icon
                                  large
                                  color="error"
                                  @click="DenegarProducto(item)"
                                >
                                  mdi-delete-circle
                                </v-icon>
                              </template>
                              <template v-slot:item.actioncantidad="{ item }"> 
                                 <v-icon large @click="AprobarProducto(item)" color="success">mdi-check-circle</v-icon>  
                              </template> 

                          </v-data-table>
                      </v-flex>
                <v-btn  color="info"  class="mr-4" style="top: 10px;" @click="generarInventario()">
                Aprobar Todo
                </v-btn>
                <v-btn color="error" id="espacioBoton" @click="modelAprobaciones = false">
                Salir
                </v-btn> 
                </v-col>
              </v-row>
              </v-form>
              </v-col>
          </v-container>
        </v-card>
      </v-dialog>
	  <v-dialog v-model="modelAcabados" persistent max-width="1000" class="primary">
        <v-toolbar  dark color="primary" class="font-weight-light body-1">
              <v-list-item-title style="font-weight:bold;">Acabados para {{descripcion}}</v-list-item-title>
       		 <v-list-item-subtitle>Agrega las opciones posibles de 1 acabado para 1 producto</v-list-item-subtitle>
        </v-toolbar>
        <v-card>
          <v-container fluid>
            <v-col cols="12" sm="12">
				<v-row>
					<v-col cols="12" sm="6">
						<h2> Acabados actuales </h2>		
							<v-simple-table>
								<template v-slot:default>
								<thead>
									<tr>
									<th class="text-left">
										Nombre Acabado
									</th>
									<th class="text-left">
										Costo m2
									</th>
									<th class="text-left">
										Opciones
									</th>
									</tr>
								</thead>
								<tbody>
									<tr
									v-for="item in acabados"
									:key="item.id"
									>
									<td>{{ item.producto }}</td>
									<td>
										<v-edit-dialog
										:return-value.sync="item.costo"
										@save="actualizarAcabado(item)"
										@cancel="cancel"
										@open="open"
										@close="close"
										>
										{{ item.costo }}
											<template v-slot:input>
												<v-text-field
												onfocus="this.select();"
												v-model="item.costo"
												
												:rules="[max25chars]"
												:label="'Código: '"
												></v-text-field>
											</template>
										</v-edit-dialog>
									</td>
									<td><v-icon @click="EliminarAcabado(item.id)" color="red"> mdi-delete </v-icon> </td>
									</tr>
								</tbody>
								</template>
							</v-simple-table>
					</v-col>
					<v-col cols="12" sm="6">
						<h2> Agregar Acabado </h2>		
						<v-text-field v-model="codigoProducto" label="Código del acabado" />
						<v-text-field v-model="nombreAcabado" label="Nombre del acabado" />
						<v-text-field v-model="costoacabado" type="number" label="Costo del m2 del acabado" />
						<v-btn :loading="loading" color="success" @click="añadirAcabado()">
							Agregar
						</v-btn>
					</v-col>
					
				</v-row>
				
				<v-btn color="error" id="espacioBoton" @click="modelAcabados = false">
					Salir
				</v-btn> 
			</v-col>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modelproveedor" persistent max-width="1400" class="primary">
        <v-toolbar  dark color="primary" class="font-weight-light body-1">
              <h4>Gestion de Proveedores</h4>
              
        </v-toolbar>
        <v-card>
          <v-container fluid>
            <v-row>
            <v-col cols="12" sm="5">
              <v-col cols="12" sm="12">
              <v-form ref="form" v-model="validFactura"  @submit.prevent="facturar">
              <v-row>
                <v-col class="mt-2" cols="12" sm="6">
                  <v-select outlined="" dense="" v-model="tpcedulaproveedor" :rules="[v => !!v || 'El tipo de cédula es requerido']" :items="tipoDocumentos" label="Tipo Cédula"></v-select>
                </v-col>
                <v-col class="mt-2" cols="12" sm="6">
                  <v-text-field outlined="" dense="" v-model="nombreproveedor" label="Nombre"></v-text-field>
                </v-col>
                <v-col class="mt-2" cols="12" sm="6">
                  <v-text-field outlined="" dense="" v-model="cedulaproveedor" label="Cedula"></v-text-field>
                </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field outlined="" dense="" clearable v-model="telefonoproveedor" label="Teléfono"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field outlined="" dense="" clearable v-model="correoproveedor" label="Correo"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-text-field outlined="" dense="" clearable v-model="direccionproveedor" label="Dirección"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                        
                  <v-btn  color="primary"  class="mr-4" style="top: 10px;" @click="IngresarProveedor()">
                    <v-icon> mdi-content-save </v-icon>  Guardar
                  </v-btn>
                  <v-btn color="error" id="espacioBoton" @click="modelproveedor = false">
                    <v-icon> mdi-cancel </v-icon> Cancelar
                  </v-btn> 
                  </v-col>
                </v-row>
                </v-form>
                </v-col>
            </v-col>
            <v-col cols="12" sm="7">
                <v-data-table
                    :headers="ProveedoresDetalles"
                    single-select
                    :items="gestionProveedor"
                    class="elevation-1"
                    hide-default-footer=""
                    v-model="selected"
                    @click:row="rowClick">
                    
                    <template slot="no-data">
                        <h3>No hay artículos agregados al detalle.</h3>
                    </template> 
                    <template v-slot:item.actionmovimiento="{ item }">
                      <v-icon
                        large
                        color="error"
                        @click="deleteProveedor(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:item.actioncantidad="{ item }"> 
                      <v-text-field v-model="item.cantidadIngreso">  </v-text-field>
                    </template> 

                </v-data-table>
            </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <v-snackbar
          v-model="snackbar" top vertical="" :timeout="4000"
          >
          {{ Mensaje }}
          <v-btn
          color="pink"
          text
          @click="snackbar = false"
          >
          Close
          </v-btn>
      </v-snackbar>
      <v-row flat class="mt-4 mx-2" color="white">
        <v-text-field style="width:20%" clearable prepend-inner-icon="mdi-filter" @change="SelectCat()" filled label="Filtrar por Categoría" v-model="cat1" /> 
		<v-select @change="initializeFinder()" style="width:20%" filled class="mx-2" v-model="localidad" label="Productos de" :items="Localidades"> </v-select>
		<v-select style="width:10%" clearable class="mx-2" prepend-inner-icon="mdi-filter" @change="initializeFinder()" filled label="Filtrar por Familia" :items="familias" v-model="familia" /> 
		<!-- <v-select style="width:10%" clearable class="mx-2" prepend-inner-icon="mdi-filter" @change="initializeFinder()" filled label="Filtrar por Proveedor" :items="Proveedores" v-model="proveedor" />  -->
		<v-btn @click="initialize()" icon> <v-icon> mdi-eraser </v-icon> </v-btn>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
         <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
			filled
			@keyup.enter="initializeFinder()"
            single-line
            hide-details
          ></v-text-field>
		
         
		<v-dialog v-model="dialog" persistent max-width="1800px">
			<template v-slot:activator="{ on }">
					<v-menu top :offset-y="offset">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							color="info"
							dark
							fab=""
							v-bind="attrs"
							:disabled="esVendedor"
							v-on="on"
						> <v-icon >mdi-dots-horizontal</v-icon>
						
						</v-btn>
					</template>

					<v-list>
						<v-list-item v-if="esBoss || esAdministrador || esSupervisor"  @click="ajustarInventarioALocalidadCero()">
							<v-list-item-title><v-icon >mdi-alert</v-icon> Fijar inventario en 0</v-list-item-title>
						</v-list-item>
						<v-list-item @click="modelAcabados = true">
							<v-list-item-title><v-icon >mdi-floor-plan</v-icon> Acabados</v-list-item-title>
						</v-list-item>
						
						<v-list-item v-if="!esBoss" @click="SyncProd()">
							<v-list-item-title><v-icon >mdi-beaker-check</v-icon> SyncProd</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="!esBoss" @click="Aprobaciones()">
							<v-list-item-title><v-icon >mdi-beaker-check</v-icon> Aprobaciones</v-list-item-title>
						</v-list-item>
						<v-list-item @click="AbrirTraslado()">
							<v-list-item-title > <v-icon >mdi-bank-transfer</v-icon> Traslado Mercadería</v-list-item-title>
						</v-list-item>
						<v-list-item @click="MoverInventario()">
							<v-list-item-title > <v-icon >mdi-excavator</v-icon> Ajuste Inventario</v-list-item-title>
						</v-list-item>
						<v-list-item to="/inventarioactual">
							<v-list-item-title > <v-icon >mdi-guitar-acoustic</v-icon> Inventario Actual </v-list-item-title>
						</v-list-item>
						<v-list-item to="/movimientoProductos">
							<v-list-item-title > <v-icon >mdi-rv-truck</v-icon> Movimiento Productos </v-list-item-title>
						</v-list-item>
						<v-list-item v-on="on" rounded="">
							<v-list-item-title  >
							<v-icon >mdi-timeline-plus</v-icon> Nuevo Producto</v-list-item-title>
						</v-list-item>
					</v-list>
                </v-menu>
          </template>
			<v-card>
				 <v-card-title style="font-size:23px;" class="boton white--text">
					Gestion de producto : {{descripcion}}
				</v-card-title>
					<v-row>
						<v-col cols="12" sm="3" >
							<div class="mx-2">
								<strong style="font-size:18px;">Aplicar gestion en </strong>
								<v-checkbox
									v-for="item in Localidades"
          							:key="item"
									style="border-bottom: 1px solid rgb(224, 224, 224);"
									v-model="LocalidadesSelectas"
									:label="item"
									:value="item"
								></v-checkbox>
								<v-divider/>
								<strong style="font-size:18px;">Configuraciones </strong>
								<v-checkbox
									style="border-bottom: 1px solid rgb(224, 224, 224);"
									label="Compuesto"
									v-model="compuesto"
								></v-checkbox>
								<v-select
									style="border-bottom: 1px solid rgb(224, 224, 224);"
									v-model="activo"
									:items="activos"
									label="Mostrar Item en la web"
								></v-select>
								<v-checkbox
									style="border-bottom: 1px solid rgb(224, 224, 224);"
									v-model="Ivi"
									label="Precio Venta con I.V.A.I"
									:value="Ivi"
								></v-checkbox>
								<v-divider/>
								<template>
									<div>
										<strong style="font-size:18px;">Cargar Imagen </strong>
										<input type="file" @change="onChangeFileUpload" accept="image/*" >
									</div>
									<div v-if="uploadValue != 0 || uploadValue != 100">
										<p>Progreso: {{uploadValue.toFixed()+"%"}}
									<progress id="progress" :value="uploadValue" max="100" ></progress>  </p>
									
									</div>
									<div v-if="uploadValue != 0">
										<img width="170" height="170" class="preview" :src="localizacion">
									</div>
								</template>
							</div>
						</v-col>
						<v-col style="border-left: 1px solid rgb(224, 224, 224);" cols="12" sm="9">
							<v-row class="mx-1">
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="8" md="8" sm="6" xs="6">
									<v-text-field filled hide-details="" v-model="descripcion" label="Nombre"></v-text-field>
									<v-text-field filled hide-details=""  v-model="cat1" label="Categoria 1">
									</v-text-field>
									<v-row>
										<v-select class="mx-3" filled hide-details="" v-model="familia"
										:items="familias" label="Familia"/>
										<v-icon @click="EliminarFamilia()" v-if="familia != ''" color="red">mdi-minus  </v-icon>
										<v-edit-dialog
											:return-value.sync="TourOperador"
											@save="IngresarFamilia()"
											>
											<v-icon color="green">mdi-plus</v-icon> 
											<template v-slot:input>
												<v-text-field
												onfocus="this.select();"
												v-model="familia"
												filled hide-details=""
												label="Digite el nombre de la familia"
												counter
												></v-text-field>
											</template>
										</v-edit-dialog> 
									</v-row>
									<v-row>
									<v-select class="mx-3" filled hide-details="" v-model="proveedor"
									:items="Proveedores" label="Categoria 3">
									</v-select>
									<v-btn color="primary" icon="" @click="GestionProveedores()"> <v-icon> mdi-account-multiple-plus </v-icon>  </v-btn>
									</v-row>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="4" md="4" sm="6" xs="6">
									<v-select filled hide-details=""  v-model="Categoria" :items="Categorias" label="Categoria"/>
									<v-text-field :disabled="formTitle == 'Actualizar'" filled hide-details="" onfocus="this.select();" v-model="codigoProducto" label="Código interno"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="cabys" label="Código CABYS"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="barcode" label="Código de barras"/>
									
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="4" md="3" sm="6" xs="6">
									<v-text-field filled hide-details="" onfocus="this.select();" type="number" v-model="precioVenta" label="Precio Venta"/>
									<v-text-field filled hide-details="" onfocus="this.select();" type="number" v-model="precioMayor" label="Precio 2 (Mayor)"/>
									<v-text-field filled hide-details="" onfocus="this.select();" type="number" v-model="precioDetalle" label="Precio 3(Detalle)"/>
									<v-text-field v-if="esSupervisor || esAdministrador || esBoss || esBodega" filled hide-details="" onfocus="this.select();" type="number" v-model="precioCompra" label="Precio Compra"/>
									<v-text-field filled hide-details="" type="date" v-model="Fecha" label="Fecha Ingreso"/>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="3" md="3" sm="6">
									<v-text-field filled hide-details="" onfocus="this.select();" hint="Digite unicamente números" type="number" persistent-hint v-model="nicotina" label="Nicotina"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="maxDescuento" label="Max% Descuento"/>
									<v-select filled hide-details="" v-model="categoria" @change="EsGravado()"
									:items="Impuesto" label="% IVA"/>
									<v-select filled hide-details="" v-model="cocina"
									:items="Cocinas" label="Comanda"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="depresiacion" label="Depreciación"/>
									<v-text-field filled hide-details="" v-if="Categoria == 'ACTIVO'" onfocus="this.select();" v-model="vidaUtil" label="Vida Util en años"/>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="3" md="3" sm="6">
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="ubicacion" label="Ubicacion"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="sku" label="Código SKU"/>
									<v-combobox
										v-model="variaciones"
										chips
										clearable
										filled hide-details=""
										label="Tags o Tallas"
										persistent-hint
										hint="Seleccione cantidades predefinadas a vender o variaciones del producto, tallas ect."
										multiple
										variant="solo"
									>
										<template v-slot:selection="{ attrs, item, select, selected }">
										<v-chip
											v-bind="attrs"
											:model-value="selected"
											closable
											color="info"
											@click="select"
											@click:close="remove(item)"
										>
											<strong>{{ item }}</strong>&nbsp;
										</v-chip>
										</template>
									</v-combobox>
									
									<v-autocomplete
									:items="acabadosFiltrados"
									label="Acabados"
									chips
									filled
									closable-chips
									v-model="acabadosSeleccionados"
									multiple
									></v-autocomplete>
									
									
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="2" md="3" sm="6">
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="maximo" label="Maximo"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="minimo" label="Minimos"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="comision" label="Comisión"/>
									<v-text-field filled hide-details="" onfocus="this.select();" v-model="Color" label="Color"/>
									<v-text-field filled hide-details=""  onfocus="this.select();" v-model="MaxFactura" label="% Max Fact"/>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" lg="10" md="10" sm="12" v-if="esProducto">	
									
									<v-textarea v-model="SubFamilia"  auto-grow
										solo
										rows="1"
										row-height="10"
										label="Descripción del producto">
									</v-textarea>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" sm="3" v-if="!esProducto">
									<v-menu
									ref="menu"
									v-model="menu6"
									:close-on-content-click="false"
									:nudge-right="40"
									:return-value.sync="time"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
									>
									<template v-slot:activator="{ on }">
										<v-text-field
										v-model="hora"
										label="Hora Inicial:"
										readonly
										v-on="on"
										></v-text-field>
									</template>
									<v-time-picker format="24h" @click:minute="$refs.menu.save(time)" @change="menu6 = false" min="05:00" v-if="menu6" v-model="hora" color="green lighten-1" :max="hora2" :allowed-minutes="(m) => m % 30 == 0" header-color="primary"></v-time-picker>
									</v-menu>
									
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" sm="3" v-if="!esProducto">
								<div>
								</div>
									<v-menu
									ref="menu"
									v-model="menu7"
									:close-on-content-click="false"
									:nudge-right="40"
									:return-value.sync="time"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
									>
									<template v-slot:activator="{ on }">
										<v-text-field
										v-model="hora2"
										:rules="horaFinalR"
										label="Hora Final:"
										readonly
										v-on="on"
										></v-text-field>
									</template>
									<v-time-picker format="24h"  scrollable="" full-width @click:minute="$refs.menu.save(time)" v-if="menu7" v-model="hora2" color="green lighten-1" :min="hora"  :allowed-minutes="(m) => m % 60 == 0" header-color="primary"></v-time-picker>
										</v-menu>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" sm="3" v-if="!esProducto">
								<v-text-field v-model="hora4" type="tel" label="Duración Maxima en horas"> </v-text-field>
								</v-col>
								<v-col style="border: 1px solid rgb(224, 224, 224);" cols="12" sm="3" v-if="!esProducto">
								<v-text-field v-model="CantPersonas" type="tel" label="Cantidad máxima de personas"> </v-text-field>
								</v-col>
								<v-flex xs12 sm12 md12 v-show="valida">
									<v-alert
										text
										prominent
										v-for="v in validaMensaje" :key="v" v-text="v"
										type="error"
										icon="mdi-cloud-alert"
									>
									</v-alert>
								</v-flex>
							</v-row>
						</v-col>
					</v-row>
					<v-divider/>
					<v-card-actions>
						<v-btn color="success" outlined class="mx-4"  @click="abrirCabys()">Catalogo CABYS</v-btn>
						{{Cateogoria}}
						<v-spacer>
						</v-spacer>
						<v-btn :loading="modelCarga" color="error" outlined class="mx-4"  @click="close"> <v-icon> mdi-cancel </v-icon> Cancelar</v-btn>
						<v-btn :loading="modelCarga" color="green" outlined class="mx-4"  @click="guardar"> <v-icon> mdi-content-save </v-icon> {{ formTitle }}</v-btn>
					</v-card-actions>
			</v-card>
        </v-dialog>
      </v-row>
    </template>
    <template v-slot:item.action="{ item }">
      <v-menu top :offset-y="offset">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab=""
                v-bind="attrs"
                v-on="on"
                :disabled="esVendedor"
              > <v-icon color="info">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list>
				<v-list-item @click="AñadirCodigo(item)">
					<v-list-item-title><v-icon >mdi-barcode-scan</v-icon> Gesionar códigos seriales</v-list-item-title>
				</v-list-item>
				<v-list-item >
					<v-list-item-title @click="DeleteProducto(item)" >
					<v-icon color="info">mdi-dots-horizontal</v-icon>Eliminar </v-list-item-title>
				</v-list-item>
				<v-list-item @click="editItem(item)">
					<v-list-item-title >
					<v-icon >mdi-pencil</v-icon> Editar Producto</v-list-item-title>
				</v-list-item>
				
            </v-list>
      </v-menu>
    </template>
	<template v-slot:item.actionImg="{ item }">
		<img style="border: 2px solid grey; border-radius: 10px;" width="150" height="150" class="preview" :src="item.localizacion">
    </template>
	<template v-slot:item.sincServer1="props">
		<v-checkbox @change="Actualizar(props.item)" v-model="props.item.sincServer1" color="red" > </v-checkbox>
    </template>
    
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reiniciar</v-btn>
    </template>
  </v-data-table>
  
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
		dialog: false,
		search: '',
		modelSeriales: false,
		MaxFactura:0,
		seriales: [],
	  	idMovimiento: 0,
		NombreVariacion:"",
		e1: 1,
		Selecciones:"",
		SeleccionesOpciones : [1,2,3,4,5,6,7,8,9,10],
		vencimiento: "",
		serial : "",
		sku:0,
		modeltrazlado:false,
		Talla:"",
		Color:0,
		CategoriaVariacion: "",
		dialogCompuesto:false,
		cat1: "",
		localidadorigen:'',
		localidaddestino:'',
		headers: [
			{ text: 'Opciones', value: 'action', sortable: false },
			{ text: 'Código', value: 'codigoProducto' },
			{ text: 'Nombre', value: 'descripcion' },
			{ text: 'Cat1', value: 'innerPack' },
			{ text: 'Cat2', value: 'tipoProducto',  },
			{ text: 'cat3', value: 'proveedor',  },
			{ text: 'Cod Barrs', value: 'barcode',  },
			{ text: 'Talla', value: 'medidas',  },
			{ text: 'SKU', value: 'codigoCaja',  },
			{ text: 'Descripcion', value: 'subFamilia', },
			{ text: 'Compuesto', value: 'compuesto', },
			{ text: 'Nic', value: 'pkg', },
			{ text: 'Ubicación', value: 'actividad', },
			{ text: 'Fecha', value: 'origen', },
			{ text: '% Puntos', value: 'comision', },
			{ text: 'Foto', value: 'actionImg', },
			{ text: 'Off-Online', value: 'sincServer1', },
		],
		Impuesto:[    
			{ text: '0% Excento', value: 0  },
			{ text: '1% Canasta Bàsica e Insumos', value: 1  }, 
			{ text: '2% Medicamentos, Primas, ect.' , value: 2  },
			{ text: '4% Boletos y Salud', value: 4  }, 
			{ text: '13% Tarifa General', value: 13  },                      
			],
			Compuestos:[    
				{ text: 'NO', value: false  },
				{ text: 'SI', value: true  },     
			],
			Proveedores:[    
				{ text: 'Mi Tienda', value: "Mi Tienda"  },              
			],
			Cocinas:[    
				{ text: 'NO', value: 0  },
				{ text: 'Cocina', value: 1  }, 
				{ text: 'Bar', value: 2  }, 
				{ text: 'Kiosko 1', value: 3  }, 
				{ text: 'Kiosko 2', value: 4  }, 
									
			],
		codigo: '',
		modelAprobaciones: false,
		tipoDocumentos: [
			'FISICA',
			'JURIDICA',
			'EXTRANJERO',
			],
		nombre: '',
		SubFamilia: '',
		stock: 0,
		modelproveedor: false,
		detalles:[],
		CompuestosItems:[],
		detalle: '',
		nicotina: 0,
		Localidades:[],
		modelAcabados: false,
		time:"",
		start: null,
		TipoCategoria: "",
		CategoriasTipo: ["Inventariado","Detalle"],
		chips: ['Azul', 'Verde'],
		OpcionesVariacion:[],
		ubicacion:0,
		menu21: null,
		Estados:["Suspendido","Disponible","Sin Stock"],
		Variaciones: [{Variacion:"Talla",Estado:true,Opciones:[
				{title: 'XS', Estado: "Suspendido"},
				{title: 'S', Estado: "Disponible"},
				{title: 'M',Estado: "Sin Stock"},
				{title: 'L',Estado: "Disponible"},
				{title: 'XL',Estado: "Suspendido"},
		]}, 
		{Variacion:"Color",Estado:true,Opciones:[
				{title: 'Negro', Estado: "Suspendido"},
				{title: 'Gris', Estado: "Disponible"},
				{title: 'Azul',Estado: "Sin Stock"},
				{title: 'Rosa',Estado: "Disponible"},
				{title: 'Rojo',Estado: "Suspendido"},
				{title: 'Lila',Estado: "Suspendido"},
				{title: 'Verde',Estado: "Suspendido"},
		]}],
		cabeceraSeriales: [
			{ text: 'Código Producto', value: 'codigoproducto', sortable: false  },
			{ text: 'Nombre Producto', value: 'descricion', sortable: false  },
			{ text: 'Código Serial', value: 'codigoSerial', sortable: false  },
			{ text: 'Estado ', value: 'estado', sortable: false  },
			{ text: 'Vencimiento ', value: 'vencimiento', sortable: false  },
			{ text: 'Proveedor ', value: 'proveedor', sortable: false  },
			{ text: 'Eliminar ', value: 'actionmovimiento', sortable: false  },
		],
		cabeceraCompuestos: [
			{ text: 'Descripción', value: 'descripcion'},
			{ text: 'Precio', value: 'precio'},  
			{ text: 'C_Compuesto', value: 'codigoCompuesto'},  
			{ text: 'C_Producto', value: 'codigoAsoiado'}, 
			{ text: 'Valor 0', value: 'actionvalor'},
			{ text: 'Eliminar', value: 'actionmovimiento'},
			],
		cabeceraDetalles: [
			{ text: 'Código', value: 'idarticulo', sortable: false  },
			{ text: 'Descripción', value: 'descripcion', sortable: false  },
			{ text: 'Precio', value: 'precio', sortable: false  },
			{ text: 'Stock Origien', value: 'cantidad', sortable: false  },
			{ text: 'Stock Destino', value: 'cantidadDestino', sortable: false  },
			{ text: 'Cantidad Ingresar', value: 'actioncantidad', sortable: false  },
			{ text: 'Stock de', value: 'localidad', sortable: false  },
			{ text: 'Eliminar', value: 'actionmovimiento', sortable: false },
		],
		cabeceraAprobaciones: [
		{ text: 'Descripción', value: 'descripcion'  },
		{ text: 'Fecha', value: 'fecha' },
		{ text: 'Usuario', value: 'cajero' },
		{ text: 'Tipo', value: 'tipo' },
		{ text: 'Cantidad', value: 'cantidad' },
		{ text: 'Aprobar', value: 'actioncantidad', sortable: false  },
		{ text: 'Denegar', value: 'actionmovimiento', sortable: false },
		],
		ProveedoresDetalles: [
		{ text: 'Nombre', value: 'proveedor'},
		{ text: 'Tipo Ced', value: 'tipoCedula'},
		{ text: 'Cédula', value: 'cedula', sortable: false  },
		{ text: 'Correo', value: 'correo', sortable: false  },
		{ text: 'Dirección', value: 'direccion', sortable: false  },
		{ text: 'Eliminar', value: 'actionmovimiento', sortable: false },
		],
		precio_venta: 0,
		descripcion: '',
		valida: 0,
		Cat: '',
		CantPersonas: 2,
      	TipoMovimiento: [
         {text:'Entrada', value: 'INGRESO'},
         {text:'Salida', value:'SALIDA'}],
		activos: [
			{text:'SI', value: 0},
			{text:'NO', value:1}],
      	validaMensaje:[],
		Categoria: 'PRODUCTO',
		Categorias: ["PRODUCTO","INSUMO","SERVICIO","ACTIVO"],
		esProducto: true,
		Ivi: true,
		articulos:[],
		adModal: 0,
		activo:0,
		Mensaje: '',
		acabados: [],
		acabado: "",
		snackbar: false,
		menu2: false,
		menu3: false,
		menu4: false,
		menu5: false,
		menu6: false,
		menu7: false,
		menu8: false,
		menu9: false,
		hora: "00:10",
		hora2: "24:00",
		hora4: 1,
		adAccion: 0,
		adNombre: '',
		adId: '',
		barcode: '',
		cantidadIngreso: 0,
		cabys: 0,
		listaAprobaciones: [],
		selectedFile: null,
		Costo:'',
		loading: false,
		Tallas:[],
		costoacabado: 0,
		nombreAcabado: "",
		CodigoAcabado: "",
		cedulaproveedor: '',
		tpcedulaproveedor: '',
		nombreproveedor: '',
		stop: false,
		telefonoproveedor: '',
		correoproveedor: '',
		direccionproveedor: '',
		codigoProducto: '',
		proveedor:'Mi Tienda',
		modelCarga: true,
		categoria:13,
		uploadValue1: 0,
		Fecha: Date().now,
		LocalidadesSelectas:[],
		uploadValue:0,
		familia:'',
		vidaUtil: 1,
		precio: 0,
		truevalue: true,
		modelinventario: false,
		familias:[],
		precioCompra:10,
		depresiacion:0,
		precioVenta: 0,
		precioMall: 0,
		precioCentral: 0,
		precioDetalle: 0,
		precioMayor: 0,
		maximo:5,
		minimo:1,
		tipoProducto:'',
		maxDescuento: 90,
		comision:1,
		codigoCABYS: "",
		IdProductKey:0,
		idProductKey: 0,
		localizacion:'https://noah.cr/ImagenesGenerales/usuario.png',
		compuesto: false,
		uploadValue: 0,
		switch1: true,
		codigo:"",
		cocina: 0,
		estado: 0,
		DatoMovimiento: '',
		acabadosSeleccionados:[],
		nombre: 'NA',
		SoN: 'S',
		productosVariacion:[],
		valorCero: false,
		localidad: "",
		productos: [],
		desserts: [],
		editedIndex: -1,
		acabadosFiltrados:[],
		expandedRows: [], // Almacena las filas expandidas
		Opciones:[],
		variaciones:[],
		editedItem: {
		
			name: '',
			calories: 0,
			fat: 0,
			gestionProveedor: [],
			carbs: 0,
			protein: 0,
		},
		defaultItem: {
			name: '',
			calories: 0,
			fat: 0,
			carbs: 0,
			protein: 0,
		},
    }),

    computed: {
		formTitle () {
			return this.editedIndex === -1 ? 'Agregar' : 'Actualizar'
		},
		esBodega(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Bodegas';
		},
		esBoss(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Boss';
		},
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
		},
		esSupervisor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Supervisor';
		},
		esMarketing(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Marketing';
		},
		esVendedor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Vendedor';
		},
		esFacturador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Facturador';
		},
      
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
		this.listarAsociadas();
		this.initialize2()
		this.select()
		this.selectProveedores();
		this.Acabados();
    },

    methods: {
	truncate(text, length) {
		if (text.length <= length) return text;
		return text.substring(0, length) + '...';
	},
	toggleRow(item) {
		const index = this.expandedRows.indexOf(item.subFamilia);
		if (index === -1) {
			this.expandedRows.push(item.subFamilia);
		} else {
			this.expandedRows.splice(index, 1);
		}
	},
    onChangeFileUpload(event){
      this.selectedFile = event.target.files[0];
      this.onUpload();
    },
	preventNonNumericInput(event) {
		// Si el caracter ingresado no es un número, lo previene
		if (!event.key.match(/[0-9]/)) {
		event.preventDefault();
		}
	},
	MoverInventario(){
		this.modelinventario= true
		this.selectProductos();
	},
	AbrirTraslado(){
		this.modeltrazlado= true
		this.selectProductos();
	},
	listarAsociadas(){
		var items = [];
		let me=this;
		me.Localidades.push(me.$store.state.usuario.localidad);
		this.localidad = this.$store.state.usuario.localidad;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/LocalidadesAsociadas/'+this.$store.state.usuario.nombre,configuracion).then(function(response){
			items=response.data;
			items.map(function(x){
				me.Localidades.push(x.localdiadAsociada);
			});
		}).catch(function(error){
			alert("Producto no encontrado");
			me.modelCarga=false;
		});
	},
    /* onUpload(){
      const storageRef=firebase.storage().ref(`${this.selectedFile.name}`);
      const task = storageRef.put(this.selectedFile);
      task.on('state_changed',snapshot=>{
        let percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        this.uploadValue = percentage;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
          task.snapshot.ref.getDownloadURL().then((url)=>{
          this.localizacion =url;
        });
      }
      );
    }, */
	obtenerFechaActual() {
      const hoy = new Date();
      const dd = String(hoy.getDate()).padStart(2, '0');
      const mm = String(hoy.getMonth() + 1).padStart(2, '0'); // Enero es 0!
      const yyyy = hoy.getFullYear();
      return `${dd}/${mm}/${yyyy}`;
    },
	async generarTrazladoIngreso(){
		this.modelCarga = true;
		var me = this;
		var momentoActual = new Date() 
		var y = momentoActual.getFullYear();
		var m = momentoActual.getMonth() + 1;
		var d = momentoActual.getDate();
		var hora = momentoActual.getHours() 
		var minuto = momentoActual.getMinutes() 
		var segundo = momentoActual.getSeconds() 
		this.Fecha = m + "/" + d + "/" + y+"  "+hora+":"+minuto+":"+segundo;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		await axios.post('api/ProductosWebs/TrazladoMercaderiaIngreso',{
					'Usuario': this.$store.state.usuario.nombre,
					'localidad': this.localidaddestino,
					'detalles': me.detalles,
					'detalle': me.detalle,
					'Aprobado': me.switch1,
					'TipoIngreso': me.localidadorigen,
					
			},configuracion).then(function (response) {
				me.snackbar = true;
				me.Mensaje = "Trazlado de "+me.localidadorigen +" hacia "+me.localidaddestino+" realizado de forma exitosa";
				me.modeltrazlado = false;
				me.PrintDetails();
				me.detalles = [];
				me.modelCarga = false;
				})
				.catch(function (error) {
					me.modelCarga = false;
					alert(`Lo sentimos ha ocurrido un error, notifiuelo al 4070-1889`+error);  
			})   
    },
    initialize(){
        let me=this;
		this.modelCarga = true;
		this.categoria = 13;
        let barcodepreview = Date.now();
        let idsa = barcodepreview.toString();
        this.codigoProducto = idsa.substring(1, 12);
        this.barcode = idsa.substring(5, 12);
		this.idMovimiento = idsa.substring(5, 12);
        //let header={"Authorization" : "Bearer " + this.$store.state.token};
        let header={"Authorization" : "Bearer "};
        let configuracion= {headers : header};
          axios.get('api/Productos/ListarLocalidad/'+me.localidad,configuracion).then(function(response){
          me.articulos=response.data;
          me.modelCarga = false;
        }).catch(function(error){
            alert("Producto no encontrado");
			me.modelCarga = false;
        });
	},
	initialize2(){
        let me=this;
		this.modelCarga = true;
		this.categoria = 13;
        let barcodepreview = Date.now();
        let idsa = barcodepreview.toString();
        this.codigoProducto = idsa.substring(1, 12);
        this.barcode = idsa.substring(5, 12);
		this.idMovimiento = idsa.substring(5, 12);
        //let header={"Authorization" : "Bearer " + this.$store.state.token};
        let header={"Authorization" : "Bearer "};
        let configuracion= {headers : header};
          axios.get('api/Productos/ListarLocalidadTotal/'+me.localidad,configuracion).then(function(response){
          me.articulos=response.data;
          me.modelCarga = false;
        }).catch(function(error){
            alert("Producto no encontrado");
			me.modelCarga = false;
        });
	},
	async Actualizar (item){
			this.loading = true;
			let me = this;
			let sinc = 1;
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			if(item.sincServer1 == true){
				sinc = 1;
			} else
			sinc = 0;
			await axios.put('api/Productos/Actualizar',{
				'codigoProducto':item.codigoProducto,
				'descripcion':item.descripcion,
				'barcode':item.barcode,
				'IdProductKey': item.idProductKey,
				'precioVenta':item.precioVenta,
				'precioDetalle':item.precioDetalle,
				'precioMall':item.PrecioMall,
				'precioMayor':item.precioMayor,
				'precioCentral':item.precioCentral,
				'precioCompra': item.precioCompra,
				'compuesto': item.compuesto,
				'Categoria':item.categoria, //Define si es un servicio o un Producto
				'Origen':item.origen, // Este campo es la hora inicial del servicio
				'InnerPack': item.innerPack, // Este campo es la Hora final del servicio
				'CantHoras': item.cantHoras, // Este campo define la cantidad de horas disponibles del servicio
				'comision': item.comision,
				'maxDescuento': item.maxDescuento,
				'proveedor': item.proveedor,
				'localizacion': item.localizacion,
				'Cocina':item.cocina,
				'minimo':item.minimo,
				'maximo':item.maximo,
				'EstadoInventario': item.estadoInventario,
				'valorImpuesto':item.valorImpuesto,
				'Exe_Grad':item.exe_Grad,
				'tipoProducto': item.tipoProducto,
				'SubFamilia': item.subFamilia,
				'Localidad' : item.localidad,
				'CantPersonas': item.cantPersonas,
				'CodigoRaiz': item.codigoRaiz,
				'MaxDescuento':item.maxDescuento,
				'SincServer1':sinc, // Mostrar Item en la web
				'Color':item.Color,
				'CodigoCaja':item.codigoCaja,
				'Medidas':item.medidas,
				'SKU':item.SKU,
				'Dimensiones':item.MaxFactura,
				'Actividad':item.ubicacion,
				'Pkg':item.pkg,
				'Consignacion':item.consignacion,
			},configuracion).then(function(response){
				me.Mensaje = "Producto actualizado con éxito en "+item.localidad;
				me.snackbar = true;      
				me.dialog = false;       
				//me.initializeFinder();
			}).catch(function(error){
				alert("Error actualizando, código interno no encontradoen "+me.localidad + error)
			});
		},
	initializeFinder(){
		let me = this;
		this.modelCarga = true;
		let part1 = this.search.includes("/") ? this.search.substring(0, this.search.indexOf("/")) : this.search;
		
		let header = {"Authorization": "Bearer " + this.$store.state.token};
		let configuracion = { headers: header };

		let baseUrl = `api/Productos/ListarLocalidadFiltros/${me.localidad}`;
		let params = new URLSearchParams();

		// Revisa si 'search' necesita ser enviado como 'finder'
		if (me.search && me.search !== 'undefined' && me.search !== 'null') {
			params.append('finder', me.search);
		}
		if (me.cat1 && me.cat1 !== 'undefined' && me.cat1 !== 'null') {
			params.append('innerPack', me.cat1);
		}
		if (me.familia && me.familia !== 'undefined' && me.familia !== 'null') {
			params.append('tipoProducto', me.familia);
		}

		let urlWithParams = baseUrl + (params.toString() ? '?' + params.toString() : '');

		axios.get(urlWithParams, configuracion)
			.then(function(response){
				me.articulos = response.data;
				me.modelCarga = false;
			}).catch(function(error){
				me.modelCarga = false;
				console.error(error);
				alert("Producto no encontrado");
			});
	},
	eliminarProducto(id){
        //console.log(id)
        this.detalles = this.detalles.filter(e => e.id != id)
        //console.log(this.cards)
	},
	ListarLocalidadProductos(){
		let me=this;
		this.modelCarga = true;
		let barcodepreview = Date.now();
		let idsa = barcodepreview.toString();
		this.codigoProducto = idsa.substring(5, 12);
		this.barcode = idsa.substring(5, 12);
		//let header={"Authorization" : "Bearer " + this.$store.state.token};
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/Productos/ListarLocalidad/'+me.localidad,configuracion).then(function(response){
		me.articulos=response.data;
		me.modelCarga = false;
		}).catch(function(error){
			alert("Producto no encontrado");
			me.modelCarga = false;
		});
	},
	close () {
		this.dialog = false;
        this.limpiar();
	},
      limpiar(){
        this.precioVenta= 0;
        this.precioCompra=0;
		this.search = this.descripcion;
        this.descripcion="";
        this.descripcion="";
        this.barcode="";
        this.proveedor = "";
        this.familia = "",
        this.maximo=5;
        this.minimo=1;
        this.comision = 5;
        this.categoria = 13;
		this.sku = 0;
		this.Color = "",
		this.Talla = "",
        this.subFamilia="";
        this.codigoProducto=0;
        this.descripcion="";
		this.MaxFactura = 0;
		this.ubicacion = 0;
        this.editedIndex=-1;
        this.maxDescuento = 0;
		let barcodepreview = Date.now();
        let idsa = barcodepreview.toString();
        this.codigoProducto = idsa.substring(5, 12);
        this.barcode = idsa.substring(5, 12);
      },
	buscarCodigo(){
		let me=this;
		me.errorArticulo=null;
		if (this.codigo != "")
		{
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/ProductosWebs/BuscarCodigoVentaLocalidad/'+this.codigo+'/'+this.$store.state.usuario.localidad,configuracion)
			.then(function(response){
				me.agregarDetalle(response.data);
			}).catch(function(error){
				alert("No existe el artículo");
			});
		}
		this.codigo = "";
    },
	buscarCodigoBarras(){
		let me=this;
		if (this.codigo != "")
		{
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/ProductosWebs/BuscarCodigoBarrasLocalidad/'+this.codigo+'/'+this.$store.state.usuario.localidad,configuracion)
			.then(function(response){
				me.agregarDetalle(response.data);
			}).catch(function(error){
				me.Mensaje ="Articulo no encontrado, por favor verifique su código de barras";
				me.snackbar = true;
			});
		}
    },
    eliminarProductoserial(id){
        this.modelCarga = true;
        var me = this;
          if(confirm("Seguro que quiere eliminar el Serial ?")){
              me.seriales = me.seriales.filter(e => e.id != id)
              let header={"Authorization" : "Bearer " + this.$store.state.token};
              let configuracion= {headers : header};
               axios.post('api/CodigosSeriales/Delete',{
                  'Id': id,
                      
                },configuracion).then(function (response) {
                    me.snackbar = true;
                    me.Mensaje = 'Serial Eliminado Con éxito.';  
                    me.modelCarga = false;
                    
                  })
                  .catch(function (error) {
                      me.snackbar = true;
                      me.Mensaje = 'Error eliminando el serial.' +error;    
                      me.modelCarga = false;
                    })
          }


      },
      buscarProducto(){
		if(this.localidad == ""){
			this.Mensaje ="Seleccione una localidad para continuar"
			this.snackbar = true;
			return;
		}
		let me=this;
		me.errorArticulo=null;
		if (this.codigo != "")
		{
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/ProductosWebs/BuscarCodigoVentaLocalidad/'+this.codigo.value+'/'+this.localidad,configuracion)
			.then(function(response){
				me.agregarDetalle(response.data);
			}).catch(function(error){
				alert("No existe el artículo");
			});
		}
    },
	selectProductos(){
		let me=this;
		let header={"Authorization" : "Bearer " };
		let configuracion= {headers : header};
		var productosArray=[];
		axios.get('api/Productos/SelectProductos/'+this.localidad,configuracion).then(function(response){
			productosArray=response.data;
			productosArray.map(function(x){
				me.productos.push({text:x.descripcion, value:x.codigoProducto});
			});
			me.modelCarga = false;
		}).catch(function(error){
			console.log(error);
		});
	},
	
    selectLocalidad(){
		let me=this;
		var localidadesArray=[];
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/Localidades/SelectLocalidades',configuracion).then(function(response){
			localidadesArray=response.data;
			localidadesArray.map(function(x){
				me.localidades.push({text: x.nombre,value:x.nombre});
			});
		})
    },
    AprobarProducto(item){
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
          let me=this;
          axios.put('api/SalidaProductos/Aprobar',{
              'IdSalida':item.idsalida,
              'Descripcion':item.descripcion,
              'Codigo': item.codigo,
              'Localidad': item.localidad,
              'Costo': item.costo,
              'Cajero': item.cajero,
              'Cantidad': item.cantidad,
              'Estado': 0,
              'Tipo': 'INGRESO'
          },configuracion).then(function(response){
              me.snackbar = true;
              me.Mensaje = 'Registro Aprobado correctamente';
              me.Aprobaciones();                     
          }).catch(function(error){
              console.log(error);
              me.snackbar = true;
              me.Mensaje = 'Error al aprobar, reporte a su proveedor el problema';   
          });
    },
    DenegarProducto(item){
       
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuracion= {headers : header};
          let me=this;
          axios.put('api/SalidaProductos/Aprobar',{
              'IdSalida':item.idsalida,
              'Estado': -1,
          },configuracion).then(function(response){
              me.snackbar = true;
              me.Mensaje = 'Registro Denegado correctamente';
              me.Aprobaciones();                     
          }).catch(function(error){
              console.log(error);
              me.snackbar = true;
              me.Mensaje = 'Error al aprobar, reporte a su proveedor el problema';   
          });
    },
	ajustarInventarioALocalidadCero() {
		// Mensaje de confirmación
		if (window.confirm("¿Estás seguro de querer continuar? Este proceso no tiene marcha atrás.")) {
			let me = this;
			this.modelCarga = true;
			me.Mensaje = "Este Proceso puede tardar varios minutos, por favor espere...";
			me.snackbar = true;
			let header = {"Authorization": "Bearer "}; // Asegúrate de incluir el token de autorización si es necesario
			let configuracion = { headers: header };
			let data = {
				localidad: this.$store.state.usuario.localidad,
				usuario: this.$store.state.usuario.nombre // Asegúrate de que esta propiedad exista en tu store de VueX
			};

			axios.post('api/ProductosWebs/AjustarInventarioALocalidadCero', data, configuracion)
			.then(function (response) {
				console.log(response.data); // Procesar la respuesta
				me.Mensaje = "Productos configurados a stock 0 con éxito";
				me.snackbar = true;
				me.modelCarga =false;
				me.$router.push('/inventarioactual'); // Redirigir al usuario
			}).catch(function (error) {
				me.Mensaje = "Error en la solicitud: "+ error;
				me.snackbar = true;
				me.modelCarga =false;
				console.error("Error en la solicitud: ", error);
				// Aquí puedes manejar el error, mostrar un mensaje, etc.
			});
		} else {
			// El usuario ha cancelado la operación
			console.log("Operación cancelada por el usuario");
		}
	},

	buscarProductoDirecto(){
		let me=this;
		this.codigo = this.codigo.value !== undefined ? this.codigo.value : this.codigo;
		if (this.codigo != "")
		{
			let header={"Authorization" : "Bearer "};
			let configuracion= {headers : header};
			axios.get('api/Productos/BuscarCodigoVenta/'+this.codigo+'/'+this.$store.state.usuario.localidad,configuracion)
			.then(function(response){
				me.agregarDetalleDirecto(response.data);
			}).catch(function(error){
				// me.snackbar = true;
						//me.Mensaje = "Producto No encontrado";
			});
		}
	},
	AgregarVariacion(){
		this.Variaciones.push(
              {
              id: Date.now(),
              Variacion:this.CategoriaVariacion,
              Estado:true,
              Opciones: this.chips,
              }
		);
		this.dialogCompuesto= false;
	},
	remove (item) {
        this.chips.splice(this.chips.indexOf(item), 1)
	},
	EstadoVariacion(x){
		if(x =="Disponible"){
			return "green lighten-4";
		}else
		if(x == "Sin Stock"){
			return "red lighten-4";
		}else
		if(x == "Suspendido"){
			return "orange lighten-4";
		}
	},
	EstadoVariacionTexto(x){
		if(x =="Disponible"){
			return "light-green darken-4";
		}else
		if(x == "Sin Stock"){
			return "red darken-4";
		}else
		if(x == "Suspendido"){
			return "orange darken-4";
		}
	},
    agregarSerial(){
      this.modelCarga = true;
            this.seriales.push(
              {
              id: Date.now(),
              codigoSerial:this.serial,
              estado:"En Stock",
              codigoproducto: this.codigoProducto,
              descricion: this.nombre,
              }
              );
          var me = this;
          let header={"Authorization" : "Bearer " + this.$store.state.token};
          let configuracion= {headers : header};
            axios.post('api/CodigosSeriales/Crear',{
                  'Estado': "En Stock",
                  'Localidad': this.$store.state.usuario.localidad,
                  'Codigoproducto': me.codigoProducto,
                  'CodigoSerial': me.serial,
                  'Usuario':this.$store.state.usuario.nombre,
                  'Descricion': me.nombre,
				  'Vencimiento': me.start,
				  'Proveedor':me.nombreproveedor,
                      
                },configuracion).then(function (response) {
                      me.snackbar = true;
                      me.Mensaje = 'Serial registrado de forma exitosa';  
                      me.serial = '';
                      me.modelCarga = false
                  })
                  .catch(function (error) {
                      me.snackbar = true;
                      me.Mensaje = 'El Codigo serial ya fue registrado.'+error;  
                      me.modelcompra = false;
                })
    },
	async generarTrazlado(){
		if (this.localidaddestino == ''){
				this.Mensaje = 'Seleccione la localidad Destino';
				this.snackbar = true;
				return
		
			}  {
		this.modelCarga = true;
		var me = this;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		await  axios.post('api/ProductosWebs/TrazladoMercaderiaSalida',{
					'Usuario': this.$store.state.usuario.nombre,
					'localidad': this.localidadorigen,
					'detalles': me.detalles,
					'detalle': me.detalle,
					'Aprobado': me.switch1,
					'TipoIngreso': me.localidaddestino,
					'NoFactura': me.idMovimiento,
					
			},configuracion).then(function (response) {
				me.generarTrazladoIngreso();
				
				})
				.catch(function (error) {
					me.modelCarga = false;
					alert(`Lo sentimos ha ocurrido un error haciendo la salida de: `+me.localidadorigen+` o las ventas emergentes estan bloqueadas, por favor verifique el movimiento o notifique a su equipo de soporte 4070-1889`+error);  
			})
			} 
	},
	async generarTrazladoIngreso(){
		this.modelCarga = true;
		var me = this;
		var momentoActual = new Date() 
		var y = momentoActual.getFullYear();
		var m = momentoActual.getMonth() + 1;
		var d = momentoActual.getDate();
		var hora = momentoActual.getHours() 
		var minuto = momentoActual.getMinutes() 
		var segundo = momentoActual.getSeconds() 
		this.Fecha = m + "/" + d + "/" + y+"  "+hora+":"+minuto+":"+segundo;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		await axios.post('api/ProductosWebs/TrazladoMercaderiaIngreso',{
					'Usuario': this.$store.state.usuario.nombre,
					'localidad': this.localidaddestino,
					'detalles': me.detalles,
					'detalle': me.detalle,
					'Aprobado': me.switch1,
					'TipoIngreso': me.localidadorigen,
					'NoFactura': me.idMovimiento,
					
			},configuracion).then(function (response) {
				me.snackbar = true;
				me.Mensaje = "Traslado de "+me.localidadorigen +" hacia "+me.localidaddestino+" realizado de forma exitosa";
				me.modeltrazlado = false;
				me.modelCarga = false;
				})
				.catch(function (error) {
					me.modelCarga = false;
					alert(`Lo sentimos ha ocurrido un error trazladando hacia `+me.localidaddestino+` , notifique a su equipo de soporte 4070-1889`+error);  
			})   
		me.PrintDetails2();
		me.detalles = [];
	},
	PrintDetails2(){
		var products = "";
		for(var i = 0; i < this.detalles.length; ++i)
			{
				products += `<tr>
							<td class="col-3">`+this.detalles[i].idarticulo+`</td>
							<td class="col-6 text-1">`+this.detalles[i].descripcion+`</td>
							<td class="col-3 text-center">`+this.detalles[i].precio+`</td>
							<td class="col-1 text-center">`+this.detalles[i].cantidadDestino+`</td>
							<td class="col-1 text-center">`+this.detalles[i].cantidadIngreso+`</td>
							</tr>`
			}
		var win = `
					<html lang="en">
					<head>
					<meta charset="utf-8" />
					<meta http-equiv="X-UA-Compatible" content="IE=edge">
					<meta name="viewport" content="width=device-width, initial-scale=1">
					<link href="images/favicon.png" rel="icon" />
					<title>General Invoice - Koice</title>
					<meta name="author" content="harnishdesign.net">

					<!-- Web Fonts
					======================= -->
					<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900' type='text/css'>

					<!-- Stylesheet
					======================= -->
					<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/bootstrap/css/bootstrap.min.css"/>
					<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/vendor/font-awesome/css/all.min.css"/>
					<link rel="stylesheet" type="text/css" href="https://www.noah.cr/FacturaCSS/css/stylesheet.css"/>
					</head>
					<body>
					<!-- Container -->
					<div class="container-fluid invoice-container">
					<!-- Header -->
					<header>
					<div class="row align-items-center">
						<div class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0">
						<img id="logo" style="max-width:100px" src="`+this.Logo3+`" title="Logotipo" alt="Koice" />
						</div>
						<div class="col-sm-5 text-center text-sm-end">
						<h4 class="text-7 mb-0">Traslado de Mercadería</h4>
						</div>
					</div>
					<hr>
					</header>
					
					<!-- Main Content -->
					<main>
					<div class="row">
						<div class="col-sm-6"><strong>Fecha:</strong> `+this.Fecha+`</div>
						<div class="col-sm-6 text-sm-end"> <strong>Número de trazlado `+this.barcode+`:</strong> 16835</div>
						
					</div>
					<hr>
					<div class="row">
						<div class="col-sm-6 text-sm-end order-sm-1"> <strong>Info de transacción:</strong>
						<address>
						Usuario: `+this.$store.state.usuario.nombre+`<br />
						Origen: `+this.localidadorigen+`<br />
						Destino:`+this.localidaddestino+`<br />
						`+this.localidadencabezado[0].telefono+`
						</address>
						</div>
						<div class="col-sm-6 order-sm-0"> <strong>Datos del Negocio:</strong>
						<address>
						`+this.localidadencabezado[0].nombre+`<br />
						`+this.localidadencabezado[0].razonSocial+`<br />
						`+this.localidadencabezado[0].cedulaJuridica+`<br />
						`+this.localidadencabezado[0].telefono+`
						</address>
						</div>
					</div>
						
					<div class="card">
						<div class="card-body p-0">
						<div class="table-responsive">
							<table class="table mb-0">
							<thead class="card-header">
							<tr>
								<td class="col-3"><strong>Código I</strong></td>
								<td class="col-6"><strong>Nombre</strong></td>
								<td class="col-3 text-center"><strong>Precio Venta</strong></td>
								<td class="col-1 text-center"><strong>Stock Anterior</strong></td>
								<td class="col-1 text-center"><strong>Cantidad Trasladada</strong></td>
							</tr>
							</thead>
							<tbody>
								`+products+`
							</tbody>
							<tfoot class="card-footer">
								<tr>
									<td colspan="4" class="text-end"><strong>Mensajero:</strong></td>
									<td class="text-end">________</td>
								</tr>
								<tr>
									<td colspan="4" class="text-end"><strong>Entregado por: </strong></td>
									<td class="text-end">________</td>
								</tr>
								<tr>
									<td colspan="4" class="text-end border-bottom-0"><strong>Recibido por:</strong></td>
									<td class="text-end border-bottom-0">________</td>
								</tr>
							</tfoot>
							</table>
						</div>
						</div>
					</div>
					</main>
					<!-- Footer -->
					<footer class="text-center mt-4">
					<p class="text-1"><strong>NOTE :</strong> `+this.detalle+`.</p>
					<div class="btn-group btn-group-sm d-print-none">  <a href="#" onclick="window.print();" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-print"></i> Print</a>  <a href="" class="btn btn-light border text-black-50 shadow-none"><i class="fa fa-download"></i> Volver</a> </div>
					</footer>
					</div>
					</body>
				</html>
			`;
			document.documentElement.innerHTML = win;
			setTimeout(() => {
				win.print();
			}, 2000);
			//win.window.close();
	},
	SelectFam(){
		//console.log(id)
		this.detalles = this.detalles.filter(e => e.id != id)
		//console.log(this.cards)
	},
      editarProductoSuma(index){
        this.cantidades = this.detalles[index].cantidad
        this.indexProducto = index 
        this.detalles[this.indexProducto].cantidad = this.cantidades + 1
       // this.sumarCantidadProducto();
      },
      editarProductoResta(index){
          if(this.detalles[index].cantidad != 1){
            this.cantidades = this.detalles[index].cantidad
            this.indexProducto = index 
            this.detalles[this.indexProducto].cantidad = this.cantidades - 1
          } 
        
       // this.sumarCantidadProducto();
      },
      AñadirCodigo(item){
        this.modelCarga = true;
        this.modelSeriales = true;
        this.codigoProducto = item.codigoProducto;
        this.nombre = item.descripcion;
        let me=this;
                let header={"Authorization" : "Bearer " };
                let configuracion= {headers : header};
                var productosArray=[];
                axios.get('api/CodigosSeriales/ObtenerSerial/'+me.$store.state.usuario.localidad+'/'+me.codigoProducto,configuracion).then(function(response){
                    me.seriales=response.data;
                    me.modelCarga = false;
                }).catch(function(error){
                    me.modelCarga = false;
                    me.Mensaje = error;
                    me.snackbar = true;
                    console.log(error);
                });

      },
    agregarDetalle(data = []){
		this.errorArticulo=null;
		if (data['codigoProducto'] != '' && data['codigoProducto'] != undefined)
		{
			this.codigo = data['codigoProducto'];
		}
		if (this.encuentra(data['codigoProducto'])){
			alert("El producto ya ha sido agregado.");
		}
		else
		{
			if (this.codigo != '')
			{
			this.detalles.push(
			{
			id: Date.now(),
			codigoProducto:data['codigoProducto'],
			idarticulo:data['codigoProducto'],
			descripcion:data['descripcion'],
			cantidad:data['cantidadInvActual'],
			precio:data['precioVenta'],
			gravado:data['exe_Grad'],
			cantidadIngreso: 0,
			localidad:data['sitio'],
			}
			);
			
			this.codigo = '';
			this.$nextTick(() => {
				this.$refs.inputCantidadIngreso.focus();
			});
			if(this.localidaddestino != ''){
				this.BuscarStockDestino(data['codigoProducto'])
			}
			}
			else{
			alert("Debe digitar un código de producto o servicio");
			}
		}
    },
	async BuscarStockDestino(codigo){
		let me=this;
		let stock = 0;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		await axios.get('api/ProductosWebs/ConsultaStock/'+codigo+'/'+this.localidaddestino,configuracion).then(function(response){
			stock=response.data;
			me.AsignarInventario(codigo,stock.stock)
		}).catch(function(error){
			//alert("Producto no encontrado en localidad "+me.localidaddestino + error);
			console.log(error);
		});
	},
	AsignarInventario(id,stock){
		for(var i=0;i<this.detalles.length;i++){
			if (this.detalles[i].idarticulo==id){
				this.detalles[i].cantidadDestino = stock;
			}
		}
		return 
	},
    encuentra(id){
		var sw=0;
		for(var i=0;i<this.detalles.length;i++){
			if (this.detalles[i].idarticulo==id){
				sw=1;
			}
		}
		return sw;
    },
    abrirCabys(){
      var url = 'https://app.powerbi.com/view?r=eyJrIjoiZDdmNTBlYTktYmE0Ni00NGM3LWJhNzYtMGZhNjBlNTQyYTdmIiwidCI6IjYxOGQwYTQ1LTI1YTYtNDYxOC05ZjgwLThmNzBhNDM1ZWU1MiJ9';
      var win = window.open(url, '_blank');
      win.focus();
    },
    
	async generarInventario(){
		if(this.localidad == ""){
			this.Mensaje ="Seleccione una localidad para continuar"
			this.snackbar = true;
			return;
		}
          if (this.DatoMovimiento == ''){
                this.Mensaje = 'Seleccione el tipo de movimiento';
                this.snackbar = true;
                return
          
            }  {
			this.loading = true;
			var momentoActual = new Date() 
			var y = momentoActual.getFullYear();
			var m = momentoActual.getMonth() + 1;
			var d = momentoActual.getDate();
          	var hora = momentoActual.getHours() 
			var minuto = momentoActual.getMinutes() 
			var segundo = momentoActual.getSeconds() 
			this.Fecha = m + "/" + d + "/" + y+"  "+hora+":"+minuto+":"+segundo;
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/ProductosWebs/MovimientoInventario',{
                      'Usuario': this.$store.state.usuario.nombre,
                      'localidad': this.localidad,
					  'detalles': me.detalles,
					  'detalle': me.detalle,
                      'Aprobado': me.switch1,
                      'TipoIngreso': me.DatoMovimiento,
					  'NoFactura': me.idMovimiento,
                      
                },configuracion).then(function (response) {
                    me.Mensaje ="Movimiento realizado con éxito"
					me.snackbar = true;
					me.loading = false;
					me.finishOrder();
                    //me.modelinventario = false;
                    me.detalles = [];
					let barcodepreview = Date.now();
					let idsa = barcodepreview.toString();
					me.idMovimiento = idsa.substring(5, 12);
					
                  })
                  .catch(function (error) {
						me.loading = false;
                      alert(`Lo sentimos ha ocurrido un error, notifiuelo al 4070-1889`);  
                })
                } return;
    },
	finishOrder() {
		var divContents = document.getElementById("bill").innerHTML;
		var a = window.open('', '', 'height=500, width=500');
		
			a.document.write('<html>');
			a.document.write(divContents);
			a.document.write('</body></html>');
			a.document.close();
			a.print();
		
	},

	Set0(){
		this.modelCarga = true;
		var me = this;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		axios.post('api/ProductosWebs/ColocarEnCero',{
			'Localidad': this.$store.state.usuario.localidad,
				
		},configuracion).then(function (response) {
			alert("Inventario menor a 0 se ha ocultado de la tienda en linea")
			me.modelCarga = false;
			})
			.catch(function (error) {
				alert("Error actualizando, por favor reporte a su proveedor")  
				me.modelCarga = false;
			})
        
	},
	buscarCompuesto(){  
		let me=this;
		this.modelCarga = true;
		let header={"Authorization" : "Bearer "};
		let configuracion= {headers : header};
		axios.get('api/PosicionProductoCajas/ListarAsociado/'+this.codigoProducto,configuracion).then(function(response){
			me.CompuestosItems=response.data;
			me.modelCarga = false;
			});
	},
	AgregarCompuesto(){
		let barcodepreview = Date.now();
        let idsa = barcodepreview.toString();
        var codigoCompuesto = idsa.substring(5, 3);
		this.modelCarga = true;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		axios.post('api/PosicionProductoCajas/CrearPosicion',{
					'codigoAsoiado':this.codigoProducto,
					'codigoCompuesto': codigoCompuesto,
					'descripcion': this.NombreVariacion,
					'Precio': this.m2Variacion,
					'ValorCero': 0,
					'Localidad': this.$store.state.usuario.localidad,
					'Id':this.CompuestosItems.length + 5,
					
			},configuracion).then(function (response) {
					me.Mensaje = "Producto Agregado con éxito.";
					me.snackbar = true;
					me.modelCarga = false;
					me.buscarCompuesto();
				})
				.catch(function (error) {
					me.modelCarga = false; 
					alert(`Problema Cargando el producto a la base de datos, compruebe la existencia`+error); 
					
			})	
		this.codigo = '';
	},
    IngresarProveedor(){
          if (this.nombreproveedor == ''){
                    alert(`Seleccione el nombre del proveedor`); 
              
                }  {
          this.loading = true;
          var me = this;
          let header={"Authorization" : "Bearer " + this.$store.state.token};
          let configuracion= {headers : header};
            axios.post('api/proveedores/Crear',{
                      'Usuario': this.$store.state.usuario.nombre,
                      'Localidad': this.$store.state.usuario.localidad,
                      'Proveedor': me.nombreproveedor,
                      'Cedula': me.cedulaproveedor.replace(/\s+/g, ''),
                      'Correo': me.correoproveedor,
                      'Telefono': me.telefonoproveedor,
                      'TipoCedula': me.tpcedulaproveedor,
                      'Dirreccion': me.direccionproveedor
                      
                },configuracion).then(function (response) {
                     alert(`Proveedor agregado con éxito`);  
                    me.loading = false;
                    me.modelproveedor = false;
                    me.selectProveedores();
                    me.nombreproveedor = '',
                    me.cedulaproveedor = '',
                    me.telefonoproveedor = '',
                    me.direccionproveedor = '',
                    me.correoproveedor = ''
                  })
                  .catch(function (error) {
                      alert(`Lo sentimos ha ocurrido un error, notifiuelo al 4070-1889`);  
                })
                } return;
    },
	SyncProd() {
		let me = this;
		this.modelCarga= true;
		let header = { "Authorization": "Bearer " + me.$store.state.usuario.token };
		let configuracion = { headers: header };
		
		// Reemplaza 'nombreLocalidad' con el valor de la localidad que deseas sincronizar
		let localidad = me.$store.state.usuario.localidad;

		axios.get('api/ProductosWebs/SincronizarProductos/' + localidad, configuracion)
			.then(function(response) {
				// Maneja la respuesta del servidor
				console.log(response.data);
				alert("Sincronización de productos no compuestos sin stock completada con éxito para tu tienda en línea. Tu productos con stock han dado de alta, sin stock se han retirado.");
				me.modelCarga = false
			})
			.catch(function(error) {
				// Maneja cualquier error que ocurra durante la solicitud
				console.error("Ocurrió un error al sincronizar los productos: ", error);
				me.modelCarga = false
				alert("Error al sincronizar los productos.");
			});
	},

    Aprobaciones(){
        this.modelAprobaciones = true;
        let me=this;
        let header={"Authorization" : "Bearer " };
        let configuracion= {headers : header};
        var familiasArray=[];
        axios.get('api/SalidaProductos/Listar/'+me.$store.state.usuario.localidad+'/1',configuracion).then(function(response){
            familiasArray=response.data;
            me.listaAprobaciones = familiasArray;
        }).catch(function(error){
            console.log(error);
        });
    },
	AgregarDetalleAcabado(){
		this.nombreAcabado = this.acabado.text;
		this.costoacabado = this.acabado.value;
	},
	Acabados(){
        let me=this;
        let header={"Authorization" : "Bearer " };
        let configuracion= {headers : header};
        var familiasArray=[];
        axios.get('api/Acabados/ListarAcabados/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
            familiasArray=response.data;
			me.acabados = familiasArray;
			familiasArray.map(function(x){
                me.acabadosFiltrados.push(x.producto);
            });
        }).catch(function(error){
            console.log(error);
        });
    },
	ListarAcabados(){
        let me=this;
        let header={"Authorization" : "Bearer " };
        let configuracion= {headers : header};
        var familiasArray=[];
        axios.get('api/Acabados/ListarAcabados/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
            familiasArray=response.data;
			me.acabados = familiasArray;
			familiasArray.map(function(x){
                me.acabadosFiltrados.push(x.producto);
            });
        }).catch(function(error){
            console.log(error);
        });
    },
	añadirAcabado(){
        this.loading = true;
		var me = this;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		axios.post('api/Acabados',{
			'Localidad': this.$store.state.usuario.localidad,
			'Codigo':this.codigoProducto,
			'Producto':this.nombreAcabado,
			'Costo':this.costoacabado,
		},configuracion).then(function (response) {
			me.snackbar = true;
			me.Mensaje= "Acabado cargado con éxito"
			me.loading = false;
			me.codigoProducto = "";
			me.nombreAcabado = "";
			me.costoacabado = "";
			me.ListarAcabados();
			})
			.catch(function (error) {
				alert("Error creando acabado, por favor reporte a su proveedor")  
				me.loading = false;
			})
    },
	actualizarAcabado(item){
        this.loading = true;
		var me = this;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		axios.put('api/Acabados/'+item.id,{
			'Localidad': this.$store.state.usuario.localidad,
			'Codigo':item.codigo,
			'Producto':item.producto,
			'Costo':item.costo,
			'Id':item.id,
		},configuracion).then(function (response) {
			me.snackbar = true;
			me.Mensaje= "Acabado actualizado con éxito"
			me.loading = false;
			//me.ListarAcabados();
			})
			.catch(function (error) {
				alert("Error actualizadno acabado, por favor reporte a su proveedor")  
				me.loading = false;
			})
    },
	EliminarAcabado(id){
        this.loading = true;
		var me = this;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		axios.delete('api/Acabados/'+id,configuracion).then(function (response) {
			me.snackbar = true;
			me.Mensaje= "Acabado cargado con éxito"
			me.loading = false;
			me.ListarAcabados();
			})
			.catch(function (error) {
				alert("Error creando acabado, por favor reporte a su proveedor")  
				me.loading = false;
			})
    },
	EliminarFamilia(){
		if (this.familia == ''){
				alert(`Digite el nombre de la familia para guardarla`); 
			
			}  {
		this.loading = true;
		var me = this;
		let header={"Authorization" : "Bearer " + this.$store.state.token};
		let configuracion= {headers : header};
		axios.delete('api/Familias/DeleteFamilias/'+this.familia+'/'+this.$store.state.usuario.localidad,{
			},configuracion).then(function (response) {
				alert(`Familia eliminada con éxito `+response);  
				me.loading = false;
				me.select();
				me.familia = '';
				})
				.catch(function (error) {
					alert(`La familia seleccionada no se puede eliminar, esta viene por defecto, lo sentimos.. :`+error);  
			})
			} return;
    },
    IngresarFamilia(){
          if (this.familia == ''){
                    alert(`Seleccione el nombre de la familia`); 
              
                }  {
          this.loading = true;
          var me = this;
          let header={"Authorization" : "Bearer " + this.$store.state.token};
          let configuracion= {headers : header};
            axios.post('api/Familias/Crear',{
                      'Nombre': this.familia,
                      'Localidad': this.$store.state.usuario.localidad,
                      
                },configuracion).then(function (response) {
                     alert(`Familia agregada con éxito`);  
                    me.loading = false;
                    me.select();
                  })
                  .catch(function (error) {
                      alert(`La familia ya existe, no es necesario agregarla`);  
                })
                } return;
    },
    EsGravado(){
      if(this.categoria == 0){
        this.SoN = 'N'
        return;
      }
      this.SoN = 'S'
    },
		select(){
			let me=this;
			this.familias = [];
			let header={"Authorization" : "Bearer " };
			let configuracion= {headers : header};
			var familiasArray=[];
			axios.get('api/Familias/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
				familiasArray=response.data;
				familiasArray.map(function(x){
					me.familias.push(x.nombre);
				});
			}).catch(function(error){
				console.log(error);
			});
		},
		GestionProveedores(){
					this.modelproveedor = true;
					let me=this;
					let header={"Authorization" : "Bearer " };
					let configuracion= {headers : header};
					var familiasArray=[];
					axios.get('api/Proveedores/Listar/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
						me.gestionProveedor=response.data;
						me.Mensaje = "Provedores listados";
						me.snackbar = true;
						
					}).catch(function(error){
						console.log(error);
					});
				},
		selectProveedores(){
				let me=this;
				let header={"Authorization" : "Bearer " };
				let configuracion= {headers : header};
				var proveedoresArray=[];
				axios.get('api/proveedores/Select/'+me.$store.state.usuario.localidad,configuracion).then(function(response){
					proveedoresArray=response.data;
					proveedoresArray.map(function(x){
						me.Proveedores.push(x.proveedor);
					});
				}).catch(function(error){
					console.log(error);
				});
		},
		async GestionarProducto(){
			if(this.LocalidadesSelectas.length === 0){
				this.Mensaje = "Seleccione al menos 1 localidad para continuar";
				this.snackbar = true;
				return;
			}

			this.stop = false;  // Asegúrate de que stop se inicializa correctamente

			for(let localidad of this.LocalidadesSelectas){
				if(this.stop){
					break;
				}

				// Clona o reestablece el estado necesario para la localidad actual
				const estadoLocal = {
					Ivi: this.Ivi,  // Clona el estado que puede ser modificado
					localidad: localidad
				};
				
				await this.guardar(estadoLocal);
			}

			this.dialog = false;
			this.initializeFinder();
			this.selectProductos(this.LocalidadesSelectas[this.LocalidadesSelectas.length - 1]);
		},
		AgregarInventario () {
			this.ValidarExistencia();
			this.modelCarga  = true;
			let me=this;
			let Compra = 0;
			let productosDatos = [];
			var ExeGradinterno = '';
			for(var i = 0; i < this.detalles.length; ++i)
			{
				if(this.detalles[i].existe == false){
					if( me.detalles[i].valorImpuesto == 0){
						ExeGradinterno = 'N'
					} else {
						ExeGradinterno = 'S'
					}
					Compra = parseInt((parseInt(me.detalles[i].precioCompra)* parseInt((me.detalles[i].valorImpuesto / 100))) + parseFloat(me.detalles[i].precioCompra));
					productosDatos.push(
					{
						CodigoProducto:me.detalles[i].idarticulo,
						Cantidad:parseFloat(me.detalles[i].cantidadIngreso), 
						UnidadMedida:"UND",
						Descripcion:me.detalles[i].descripcion,
						PrecioVenta:me.detalles[i].precio,
						PrecioCompra : Compra,                          
						ValorImpuesto:me.detalles[i].valorImpuesto,
						Gravado:me.detalles[i].gravado,
						CodigoRaiz: me.detalles[i].cabys,
						TipoProducto: me.detalles[i].familia,
						BarCode : me.detalles[i].codigoBarras,
						EstadoInventario: me.detalles[i].Ivi,
						Exe_Grad: ExeGradinterno,
						proveedor: me.detalles[i].proveedor,

					}
					);
				}
		}

				let header={"Authorization" : "Bearer "};
				let configuracion= {headers : header};
					//Código para guardar
					
					axios.post('api/Productos/CrearMasivo',{
						'Localidad' : me.$store.state.usuario.localidad,
						'detalles': productosDatos, // Este campo guarda la cantidad de personas soportadas por un servicio
					},configuracion).then(function(response){
						//wait(9000);
						me.modelCarga = false;
						alert("Productos cargados de forma exitosa");	
						me.ValidarExistencia();			
					}).catch(err => {
						me.loading =false;
						//console.log(err.response);
						if (err.response.status==400){
							alert("Ocurrió un error cargando un producto");
						} else if (err.response.status==404){
							alert("Código ocupado por otro producto")
						}else{
							alert("Validar Campos Vacios")
						}
					});
              
    	},
      	async guardar() {
			if(this.LocalidadesSelectas.length === 0){
				this.Mensaje = "Seleccione al menos 1 localidad para continuar";
				this.snackbar = true;
				return;
			}
            if(this.Categoria == 'PRODUCTO'){ 
				if(this.validar()){
					return;
				}	
            }  else
              if(this.Categoria == 'SERVICIO' )
                if (this.validarServicio()){
					return;
			} 
            if(this.categoria == 0){
              this.SoN = 'N';
            }
			if(this.Ivi == true){
				this.Ivi = 1;
			}else
			{
				this.Ivi = 0;
			}
			const acabadosselectos = this.acabadosSeleccionados.join(', ');
			const variantes = this.variaciones.join(', ');
			this.modelCarga = true;
            let header={"Authorization" : "Bearer "};
                let configuracion= {headers : header};
                if (this.editedIndex > -1) {
                    //Código para editar
                    let me=this;
                    await axios.post('api/Productos/ActualizarProductoMultiple',{
                        'codigoProducto':me.codigoProducto,
                        'descripcion':me.descripcion,
                        'barcode':me.barcode,
                        'IdProductKey': me.idProductKey,
                        'precioVenta':me.precioVenta,
                        'precioDetalle':me.precioDetalle,
                        'precioMall':me.PrecioMall,
                        'precioMayor':me.precioMayor,
                        'precioCentral':me.precioCentral,
                        'precioCompra': me.precioCompra,
                        'compuesto': me.compuesto,
                        'Categoria':me.Categoria, //Define si es un servicio o un Producto
                        'Origen':me.hora, // Este campo es la hora inicial del servicio
                        'InnerPack': me.cat1, // Este campo es la Hora final del servicio
                        'CantHoras': me.hora4, // Este campo define la cantidad de horas disponibles del servicio
                        'comision': me.comision,
                        'maxDescuento': me.maxDescuento,
                        'proveedor': me.proveedor,
                        'localizacion': me.localizacion,
						'Cocina':me.cocina,
                        'minimo':me.minimo,
                        'maximo':me.maximo,
                        'EstadoInventario': me.Ivi,  // 
        				'Localidad': '',  // se usan las localidades del arreglo esta no se necesita
                        'valorImpuesto':me.categoria,
                        'Exe_Grad':me.SoN,
                        'tipoProducto': me.familia,
                        'SubFamilia': me.SubFamilia,
                        'CantPersonas': me.CantPersonas,
                        'CodigoRaiz': me.cabys,
						'MaxDescuento':me.maxDescuento,
						'SincServer1':me.activo, // Mostrar Item en la web
						'Color':me.Color,
						'CodigoCaja':me.sku,
						'Medidas':variantes,
						'PKG':me.nicotina,
						'Consignacion':acabadosselectos,
						'Dimensiones':me.MaxFactura,
						'Actividad':me.ubicacion,
						'Localidades':me.LocalidadesSelectas,
						'Usuario':me.$store.state.usuario.nombre,
                    },configuracion).then(function(response){
						const { actualizadas, noEncontradas } = response.data;
        
							// Construir mensajes basados en las respuestas
							let mensajeActualizadas = actualizadas.length > 0 ? "Localidades actualizadas: " + actualizadas.join(", ") : "No se actualizó ninguna localidad.";
							let mensajeNoEncontradas = noEncontradas.length > 0 ? "Localidades no encontradas: " + noEncontradas.join(", ") : "";
							me.familia = null;
							// Mostrar mensajes en el snackbar o similar
							alert(mensajeActualizadas + " " + mensajeNoEncontradas);
							me.modelCarga = false;   
							me.dialog = false;         
                    }).catch(function(error){
                        if (error.response && error.response.data) {
							// Mostrar mensaje de error detallado
							me.modelCarga = false; 
							alert("Error: " + error.response.data.Error + " Detalles: " + error.response.data.Detalle);
						} else {
							me.modelCarga = false; 
							// Error no relacionado con la respuesta del backend
							alert("Error de comunicación con el servidor.");
						}
                    });
                } else {

            let header={"Authorization" : "Bearer "};
            let configuracion= {headers : header};
                //Código para guardar
                let me=this;
                await axios.post('api/Productos/CrearMultiple',{
                    'codigoProducto':me.codigoProducto,
                    'descripcion':me.descripcion,
                    'barcode':me.barcode,
                    'precioVenta':me.precioVenta,
                    'precioDetalle':me.precioDetalle,
                    'precioMall':me.PrecioMall,
                    'precioMayor':me.precioMayor,
                    'precioCentral':me.precioCentral,
                    'precioCompra': me.precioCompra,
                    'compuesto': me.compuesto,
                    'comision': me.comision,
                    'maxDescuento': me.maxDescuento,
                    'proveedor': me.proveedor,
                    'localizacion': me.localizacion,
                    'minimo':me.minimo,
                    'maximo':me.maximo,
                    'EstadoInventario': me.Ivi,
                    'Categoria':me.Categoria, //Define si es un servicio o un Producto
                    'Origen':me.hora, // Este campo es la hora inicial del servicio
                    'InnerPack': me.cat1, // Este campo es la Hora final del servicio
                    'CantHoras': me.hora4, // Este campo define la cantidad de horas disponibles del servicio
                    'valorImpuesto':me.categoria,
                    'tipoProducto': me.familia,
                    'SubFamilia' :me.SubFamilia,
                    'Exe_Grad':me.SoN,
                    'Localidad' : '',
                    'CantPersonas': me.CantPersonas, // Este campo guarda la cantidad de personas soportadas por un servicio
                    'CodigoRaiz': me.cabys, // Guarda el Codigo CABYS
					'SincServer1':me.activo,
					'Color':me.Color,
					'CodigoCaja':me.sku,
					'Medidas':variantes,
					'PKG':me.nicotina,
					'Consignacion':acabadosselectos,
					'Dimensiones':me.MaxFactura,
					'Actividad':me.ubicacion,
					'Localidades':me.LocalidadesSelectas,
					'Usuario':me.$store.state.usuario.nombre,
                },configuracion).then(function(response){
					const { creadas, errores } = response.data;
					
					let mensajeCreadas = creadas.length > 0 ? "Localidades creadas: " + creadas.join(", ") : "No se creó el producto en ninguna localidad.";
					let mensajeErrores = errores.length > 0 ? "Errores: " + errores.join(", ") : "";

					// Mostrar mensajes en el snackbar o similar
					alert(mensajeCreadas + "\n" + mensajeErrores);
					
					me.modelCarga = false;
					me.dialog = false;
					me.familia = null;
                }).catch(error => {
					me.modelCarga = false;
					// Si el error es de validación o específico del servidor
					if (error.response) {
						if (error.response.status === 400) {
							// Puedes personalizar más dependiendo del cuerpo del error
							alert("Error: " + error.response.data);
						} else if (error.response.status === 404) {
							alert("No se encontraron recursos.");
						} else {
							alert("Error en el servidor.");
						}
					} else {
						// Error de red o desconocido
						alert("Error de conexión o error desconocido.");
					}
                });
              }
		},
		async IngresoStockInicial(){
			if(this.stock > 1)
			this.modelcarga = true;
			var det = [{idarticulo: this.codigoProducto,descripcion:this.descripcion,
					precio:this.precioVenta,cantidadIngreso:this.stock}];
			var me = this;
			let header={"Authorization" : "Bearer " + this.$store.state.token};
			let configuracion= {headers : header};
			await axios.post('api/ProductosWebs/MovimientoInventario',{
						'Usuario': this.$store.state.usuario.nombre,
						'localidad': this.$store.state.usuario.localidad,
						'detalles': det,
						'detalle': "Ingreso inicial stock",
						'Aprobado': true,
						'TipoIngreso': "INGRESO",
						
				},configuracion).then(function (response) {
					me.snackbar = true;
					me.Mensaje = "Inventario cargado de forma exitosa";
					})
					.catch(function (error) {
						me.modelcarga = false;
						alert(`Lo sentimos ha ocurrido un error, notifiuelo al 4070-1889`+error);  
				})
		},
      editItem (item) {
        this.codigoProducto = item.codigoProducto
        this.descripcion = item.descripcion
        this.categoria = item.valorImpuesto
		this.Cat = item.categoria
		this.Categoria = item.categoria
        this.idProductKey = item.idProductKey
        this.SubFamilia = item.subFamilia
        this.barcode = item.barcode
        this.precioCompra = item.precioCompra
        this.comision = item.comision
        this.precioVenta = item.precioVenta
        this.hora = item.origen 
        this.cat1 = item.innerPack
        this.cabys = item.codigoRaiz
        this.hora4 = item.cantHoras
        this.familia = item.tipoProducto
		this.cocina = item.cocina
        this.proveedor = item.proveedor
        this.maximo = item.maximo
        this.Ivi = item.estadoInventario
        this.minimo = item.minimo
        this.maxDescuento = item.maxDescuento
        this.localizacion = item.localizacion
        this.precioMall = item.precioMall
        this.precioCentral = item.precioCentral
		this.activo = item.sincServer1
		this.compuesto = item.compuesto
        this.precioDetalle = item.precioDetalle
        this.precioMayor = item.precioMayor
        this.editedIndex=1;
        this.CantPersonas = item.cantPersonas
        this.dialog = true
		this.Color = item.color
		this.MaxFactura = item.dimensiones // Max porcentaje que se puede facturar del item
		this.ubicacion = item.actividad // Quien tiene el activo o donde esta ubicado
		this.sku = item.codigoCaja
		this.uploadValue = 1
		this.nicotina = item.pkg
		this.variaciones = item.medidas.split(', ')
		this.acabadosSeleccionados = item.consignacion.split(', ')
		this.SelectSKU();
      },
	  SelectSKU(){
			this.Tallas = [];
			for(var i=0;i<this.articulos.length;i++){
				if (this.articulos[i].codigoCaja==this.sku){
					this.Tallas.push(
						{
						Talla: this.articulos[i].medidas, 
						Color:this.articulos[i].color, 
						Estado: "Disponible",
						descripcion:this.articulos[i].descripcion,
						Codigo:this.articulos[i].codigoProducto,
						}
					);
			}
		}
	  },
      validar(){
          this.valida=false;
          this.validaMensaje=[];

          if (this.descripcion.length<3 || this.descripcion.length>120){
            this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 120 caracteres.");
            this.valida=true;
          }
          if (!this.familia){
            this.validaMensaje.push("Seleccione una familia.");
            this.valida=true;
          }
          if (!this.proveedor){
            this.validaMensaje.push("Seleccione un proveedor");
            this.valida=true;
          }
          if (!this.maximo){
            this.validaMensaje.push("Seleccione un Máximo");
            this.valida=true;
          }
          if (!this.minimo){
            this.validaMensaje.push("Seleccione un Mínimo");
            this.valida=true;
          }
          if (!this.proveedor){
            this.validaMensaje.push("Seleccione un proveedor");
            this.valida=true;
          }
          if (!this.codigoProducto){
            this.validaMensaje.push("Seleccione un código de Producto");
            this.valida=true;
          }
          if (!this.barcode){
            this.validaMensaje.push("Seleccione un código de barras");
            this.valida=true;
          }
          return this.valida;
      },
      validarServicio(){
          this.valida=0;
          this.validaMensaje=[];

          if (this.descripcion.length<3 || this.descripcion.length>50){
              this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 50 caracteres.");
          }
          if (!this.hora){
              this.validaMensaje.push("Seleccione una hora de inicio");
          }
          if (!this.hora2){
              this.validaMensaje.push("Seleccione una Hora Final");
          }
          if (!this.hora4){
              this.validaMensaje.push("Seleccione la cantidad de Horas");
          }
          if (this.validaMensaje.length){
              this.valida=1;
          }
          return this.valida;
      },
      EsProducto(){
        this.esProducto = true;
        this.Categoria = "PRODUCTO";
        if(this.editedIndex === -1){
			let barcodepreview = Date.now();
			let idsa = barcodepreview.toString();
			this.codigoProducto = idsa.substring(0, 8);
			this.barcode = idsa.substring(0, 8);
		}
      },
      EsServicio(){
        this.esProducto = false;
        this.Categoria = "SERVICIO";
        if(this.editedIndex === -1){
			let barcodepreview = Date.now();
			let idsa = barcodepreview.toString();
			this.codigoProducto = idsa.substring(0, 8);
			this.barcode = idsa.substring(0, 8);
		}
      },
	  EsInsumo(){
        this.esProducto = true;
        this.Categoria = "INSUMO";
		if(this.editedIndex === -1){
			let barcodepreview = Date.now();
			let idsa = barcodepreview.toString();
			this.codigoProducto = idsa.substring(0, 8);
			this.barcode = idsa.substring(0, 8);
		}
        
      },
      deleteProveedor (item) {
          this.loading = true;
          var me = this;
          if(confirm("Seguro que quiere eliminar el proveedor "+item.proveedor+"?")){
              let header={"Authorization" : "Bearer " + this.$store.state.token};
              let configuracion= {headers : header};
               axios.post('api/Proveedores/DeleteProveedores',{
                  'IdProveedorKey': item.idProveedorKey,
                      
                },configuracion).then(function (response) {
                    me.snackbar = true;
                    me.Mensaje = 'Proveedor Eliminado Con éxito.';  
                    me.GestionProveedores();
                    me.selectProveedores();
                    me.loading = false;
                  })
                  .catch(function (error) {
                      me.snackbar = true;
					  me.loading = false;
                      me.Mensaje = 'Error eliminando el proveedor.'; 
                    })
          }
      },
      DeleteProducto (item) {
          this.loading = true;
          var me = this;
          if(confirm("Seguro que quiere eliminar el Producto "+item.descripcion+"?")){
              let header={"Authorization" : "Bearer " + this.$store.state.token};
              let configuracion= {headers : header};
               axios.post('api/Productos/Delete',{
                  'IdProductKey': item.idProductKey,
                      
                },configuracion).then(function (response) {
                  
                    me.initialize();
                    me.snackbar = true;
					me.loading = false;
                    me.Mensaje = 'Producto Eliminado Con éxito.';  
                    
                  })
                  .catch(function (error) {
                      me.snackbar = true;
                      me.Mensaje = 'Error eliminando el Producto.'; 
					  me.loading = false;   
                    })
          }
      },

      
    },
  }
</script>
<style lang="scss">>
    #espacioBoton {
        top: 10px;
    }
    /*#clienteBoton {
        bottom: 10px;
    }*/
    /* #clienteActualizar {
        bottom: 5px;
    }*/

    #factura {
        padding: 20px;
    }
    #ticket {
    width: 60px;
    max-width: 155px;
    }

    
    @import url('https://fonts.googleapis.com/css2?family=ABeeZee&family=Montserrat:wght@200;400&family=Open+Sans:wght@600&family=PT+Sans&display=swap');

    #logo {
        float: left;
        margin-left: 2%;
        margin-right: 2%;
    }
    #imagen {
        width: 100px;
    }

    #fact {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }   

    #datos {
        float: left;
        margin-top: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }

    #encabezado {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 16px;
    }

    section {
        clear: left;
    }

    #cliente {
        text-align: left;
    }

    #facliente {
        width: 40%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 15px;
    }

    #fa {
        color: #FFFFFF;
        font-size: 14px;
    }
    @media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
    }   
	/* Solid border */
	hr.solid {
	border-top: 3px solid #bbb;
	}
    #facarticulo {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        padding: 20px;
        margin-bottom: 15px;
    }

    #facarticulo thead {
        padding: 20px;
        background: #2183E3;
        text-align: center;
        border-bottom: 1px solid #FFFFFF;
    }

    #gracias {
        text-align: center;
    }
    tr.v-data-table__selected {
      background: #7d92f5 !important;
    }

    button#facturaBoton {
      margin: 18px;
    }

    button#hoyBoton {
      margin: 18px;
    }

    .tarjeta{
        margin-bottom: 15px!important;
        margin-top: 20px!important;
    }

    .efectivo{
        margin-bottom: 15px!important;
        margin-top: 20px!important;
    }

    .btnLimpiarGrid {
        margin-bottom: 20px;
    }

    button#productoBoton {
        top: 6px;
        margin-left: 35px;
    }

    td,
    th,
    tr,

    .v-dialog:not(.v-dialog--fullscreen) {
        max-height: 100%!important;
    }
    .centrado {
    text-align: center;
    align-content: center;
    }

    .ticket {
        width: 155px;
        max-width: 155px;
    }

    img {
        max-width: inherit;
        width: inherit;
    }
    .centrado {
    text-align: center;
    align-content: center;
    }

    .ticket {
        width: 200px;
        max-width: 200px;
    }

    img {
        max-width: inherit;
        width: inherit;
    }

</style>
My CRUD

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueTextareaAutosize from "vue-textarea-autosize";
import axios from "axios";
import router from "./router";
import store from "./store";

Vue.use(VueTextareaAutosize);
Vue.use(axios);

Vue.config.productionTip = false;
// axios.defaults.baseURL ='http://jefrux1324-002-site1.etempurl.com/'   BackendQUinchos
//axios.defaults.baseURL ='http://jefrux1324-002-site2.etempurl.com/'     //  BackendPollosJeff
//axios.defaults.baseURL ='http://rest.noah.cr/Backend' //BackendSissos
//  axios.defaults.baseURL ='https://localhost:44388/'
// axios.defaults.baseURL ='http://192.168.100.84'
 axios.defaults.baseURL ='https://backend.kingvape.cr/'
//axios.defaults.baseURL = "https://backend.pdvcostarica.com/";


new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

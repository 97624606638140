import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./components/Login.vue";
import Dashboard from "./components/Dashboard.vue";
import Calendar from "./components/Calendar.vue";
import Ordenar from "./components/Ordenar.vue";
import CrearUsuario from "./components/CrearUsuario.vue";
import CrearUsuarioCliente from "./components/CrearUsuarioCliente.vue";
import CrearOrden from "./components/CrearOrden.vue";
import RepVentas from "./components/RepVentas.vue";
import RepCompras from "./components/RepCompras.vue";
import Facturar from "./components/Facturar.vue";
import Ordenes from "./components/Ordenes.vue";
import CuentasPorCobrar from "./components/CuentasPorCobrar.vue";
import CuentasPorPagar from "./components/CuentasPorPagar.vue";
import MisOrdenes from "./components/MisOrdenes.vue";
import Clientes from "./components/Clientes.vue";
import Features from "./components/Features.vue";
import Proformas from "./components/Proformas.vue";
import InvActual from "./components/InvActual.vue";
import Afiliacion from "./components/Afiliacion.vue";
import Compras from "./components/Compras.vue";
import AceptarProforma from "./components/AceptarProforma.vue";
import Ticket from "./components/Ticket.vue";
import PerfilCliente from "./components/PerfilCliente.vue";
import Conteos from "./components/Conteos.vue";
import Rol from "./components/Rol.vue";
import Reparaciones from "./components/Reparaciones.vue";
import CreditosPagados from "./components/CreditosPagados.vue";
import RepXproducto from "./components/RepXproducto.vue";
import Seriales from "./components/Seriales.vue";
import Tutoriales from "./components/Tutoriales.vue";
import MovimientoProductos from "./components/MovimientoProductos.vue";
import PanelControl from "./components/PanelControl.vue";
import Licencias from "./components/Licencias.vue";
import ProductosWeb from "./components/ProductosWeb.vue";
import Cocina from "./components/Cocina.vue";
import Bar from "./components/Bar.vue";
import Despacho from "./components/Despacho.vue";
import Botones from "./components/Botones.vue";
import RepClientes from "./components/RepClientes.vue";
import Localidades from "./components/Localidades.vue";
import POS from "./components/POS.vue";
import ReporteCrudo from "./components/ReporteCrudo.vue";
import Calendario from "./components/Calendario.vue";
import Cierre from "./components/Cierre.vue";
import Compuestos from "./components/Compuestos.vue";
import Recetas from "./components/Recetas.vue";
import FeedBack from "./components/FeedBack.vue";
import Odt from "./components/Odt.vue";
import ResEntrantes from "./components/ResEntrantes.vue";
import store from "./store";
import RepProductoDetallado from "./components/RepProductoDetallado.vue";
import RepXVendedor from "./components/RepXVendedor.vue";
import HomeQuinchos from "./components/HomeQuinchos.vue";
import Promociones from "./components/Promociones.vue";
import Trazabilidad from "./components/Trazabilidad.vue";
import OrdenExpress from "./components/OrdenExpress.vue";
import ReporteTraslados from "./components/ReporteTraslados.vue";
import RepComprasProveedor from "./components/RepComprasProveedor.vue";
import ReporteMinimos from "./views/ReporteMinimos.vue";
import ProductosEstancados from "./views/ProductosEstancados.vue";

Vue.use(Router);

var router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        administrador: true,
        supervisor: true,
        vendedor: true,
        libre: true,
      },
    },
    {
      path: "/pos",
      name: "pos",
      component: POS,
      meta: {
        libre: true,
      },
    },
    {
      path: "/productosestancados",
      name: "productosestancados",
      component: ProductosEstancados,
      meta: {
        libre: true,
      },
    },
    {
      path: "/reportetraslados",
      name: "reportetraslados",
      component: ReporteTraslados,
      meta: {
        libre: true,
      },
    },
    {
      path: "/ordenexpress",
      name: "ordenexpress",
      component: OrdenExpress,
      meta: {
        libre: true,
      },
    },
    {
      path: "/reservasentrantes",
      name: "reservasentrantes",
      component: ResEntrantes,
      meta: {
        libre: true,
      },
    },
    {
      path: "/trazabilidad",
      name: "trazabilidad",
      component: Trazabilidad,
      meta: {
        libre: true,
      },
    },
    {
      path: "/comprasproveedor",
      name: "comprasproveedor",
      component: RepComprasProveedor,
      meta: {
        libre: true,
      },
    },
    {
      path: "/reporteminimos",
      name: "reporteminimos",
      component: ReporteMinimos,
      meta: {
        libre: true,
      },
    },
    {
      path: "/test",
      name: "test",
      component: HomeQuinchos,
      meta: {
        libre: true,
      },
    },
    {
      path: "/odt",
      name: "odt",
      component: Odt,
      meta: {
        libre: true,
      },
    },
    {
      path: "/repxproducto",
      name: "repxproducto",
      component: RepXproducto,
      meta: {
        administrador: true,
        supervisor: true,
        vendedor: true,
      },
    },
    {
      path: "/promociones",
      name: "promociones",
      component: Promociones,
      meta: {
        administrador: true,
        supervisor: true,
        vendedor: true,
      },
    },

    {
      path: "/botones",
      name: "botones",
      component: Botones,
      meta: {
        libre: true,
      },
    },
    {
      path: "/reportecrudo",
      name: "reportecrudo",
      component: ReporteCrudo,
      meta: {
        libre: true,
      },
    },
    {
      path: "/repxvendedor",
      name: "repxvendedor",
      component: RepXVendedor,
      meta: {
        libre: true,
      },
    },
    {
      path: "/recetas",
      name: "recetas",
      component: Recetas,
      meta: {
        libre: true,
      },
    },
    {
      path: "/tutoriales",
      name: "tutoriales",
      component: Tutoriales,
      meta: {
        libre: true,
      },
    },
    {
      path: "/compuestos",
      name: "compuestos",
      component: Compuestos,
      meta: {
        libre: true,
      },
    },

    {
      path: "/bar",
      name: "bar",
      component: Bar,
      meta: {
        libre: true,
      },
    },
    {
      path: "/seriales",
      name: "seriales",
      component: Seriales,
      meta: {
        administrador: true,
        supervisor: true,
      },
    },
    {
      path: "/feedback",
      name: "feedback",
      component: FeedBack,
      meta: {
        libre: true,
      },
    },
    {
      path: "/creditospagados",
      name: "creditospagados",
      component: CreditosPagados,
      meta: {
        administrador: true,
        supervisor: true,
      },
    },
    {
      path: "/panelcontrol",
      name: "panelcontrol",
      component: PanelControl,
      meta: {
        administrador: true,
        supervisor: true,
        libre: true,
      },
    },
    {
      path: "/ticket/:id",
      name: "ticket",
      component: Ticket,
      meta: {
        libre: true,
      },
    },
    {
      path: "/cierre/:id",
      name: "cierre",
      component: Cierre,
      meta: {
        libre: true,
      },
    },
    {
      path: "/perfilcliente/:localidad/:id",
      name: "perfilcliente",
      component: PerfilCliente,
      meta: {
        libre: true,
      },
    },
    {
      path: "/facturar/:id/:localidad",
      name: "facturar",
      component: Facturar,
      meta: {
        libre: true,
      },
    },
    {
      path: "/aceptarproforma/:id/:localidad",
      name: "aceptarproforma",
      component: AceptarProforma,
      meta: {
        libre: true,
      },
    },
    {
      path: "/conteos/:id/",
      name: "conteos",
      component: Conteos,
      meta: {
        libre: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        administrador: true,
        almacenero: true,
        vendedor: true,
        libre: true,
      },
    },
    {
      path: "/repventas",
      name: "repventas",
      component: RepVentas,
      meta: {
        libre: true,
      },
    },
    {
      path: "/repcompras",
      name: "repcompras",
      component: RepCompras,
      meta: {
        administrador: true,
        libre: true,
      },
    },
    {
      path: "/proformas",
      name: "proformas",
      component: Proformas,
      meta: {
        libre: true,
      },
    },
    {
      path: "/inventarioactual",
      name: "inventarioactual",
      component: InvActual,
      meta: {
        libre: true,
        supervisor: true,
      },
    },
    {
      path: "/misordenes",
      name: "misordenes",
      component: MisOrdenes,
      meta: {
        administrador: true,
        supervisor: true,
        vendedor: true,
      },
    },
    {
      path: "/reparaciones",
      name: "reparaciones",
      component: Reparaciones,
      meta: {
        administrador: true,
        supervisor: true,
        libre: true,
      },
    },
    {
      path: "/usuarios",
      name: "usuarios",
      component: CrearUsuario,
      meta: {
        libre: true,
      },
    },
    {
      path: "/licencias",
      name: "licencias",
      component: Licencias,
      meta: {
        libre: true,
      },
    },
    {
      path: "/productosweb",
      name: "productosweb",
      component: ProductosWeb,
      meta: {
        libre: true,
      },
    },
    {
      path: "/productosdetallados",
      name: "productosdetallados",
      component: RepProductoDetallado,
      meta: {
        libre: true,
      },
    },
    {
      path: "/usuariocliente",
      name: "usuariocliente",
      component: CrearUsuarioCliente,
      meta: {
        libre: true,
      },
    },
    {
      path: "/calendar",
      name: "calendar",
      component: Calendar,
      meta: {
        libre: true,
      },
    },
    {
      path: "/calendario",
      name: "calendario",
      component: Calendario,
      meta: {
        libre: true,
      },
    },
    {
      path: "/afiliacion",
      name: "afiliacion",
      component: Afiliacion,
      meta: {
        libre: true,
      },
    },
    {
      path: "/clientes",
      name: "clientes",
      component: Clientes,
      meta: {
        administrador: true,
        supervisor: true,
      },
    },
    {
      path: "/localidades",
      name: "localidades",
      component: Localidades,
      meta: {
        administrador: true,
      },
    },
    {
      path: "/ordenes",
      name: "ordenes",
      component: Ordenes,
      meta: {
        libre: true,
      },
    },
    {
      path: "/ordenar",
      name: "ordenar",
      component: Ordenar,
      meta: {
        administrador: true,
        supervisor: true,
        vendedor: true,
      },
    },
    {
      path: "/movimientoproductos",
      name: "movimientoproductos",
      component: MovimientoProductos,
      meta: {
        administrador: true,
        supervisor: true,
        vendedor: true,
      },
    },
    {
      path: "/crearorden",
      name: "crearorden",
      component: CrearOrden,
      meta: {
        administrador: true,
        supervisor: true,
        vendedor: true,
      },
    },
    {
      path: "/cuentasporcobrar",
      name: "cuentasporcobrar",
      component: CuentasPorCobrar,
      meta: {
        administrador: true,
        supervisor: true,
      },
    },
    {
      path: "/cuentasporpagar",
      name: "cuentasporpagar",
      component: CuentasPorPagar,
      meta: {
        administrador: true,
        supervisor: true,
      },
    },
    {
      path: "/features",
      name: "features",
      component: Features,
      meta: {
        administrador: true,
        libre: true,
      },
    },
    {
      path: "/despacho",
      name: "despacho",
      component: Despacho,
      meta: {
        administrador: true,
        libre: true,
      },
    },
    {
      path: "/cocina",
      name: "cocina",
      component: Cocina,
      meta: {
        administrador: true,
        libre: true,
      },
    },
    {
      path: "/id",
      name: "",
      meta: {
        administrador: true,
      },
    },
    {
      path: "/compras",
      name: "compras",
      component: Compras,
      meta: {
        administrador: true,
        supervisor: true,
        libre: true,
      },
    },
    {
      path: "/rol",
      name: "rol",
      component: Rol,
      meta: {
        administrador: true,
        supervisor: true,
      },
    },
    {
      path: "/repclientes",
      name: "repclientes",
      component: RepClientes,
      meta: {
        libre: true,
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        libre: true,
      },
    },
  ],
});

// Nav Guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.libre)) {
    next();
  } else if (
    store.state.usuario &&
    store.state.usuario.rol == "Administrador"
  ) {
    if (to.matched.some((record) => record.meta.administrador)) {
      next();
    }
  } else if (store.state.usuario && store.state.usuario.rol == "Supervisor") {
    if (to.matched.some((record) => record.meta.supervisor)) {
      next();
    }
  } else if (store.state.usuario && store.state.usuario.rol == "Vendedor") {
    if (to.matched.some((record) => record.meta.vendedor)) {
      next();
    }
  } else {
    next({
      name: "/dashboard",
    });
  }
});

export default router;
